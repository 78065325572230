import { defineStore } from 'pinia';
import { useScrollLock } from '@vueuse/core';
import { EModal, type HorizonComponent, type SiteSettings} from '../types';
import { isHorizonComponent } from '../types';
import { useSiteConfigStore } from './siteConfig.store';
import { ref, computed, watchEffect, nextTick } from 'vue';
import type { FlirtModalProps } from '../components/Modals/Flirt/flirtmodal.schema';
import type { FilterModalProps } from '../components/Modals/Filters/filtermodal.schema';

export const useModalStore = defineStore('modal', () => {
  const component = ref<HorizonComponent | undefined>(undefined);
  const componentName = computed<string | undefined>(() => component.value?.name);

  const isDismissable = ref<boolean>(false);
  const blur = ref<boolean>();
  const scrollLock = useScrollLock(window.document);

  watchEffect(() => {
    if (component.value) {
      scrollLock.value = true;
    } else {
      scrollLock.value = false;
    }
  });

  async function showModalComponent<T>(
    componentName: EModal,
    dismissable: boolean = true,
    props: T | null = null
  ) {
    if (component.value) {
      // eslint-disable-next-line no-console
      console.warn(
        `Modal '${component.value.name}' is already being rendered, replacing with '${componentName}'`
      );
      component.value = undefined;
      // Bugfix re-rendering the same modal after switching page
      await nextTick();
    }
    const siteConfig = useSiteConfigStore();
    const siteSettings = siteConfig.siteSettings;
    if (!siteSettings) return;

    const modalComponent =
      siteSettings.components[componentName as unknown as keyof SiteSettings['components']];

    if (!modalComponent || !isHorizonComponent(modalComponent)) return;
    if (modalComponent && props != null) modalComponent.data = { ...modalComponent.data, ...props };
    component.value = modalComponent;
    isDismissable.value = dismissable;
    blur.value = component?.value?.data?.blur ?? false;
  }

  async function showFlirtModal(props: FlirtModalProps, dismissable: boolean = true) {
    showModalComponent(EModal.flirtModalComponent, dismissable, props);
  }

  async function showFilterModal(props: FilterModalProps, dismissable: boolean = true) {
    showModalComponent(EModal.filtersModalComponent, dismissable, props);
  }

  function clearModal() {
    component.value = undefined;
    scrollLock.value = false;
  }

  return {
    component,
    isDismissable,
    blur,
    componentName,

    showModalComponent,
    showFlirtModal,
    showFilterModal,
    clearModal
  };
});

