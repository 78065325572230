import { storeToRefs } from 'pinia';
import { useSiteConfigStore } from '../../stores';
import { useRoute } from 'vue-router';
import type { PageKey } from '../../types';
import { useTranslationMap } from '../../utils/pageKeyTranslationMap';
import { ref } from 'vue';

export interface NavItem {
  label: string;
  mobileLabel?: string;
  icon: {
    name: string;
    flip?: 'horizontal' | 'vertical';
  };
  to: string;
  class: string;
  count?: number;
}
type NavItems = Record<string, NavItem>;
export type HeaderLink = {
  label: string;
  route: string;
  key: PageKey | undefined;
};

type IconMap = Record<PageKey, string>;
const iconMapping: Partial<IconMap> = {
  members: 'ic:baseline-search',
  matchgame: 'lucide:user-round-plus',
  visitors: 'fa6-regular:eye',
  favourites: 'ic:baseline-favorite-border',
  chat: 'ic:outline-mail',
  credits: 'la:coins'
};

function getIcon(pageKey: PageKey) {
  return iconMapping[pageKey] ?? 'rivet-icons:question-mark';
}

const accountClassPagesDefaults = ['my-pictures', 'account', 'settings'];
const chatClassPagesDefaults = ['mailbox', 'chat'];

function getClass(
  headerLink: HeaderLink,
  baseEnabledClass: string,
  baseDisabledClass: string,
  accountClassPages = accountClassPagesDefaults,
  chatClassPages = chatClassPagesDefaults
): string {
  if (!headerLink.key) return '';
  if (accountClassPages.includes(headerLink.key))
    return isAccountPage() ? baseEnabledClass : baseDisabledClass;
  if (chatClassPages.includes(headerLink.key))
    return isChatPage() ? baseEnabledClass : baseDisabledClass;
  return isDefaultClass(headerLink.route) ? baseEnabledClass : baseDisabledClass;
}

export function useHeader(
  overrideOrder: string[] = [],
  baseEnabledClass: string = 'text-primary hover:text-link-hover',
  baseDisabledClass: string = 'hover:text-link-hover'
) {
  const { pages, order } = getVars(overrideOrder);

  const headerLinks =
    order.length > 1
      ? (pages.value ?? [])
          .filter((x) => x.key && order.includes(x.key))
          .map((x) => ({ label: x.name, route: x.path, key: x.key }))
          .sort(function (a, b) {
            const indexA = order.indexOf(a.key ?? a.label);
            const indexB = order.indexOf(b.key ?? b.label);
            return indexA - indexB;
          })
      : [];

  const navItems = ref<NavItems>({});

  for (const link of headerLinks) {
    if (!link || !link.key) continue;
    navItems.value[link.key ?? link.label] = createNavItem(
      link,
      baseEnabledClass,
      baseDisabledClass
    );
  }

  return {
    headerLinks,
    navItems,
    baseEnabledClass,
    baseDisabledClass
  };
}

export function createNavItem(
  link: HeaderLink,
  baseEnabledClass: string,
  baseDisabledClass: string
) {
  const { pages, translations } = getVars();
  const { translationMapping, mobileTranslationMapping } = useTranslationMap(
    pages.value,
    translations.value
  );
  if (!translationMapping || !mobileTranslationMapping || !link.key) {
    return {
      label: 'none',
      mobileLabel: 'none',
      icon: {
        name: 'none'
      },
      to: '/404',
      class: ''
    };
  }

  return {
    label: translationMapping[link.key],
    mobileLabel: mobileTranslationMapping[link.key],
    icon: { name: getIcon(link.key) },
    to: link.route,
    class: getClass(link, baseEnabledClass, baseDisabledClass)
  };
}

function getVars(overrideOrder: string[] = []) {
  const configStore = useSiteConfigStore();
  const { pages, translations } = storeToRefs(configStore);
  const order: string[] =
    overrideOrder.length > 0 ? overrideOrder : (configStore.siteSettings?.headerLinkOrder ?? []);
  const route = useRoute();
  return {
    pages,
    translations,
    order,
    route
  };
}

export function isAccountPage(accountKeys: string[] = accountClassPagesDefaults) {
  const { pages, route } = getVars();
  const accountPaths = pages.value
    ?.filter((page) => accountKeys.includes(page?.key ?? 'noKey'))
    .map((x) => x.path);
  return accountPaths?.includes(route.path);
}

export function isChatPage(chatKeys: string[] = chatClassPagesDefaults) {
  const { pages, route } = getVars();
  const chatPaths = pages.value
    ?.filter((page) => chatKeys.includes(page?.key ?? 'noKey'))
    .map((x) => x.path);
  return chatPaths?.includes(route.path);
}

export function isDefaultClass(pagePath: string) {
  const route = useRoute();
  return route.path.startsWith(pagePath);
}

