import { createError } from '#app';
import { useSiteConfigStore } from '../stores';
import type { HorizonNuxtErrorData, LegalTextResponseData, TranslationVars } from '../types';
import { replaceVariables } from '../utils/variableReplacement';
import { usePaymentStore } from '../stores/payment.store';
import { createUseCCFetchClient } from './apiClients';
import { storeToRefs, useNuxtApp } from '#imports';

export async function fetchLegalText(
  localeCode: string | undefined,
  legalTextKey: string | undefined,
  companyId: string | undefined
) {
  const siteConfigStore = useSiteConfigStore();
  const { translations } = storeToRefs(siteConfigStore);
  const { $translate } = useNuxtApp();

  if (!localeCode || !legalTextKey || !companyId) {
    const vars: TranslationVars = {
      whatVar: 'legal text'
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.loadError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: useSiteConfigStore().theme,
        devTitle: 'expected a value for localeCode, legalTextKey and companyID',
        devMessage: 'but at least one of them was undefined '
      },
      fatal: true
    });
  }

  /**
   * Cached by the Nuxt server for 5 minutes, see apps/horizon/horizon-ui-site/server/api/cached/legaltext.get.ts
   */
  const legalData = await $fetch<LegalTextResponseData>('/api/cached/legaltext', {
    query: {
      localeCode,
      legalTextKey,
      companyId
    }
  }).catch((error) => {
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.loadError, {
          whatVar: 'legal text'
        }),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: useSiteConfigStore().theme,
        devTitle: error.value?.message,
        devMessage: error.value?.data?.error
      },
      fatal: true
    });
  })

  const replacedText = replaceVariables(legalData.legalText.content, {
    company: legalData.variables.company,
    modifiedAt: legalData.legalText.modifiedAt,
    launchYear: new Date(legalData.legalText.createdAt).getFullYear(),
    minCreditPrice: usePaymentStore().minCreditPrice,
    maxCreditPrice: usePaymentStore().maxCreditPrice
  });

  return { data: legalData, replacedText: replacedText };
}

export async function fetchLegalVariables(
  localeCode: string | undefined,
  legalTextKey: string | undefined,
  companyId: string | undefined
) {
  const siteConfigStore = useSiteConfigStore();
  const { translations } = storeToRefs(siteConfigStore);
  const { $translate } = useNuxtApp();
  if (!localeCode || !legalTextKey || !companyId) {
    const vars: TranslationVars = {
      whatVar: 'legal text'
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.loadError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: useSiteConfigStore().theme,
        devTitle: 'expected a value for localeCode, legalTextKey and companyID',
        devMessage: 'but at least one of them was undefined '
      },
      fatal: true
    });
  }

  const useCCFetch = createUseCCFetchClient();
  const { data, error } = await useCCFetch('/locale/{localeCode}/legal/{key}', {
    method: 'GET',
    path: {
      localeCode: localeCode,
      key: legalTextKey
    },
    query: {
      companyID: companyId!
    }
  });

  if (!data.value || error.value) {
    const vars: TranslationVars = {
      whatVar: 'legal text'
    };
    throw createError<HorizonNuxtErrorData>({
      data: {
        title: $translate(translations?.value?.loadError, vars),
        subtitle: $translate(translations?.value?.tryAgain),
        theme: useSiteConfigStore().theme,
        devTitle: error.value?.message,
        devMessage: error.value?.data?.error
      },
      fatal: true
    });
  }

  return data.value.variables;
}

