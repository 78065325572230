import { createError, useCookie, navigateTo } from '#app';
import { useRoute } from '#vue-router';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type {
  HorizonNuxtErrorData,
  PaymentProducts,
  PaymentProductsResponse,
  UIPaymentProduct
} from '../types';
import { Status } from '../types/fulfillment';
import { getDeviceType } from '../utils/deviceType';
import { useSiteConfigStore } from './siteConfig.store';
import { useChatStore } from './chat.store';
import { truncatePrice } from '../utils';
import {
  createCreditFetchClient,
  createLiteFulfillmentFetchClient,
  createUseFulfillmentFetchClient
} from '../composables/apiClients';
import { useRuntimeConfig } from '#imports';

export const usePaymentStore = defineStore('payment-store', () => {
  const apiClient = createUseFulfillmentFetchClient();
  const siteConfigStore = useSiteConfigStore();
  const route = useRoute();
  const runtimeConfig = useRuntimeConfig();

  const paymentPage = siteConfigStore.pages?.find((page) => page.key === 'payment');
  const paymentPagePath = paymentPage?.path;
  const paymentError = ref<string | null>(null);

  const paymentProducts = ref<UIPaymentProduct[]>();
  const minCreditPrice = ref<number>(0);
  const maxCreditPrice = ref<number>(0);

  const workInBackground = ref<boolean>(false);
  const intervalFulfillment = ref<NodeJS.Timeout | undefined>();
  const intervalCredit = ref<NodeJS.Timeout | undefined>();

  const intervalDelay = 2000; // in ms
  const maxTries = 60; // about 2 min for interval delay = 2sec

  const apiClientFulfillment = createLiteFulfillmentFetchClient();
  const apiClientCredit = createCreditFetchClient();

  async function fetchProducts() {
    /**
     * Cached by the Nuxt server for 5 minutes, see apps/horizon/horizon-ui-site/server/api/cached/products.get.ts
     */
    const productsResponse = await $fetch<PaymentProductsResponse>('/api/cached/products', {
      query: {
        tenantId: runtimeConfig.public.tenantId
      }
    }).catch((error) => {
      throw createError<HorizonNuxtErrorData>({
        data: {
          title: 'Unable to load products',
          theme: siteConfigStore.theme,
          devTitle: 'payment store, fetchProducts() failed',
          devMessage: error.value?.data?.error
        },
        fatal: true
      });
    });

    if (productsResponse) {
      paymentProducts.value = _calculateDiscountsAndMapProducts(productsResponse.products);
    }
  }

  async function createOrder(paymentMethod: string, productId: string): Promise<string | null> {
    if (!siteConfigStore.localeCode) return null;

    paymentError.value = null;

    if (!paymentPagePath) {
      paymentError.value = 'Payment page not found';
      return null;
    }

    const origin = new URL(window.location.href).origin;
    const redirectBaseUrl = origin + paymentPagePath;
    const locale = siteConfigStore.localeCode ?? '';
    const { data, error } = await apiClient('/client/orders', {
      method: 'POST',
      body: {
        device: getDeviceType(),
        order_lines: [{ product_id: productId, quantity: 1 }],
        payment_method: paymentMethod,
        locale: locale,
        success_url: `${redirectBaseUrl}?status=${Status.PaymentSuccessful}`,
        fail_url: `${redirectBaseUrl}?status=${Status.PaymentFailed}`,
        pending_url: `${redirectBaseUrl}?status=${Status.PaymentPending}`
      }
    });

    if (error.value || !data.value) {
      paymentError.value = 'Failed to create order';
      return null;
    }

    const orderCookie = useCookie('orderId', { maxAge: 60 * 60 }); // 1 hour
    orderCookie.value = data.value.id;

    const profileCookie = useCookie('profileId', { maxAge: 60 * 60 }); // 1 hour
    const profileId: string | undefined = route.params?.id?.toString();
    profileCookie.value = profileId;

    return data.value.checkout_url;
  }

  function _calculateDiscountsAndMapProducts(products: PaymentProducts): UIPaymentProduct[] {
    const result: UIPaymentProduct[] = [];

    // 1. Transform into UI type, calculate min/max prices per credit
    let min = Number.MAX_SAFE_INTEGER;
    let max = 0;

    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      const pricePerCredit = truncatePrice(product.price / product.reward_quantity);
      min = Math.min(min, pricePerCredit);
      max = Math.max(max, pricePerCredit);

      result.push({
        ...product,
        isSelected: false,
        pricePerCredit,
        discountPercentage: 0,
        discountPercentageLabel: '-',
        bundleType: 'normal'
      });
    }

    minCreditPrice.value = min === Number.MAX_SAFE_INTEGER ? 0 : min;
    maxCreditPrice.value = max;

    // 2. Calculate discount percentage per product now that max price is available
    let foundBestValue = false;

    for (let i = 0; i < result.length; i++) {
      const uiProduct = result[i];

      const priceDifference = 1 - uiProduct.pricePerCredit / max;
      if (priceDifference < 0.01) continue;

      const discountPercentage = truncatePrice(100 * priceDifference, 0);
      uiProduct.discountPercentage = discountPercentage;
      uiProduct.discountPercentageLabel = -discountPercentage + '%';

      if (uiProduct.pricePerCredit === min && !foundBestValue) {
        foundBestValue = true;
        uiProduct.bundleType = 'bestValue';
      }

      // TODO: Mark products as 'discountedOffer' | 'mostPopular', 2025 and beyond
    }

    return result;
  }

  async function fetchOrderStatus(currentStatus: Status, order_id: string | null | undefined) {
    if (!order_id) {
      return currentStatus;
    }

    const { data, error } = await apiClientFulfillment('/client/orders/{order_id}/status', {
      path: { order_id },
      method: 'GET'
    });

    if (error) {
      clearInterval(intervalFulfillment.value);
      return Status.PaymentFailed;
    }

    if (data.status === Status.PaymentSuccessful || data.status === Status.PaymentFailed) {
      clearInterval(intervalFulfillment.value);
      clearInterval(intervalCredit.value);
    }

    return data.status as Status;
  }

  async function fetchCreditInformation(currentStatus: Status, orderId: string | null | undefined) {
    const { data, error } = await apiClientCredit('/users', {
      method: 'GET'
    });
    if (error || !orderId || !data.orders?.includes(orderId)) {
      return currentStatus;
    }

    clearInterval(intervalCredit.value);
    useChatStore().creditCount = data.current_credits;

    return Status.AddingSuccessful;
  }

  return {
    paymentError,
    paymentProducts,
    minCreditPrice,
    maxCreditPrice,
    workInBackground,
    intervalCredit,
    intervalFulfillment,
    intervalDelay,
    maxTries,

    createOrder,
    fetchProducts,
    fetchCreditInformation,
    fetchOrderStatus
  };
});

