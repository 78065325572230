import { defineStore } from 'pinia';
import { signInWithCustomToken, signInWithEmailAndPassword, type Auth } from 'firebase/auth';
import type { FirebaseError } from 'firebase/app';
import { AuthUserSchema, type AuthUser } from '../types/auth/user';
import { clearStorage, dateToAge, storageKeys } from '../utils';
import { checkUserConsent, fetchUserConsent, fingerPrintUser } from '../utils/consentChecker';
import { useSiteConfigStore, useChatStore } from '../stores';
import { useNuxtApp } from '#app';
import { ref, computed } from 'vue';
import { createIdentityFetchClient } from '../composables/apiClients';
import type { RegisterAccountBody } from '../types/identity/identityApi';
import { usePartnerTrackingStore } from './partnerTracking';
import { usePartnerTrackingCookie } from '../utils/partner';
import { useRuntimeConfig } from '#imports';

export const useAuthStore = defineStore('auth', () => {
  const nuxtApp = useNuxtApp();
  const firebaseAuth = nuxtApp.$firebaseAuth as Auth;

  const partnerTrackingStore = usePartnerTrackingStore();
  const siteConfigStore = useSiteConfigStore();

  const identityApiClient = createIdentityFetchClient();

  const runtimeConfig = useRuntimeConfig();

  const authUser = ref<AuthUser>();

  const isAuthorized = computed<boolean>(() => {
    return !!authUser.value;
  });

  // When signing up via SSO, we have to store the sso email to use in register modal
  const ssoEmail = ref<string>();
  // When resetting password, we have to store the token to use in the password reset modal
  const resetPasswordToken = ref<string>();

  firebaseAuth?.onIdTokenChanged((x) => {
    if (!x) {
      authUser.value = undefined;
      clearStorage();
      return;
    }

    const parseResult = AuthUserSchema.safeParse(x?.toJSON());

    if (parseResult.success) {
      authUser.value = parseResult.data;
    } else {
      // eslint-disable-next-line no-console
      console.warn('[AuthStore] firebase: Unable to parse idToken', parseResult.error.format());
      authUser.value = undefined;
    }
  });

  async function getUserAccessToken(): Promise<string | undefined> {
    return firebaseAuth.currentUser?.getIdToken();
  }

  async function initialLoad() {
    await firebaseAuth?.authStateReady();
  }

  async function loadTenant(tenantId: string | null) {
    // https://cloud.google.com/identity-platform/docs/multi-tenancy-authentication#sign_in_with_tenants
    if (firebaseAuth) {
      firebaseAuth.tenantId = tenantId;
    }
  }

  async function registerAccount(body: RegisterAccountBody) {
    if (!firebaseAuth)
      return {
        errorCode: 'auth/no-firebase-instance',
        data: undefined
      };

    firebaseAuth.tenantId = runtimeConfig.public.tenantId;

    const { data, error } = await identityApiClient(
      '/client/identity/tenants/{tenant_id}/users/register',
      {
        method: 'POST',
        path: { 
          tenant_id: runtimeConfig.public.tenantId
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        headers: {} as any, // Cast header as any as these values (user-agent, referer, accept-language) get filled by the browser
        body
      }
    );

    if (error) {
      return {
        errorCode: error?.data?.error ?? 'auth/invalid-credential',
        data: undefined
      };
    }

    partnerTrackingStore.loadSoiPixels(data.uuid, dateToAge(body.dateOfBirth));

    const isDoiDelayed =
      siteConfigStore.partnerPixelConfig && siteConfigStore.partnerPixelConfig.delayedDoi;
    if (!isDoiDelayed && body.isSSO) {
      await new Promise((r) => setTimeout(r, 500));
      partnerTrackingStore.loadDoiPixels(data.uuid, dateToAge(body.dateOfBirth));
    }

    usePartnerTrackingCookie().value = undefined;

    clearStorage();

    return {
      errorCode: undefined,
      data
    };
  }

  async function resendActivationMail() {
    const storedRegisterResult = sessionStorage.getItem(storageKeys.register.Result);
    if (storedRegisterResult === null)
      return {
        errorCode: 'auth/no-registration-found',
        data: undefined
      };
    const registerResult = JSON.parse(storedRegisterResult);
    const { error } = await identityApiClient('/identity/resend-verify-email', {
      method: 'POST',
      body: {
        tenantID: runtimeConfig.public.tenantId,
        uuid: registerResult.uuid
      }
    });
    return !error ? true : false;
  }
  async function changePassword(password: string) {
    const { data, error } = await identityApiClient(
      '/identity/tenants/{tenant_id}/password',
      {
        method: 'POST',
        path: {
          tenant_id: runtimeConfig.public.tenantId,
        },
        body: {
          new_password: password
        }
      }
    );
    return {
      data: data,
      error: error
    };
  }

  async function loginWithEmailPassword(email: string, password: string, relogin: boolean = false) {
    if (!firebaseAuth)
      return {
        errorCode: 'auth/no-firebase-instance'
      };

    firebaseAuth.tenantId = runtimeConfig.public.tenantId

    let resultCode: string | undefined;
    const result = await signInWithEmailAndPassword(firebaseAuth, email, password).catch(
      (e: FirebaseError) => {
        if (e.message.includes('Unverified email')) {
          resultCode = 'auth/user-unverified';
          return;
        }
        resultCode = e.code;
      }
    );

    if (resultCode || !result) {
      return {
        errorCode: resultCode ?? 'auth/invalid-credential'
      };
    }

    const idToken = await result.user.getIdToken().catch();
    if (!idToken)
      return {
        errorCode: 'auth/idtoken-fetch-failed'
      };

    if (!relogin) {
      await fingerPrintUser(authUser.value?.uid);
      await fetchUserConsent();
      await checkUserConsent();
      clearStorage();
    }

    return {
      errorCode: undefined
    };
  }

  async function createCustomTokenForGoogleSSO(token: string) {
    let errorCode: string | undefined = undefined;
    const response = await identityApiClient(
      '/client/identity/tenants/{tenant_id}/users/sso/login',
      {
        method: 'POST',
        path: { 
          tenant_id: runtimeConfig.public.tenantId
        },
        body: { token }
      }
    ).catch(() => {
      errorCode = 'auth/sso-token-fetch-failed';
    });

    if (response?.data && !response.data.email_exists) {
      errorCode = 'auth/email-does-not-exist';
    }

    return { errorCode, token: response?.data?.token };
  }

  async function loginWithCustomToken(customToken: string) {
    if (!firebaseAuth)
      return {
        errorCode: 'auth/no-firebase-instance'
      };

    firebaseAuth.tenantId = runtimeConfig.public.tenantId;

    let resultCode: string | undefined;
    const result = await signInWithCustomToken(firebaseAuth, customToken).catch(
      (e: FirebaseError) => {
        resultCode = e.code;
      }
    );

    if (resultCode || !result) {
      return {
        errorCode: resultCode ?? 'auth/invalid-credential'
      };
    }

    const idToken = await result.user.getIdToken().catch();
    if (!idToken)
      return {
        errorCode: 'auth/idtoken-fetch-failed'
      };

    await fingerPrintUser(authUser.value?.uid);
    await fetchUserConsent();
    await checkUserConsent();

    return {
      errorCode: undefined
    };
  }

  async function logout() {
    clearStorage();
    useChatStore().exitStore();
    await firebaseAuth?.signOut();
    window.location.reload();
  }

  return {
    authUser,
    isAuthorized,
    ssoEmail,
    resetPasswordToken,

    getUserAccessToken,
    initialLoad,
    loadTenant,
    registerAccount,
    loginWithEmailPassword,
    createCustomTokenForGoogleSSO,
    loginWithCustomToken,
    logout,
    changePassword,
    resendActivationMail
  };
});

