<script setup lang="ts">
import { Dropdown } from 'floating-vue';
import { computed, nextTick, ref, storeToRefs } from '#imports';
import type { SelectEmoji } from 'vue3-emoji-picker';
import { useChatStore } from '../../../stores/chat.store';
import { useModalStore } from '../../../stores/modal.store';
import { useSiteConfigStore } from '../../../stores/siteConfig.store';
import { EModal } from '../../../types/config/modals';
import type { HorizonComponentProps } from '../../../types';

const props = defineProps<HorizonComponentProps>();

const chatStore = useChatStore();
const { currentChatUser } = storeToRefs(chatStore);

const modalStore = useModalStore();

const siteConfigStore = useSiteConfigStore();

const chatTextarea = ref<string>('');
const chatFile = ref<File | undefined>(undefined);
const errorMessage = ref<string>();

const MINIMAL_AMOUNT_OF_CHARACTERS = process.env.NODE_ENV === 'development' ? 1 : 75;
const canSendFile = computed(() => siteConfigStore.siteSettings?.chatAllowFileUploads ?? false);
const canSendChatMessage = computed(() => {
  return chatTextarea.value.length >= MINIMAL_AMOUNT_OF_CHARACTERS || !!chatFile.value;
});

function onSelectEmoji(emoji: SelectEmoji) {
  chatTextarea.value += emoji.i;
}

function chatFileChange(e: Event) {
  const files = (e.target as HTMLInputElement).files;
  if (!files?.length) return;
  chatFile.value = files[0];
}

const textArea$ = ref<HTMLTextAreaElement>();
function changeTextareaHeight() {
  const element = textArea$.value;
  if (!element) return;

  element.style.height = 'auto';
  element.style.height = element.scrollHeight + 'px';
}

async function submitChatMessage(e: Event) {
  e.preventDefault();
  if (currentChatUser.value === undefined || !currentChatUser.value.card.profile_id) {
    return;
  }

  const response = await chatStore.sendMessage(
    currentChatUser.value.chatID,
    currentChatUser.value.card.profile_id,
    chatTextarea.value,
    chatFile.value
  );

  if (response.status === 'insufficientcredits') {
    modalStore.showModalComponent(EModal.insufficientCreditsModalComponent);
    return;
  } else if (response.status === 'error') {
    errorMessage.value = response.errorMessage;
    return;
  }

  chatFile.value = undefined;
  chatTextarea.value = '';

  await nextTick();
  changeTextareaHeight();
}
</script>

<template>
  <div class="flex flex-col p-2" :data-testid="props.testId + '/ChatBox'">
    <h1 v-if="errorMessage" class="text-danger mb-2 text-sm">{{ errorMessage }}</h1>

    <form
      class="grid h-full grid-cols-[32px_32px_1fr_32px] grid-rows-1 items-center gap-2"
      @submit="submitChatMessage($event)"
    >
      <div class="mb-4 self-end">
        <input
          id="attachment"
          name="attachment"
          type="file"
          class="hidden"
          :data-testid="props.testId + '/AttachmentButton'"
          @change="chatFileChange($event)"
        />
        <label for="attachment">
          <Icon
            v-if="canSendFile"
            :name="
              canSendFile
                ? chatFile
                  ? 'mdi:camera'
                  : 'mdi:camera-outline'
                : 'mdi:camera-odd-outline'
            "
            size="28"
            class="text-inherit"
          />
        </label>
      </div>

      <button
        class="relative mb-4 self-end"
        type="button"
        :data-testid="props.testId + '/EmojiButton'"
      >
        <Dropdown>
          <div>
            <Icon
              name="mdi:smiley-happy-outline"
              size="28"
              class="text-header-text hover:text-link-hover"
            />
          </div>

          <template #popper>
            <ClientOnly>
              <LazyChatBoxEmojiPicker
                :test-id="props.testId"
                :data="{}"
                @on-select-emoji="onSelectEmoji($event)"
              />
            </ClientOnly>
          </template>
        </Dropdown>
      </button>

      <textarea
        id="chatarea"
        ref="textArea$"
        v-model="chatTextarea"
        class="bg-background border-border text-header-text size-full max-h-40 min-h-16 resize-none overflow-y-auto rounded-md border p-2 text-base placeholder-gray-400"
        name="chatarea"
        placeholder="Write a message"
        :data-testid="props.testId + '/TextArea'"
        @input="changeTextareaHeight()"
      ></textarea>

      <VTooltip class="mb-4 self-end">
        <button
          :disabled="!canSendChatMessage || currentChatUser?.card.profile_id === 'none'"
          class="text-header-text bg-header-bg rounded-md p-2 disabled:cursor-not-allowed disabled:opacity-50"
          type="submit"
          :data-testid="props.testId + '/SendButton'"
        >
          <Icon name="fa6-solid:paper-plane" size="20px" />
        </button>

        <template #popper>
          {{
            currentChatUser?.card.profile_id === 'none'
              ? "Sorry! You can't send messages to " + currentChatUser?.card.name
              : 'Minimum amount of characters: ' +
                chatTextarea.length +
                '/' +
                MINIMAL_AMOUNT_OF_CHARACTERS
          }}
        </template>
      </VTooltip>
    </form>
  </div>
</template>

