export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

export function truncatePrice(price: number, decimals: number = 2): number {
    let priceString = price.toString();

    if (priceString.includes('.')) {
        priceString = priceString.substring(0, priceString.indexOf('.') + 1 + decimals);
    }

    return Number(priceString);
}
