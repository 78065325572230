<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { inject, ref, watch, onMounted, onUnmounted, computed, type Component } from 'vue';
import { useRoute } from 'vue-router';
import {
  useProfileStore,
  useSiteConfigStore,
  useFiltersStore,
  useGlobalsStore
} from '../../stores';
import type { ProfileCard, HorizonComponentProps } from '../../types';
import {
  overrideStyles,
  emptyCard,
  emptyFilterCard,
  storageKeys,
  storeValue,
  getTestId,
  arrayShuffle
} from '../../utils';
import type { ScrollState } from './VirtualInfiniteGrid.vue';
import NoResultsNotice from './ModernflingSupport/NoResultsNotice.vue';
import { useWindowSize, watchDebounced, useElementSize, useScroll } from '@vueuse/core';
import VirtualGrid from './VirtualGrid.vue';
import VirtualInfiniteGrid from './VirtualInfiniteGrid.vue';

const props = defineProps<HorizonComponentProps>();
const components = inject<Record<string, Component>>('components');

const profileStore = useProfileStore();
const filterStore = useFiltersStore();
await filterStore.initFilterStore();
const state = computed(() => profileStore.locationState);
const siteConfigStore = useSiteConfigStore();
const { profileCardComponent, profileFiltersComponent } = storeToRefs(siteConfigStore);

const globals = useGlobalsStore();

const ITEMS_PER_PAGE = globals.constantGlobals.profileGrid.ITEMS_PER_PAGE;
const gridContainer = ref(null);
const gridKey = ref<number>(0);
const { width } = useElementSize(gridContainer);
const dontSetFooter = ref<boolean>(false);
const ITEM_WIDTH = ref<number>(globals.constantGlobals.profileGrid.ITEM_WIDTH);

const ITEM_HEIGHT = computed(() => (ITEM_WIDTH.value / 5) * 6);

const MAX_COL_COUNT = globals.constantGlobals.profileGrid.MAX_COL_COUNT;
const pageNumber = ref<number>(-1);
const totalCount = ref<number>(0);
const totalPages = ref<number>(0);
const availablePages = ref<number[]>([-1]);
const maxPage = ref<number>(-1);
const profiles = ref<ProfileCard[]>([]);

const noMoreProfiles = ref<boolean>(false);
const firstFetchEmpty = ref<boolean>(false);
const firstFetch = ref<boolean>(pageNumber.value === 0);

const pageName = String(useRoute().name);
const membersPage = siteConfigStore.pages?.find((page) => page.key === 'members');
const isMembersPage = computed(() => pageName === membersPage?.name);

const showNotice = ref<boolean>(false);

const { height: pageHeight } = useWindowSize();

const isLoading = ref<boolean>(false);

const { arrivedState } = useScroll(window, { behavior: 'smooth' });
const wheelTrigger = 30;

function getRandomPage() {
  const index = Math.floor(Math.random() * availablePages.value.length);
  let pageNumber = availablePages.value.splice(index, 1)[0];
  if (pageNumber === undefined) {
    pageNumber = maxPage.value;
    maxPage.value = -1;
  }
  return pageNumber;
}

function handleWheel(scroll: WheelEvent) {
  if (!isLoading.value && arrivedState.bottom && scroll.deltaY > wheelTrigger) {
    fetchItems();
  }
}

watchDebounced(pageHeight, setForceFooter, { debounce: 100 });

onMounted(async () => {
  window.addEventListener('wheel', handleWheel);
  filterStore.initFilters();
  if (pageName != membersPage?.name) {
    fetchItems();
  }

  storeValue(storageKeys.profileGrid.prevPage, pageName);
});

onUnmounted(() => {
  window.removeEventListener('wheel', handleWheel);
});
async function fetchItems() {
  if (isLoading.value) return;
  isLoading.value = true;
  if (noMoreProfiles.value) {
    const lastCard = profiles.value.at(-1);
    if (lastCard && lastCard.profile_id.startsWith('empty')) {
      isLoading.value = false;
      return;
    }
  }
  pageNumber.value = getRandomPage();
  firstFetch.value = profiles.value.length === 0 && pageNumber.value === -1;
  const data = await profileStore.getProfiles(pageNumber.value, ITEMS_PER_PAGE);
  if (firstFetch.value) {
    firstFetchEmpty.value = data.totalCount === 0;
    totalCount.value = data.totalCount;
    if (totalCount.value > 0) {
      totalPages.value = Math.ceil(totalCount.value / ITEMS_PER_PAGE);
      availablePages.value = [...Array(totalPages.value).keys()];
      maxPage.value = availablePages.value.pop() ?? -1;
      const usedPageIndex = availablePages.value.indexOf(data.usedPage);
      availablePages.value.splice(usedPageIndex, 1);
    }
  }
  const shouldAddToGrid = !data.noProfiles || (firstFetchEmpty.value && isMembersPage.value);
  if (data.profiles != null && data.profiles.length > 0 && shouldAddToGrid) {
    const filteredData = data.profiles.filter(
      (x) => !profiles.value.find((y) => y.profile_id === x.profile_id)
    );
    const shuffled = arrayShuffle(filteredData);
    profiles.value = [...profiles.value, ...shuffled];
  }
  showNotice.value = !isMembersPage.value && profiles.value.length === 0;

  if (data.noProfiles || (profiles.value.length >= totalCount.value && totalCount.value !== 0)) {
    const shouldStopFetching = isMembersPage.value
      ? data.profiles?.length < ITEMS_PER_PAGE || !firstFetchEmpty.value
      : data.profiles?.length < ITEMS_PER_PAGE && !firstFetchEmpty.value;
    if (shouldStopFetching || profiles.value.length >= totalCount.value) {
      endOfFetching();
      isLoading.value = false;
    }
  }

  if (!dontSetFooter.value) {
    setForceFooter();
    if (profiles.value.length >= ITEMS_PER_PAGE) {
      dontSetFooter.value = true;
    }
  }
  isLoading.value = false;
}

function setForceFooter() {
  if (!document) return;
  setTimeout(() => {
    const footerHeight = document.getElementById('mainFooter')?.scrollHeight ?? 0;
    const filterComponentHeight = document.getElementById('filterComponent')?.scrollHeight ?? 0;
    const pageHeaderHeight = document.getElementById('pageHeader')?.scrollHeight ?? 0;
    const height = pageHeight.value - footerHeight - filterComponentHeight - pageHeaderHeight;
    const minRows = Math.floor(height / ITEM_HEIGHT.value);
    const forceFooterThreshold = minRows * COL_COUNT.value + 1;
    globals.dynamicGlobals.profileGrid.forceFooter = profiles.value.length < forceFooterThreshold;
  });
}

function endOfFetching() {
  if (noMoreProfiles.value) return;
  noMoreProfiles.value = true;
  if (pageName != membersPage?.name) return;
  if (profiles.value.length < ITEMS_PER_PAGE) setForceFooter();

  const endProfile: ProfileCard =
    filterStore.isDefaultFilter() || firstFetchEmpty.value ? emptyCard : emptyFilterCard;

  const lastCardIndex = profiles.value.length - 1;
  const lastCard = profiles.value[lastCardIndex];
  if (!lastCard || lastCard.profile_id === endProfile.profile_id) return;

  if (lastCard.profile_id.startsWith('empty')) {
    profiles.value.pop();
  }
  profiles.value.push(endProfile);
}

function loadState(state: ScrollState<ProfileCard> | null) {
  if (pageName != membersPage?.name || !state) {
    fetchItems();
    return;
  }
  isLoading.value = true;
  availablePages.value = state.availablePages;
  maxPage.value = state.maxPage;
  totalCount.value = state.totalCount;
  profiles.value = state.items;

  if (profiles.value.length > 0) {
    showNotice.value = false;
  } else {
    isLoading.value = false;
    fetchItems();
  }
  firstFetchEmpty.value = state.firstFetchEmpty;
  isLoading.value = false;
}

watch(
  () => filterStore.filtersUpdated,
  () => {
    firstFetchEmpty.value = false;
    firstFetch.value = true;
    noMoreProfiles.value = false;
    pageNumber.value = -1;
    maxPage.value = -1;
    availablePages.value = [-1];
    profiles.value = [];
    gridKey.value++;
    fetchItems();
  }
);

const COL_COUNT = ref(MAX_COL_COUNT);

watchDebounced(width, resizeGrid, { debounce: 100 });

function resizeGrid() {
  const cols = Math.floor(width.value / globals.constantGlobals.profileGrid.MIN_ITEM_WIDTH);
  COL_COUNT.value = Math.min(cols, MAX_COL_COUNT);
  ITEM_WIDTH.value = width.value / COL_COUNT.value;
  setForceFooter();
}
</script>

<template>
  <div class="relative">
    <div v-if="components && profileCardComponent" class="min-h-modal-2xl">
      <div ref="gridContainer" class="container">
        <component
          :is="components[profileFiltersComponent.name]"
          v-if="profileFiltersComponent"
          :style="overrideStyles(profileFiltersComponent?.overrides)"
          :data="{
            showNoResults: firstFetchEmpty,
            amount: totalCount,
            state: state
          }"
          class="mb-4"
          :data-testid="props.testId + '/' + getTestId(profileFiltersComponent)"
          :test-id="props.testId + '/' + getTestId(profileFiltersComponent)"
        />

        <VirtualGrid
          v-show="profiles.length === 0 && !showNotice"
          :item-width="ITEM_WIDTH"
          :item-height="ITEM_HEIGHT"
          :col-count="COL_COUNT"
          :is-loading="isLoading"
        />

        <ClientOnly>
          <div v-show="!showNotice">
            <VirtualInfiniteGrid
              :data-testid="props.testId + '/VirtualGrid'"
              :test-id="props.testId + '/VirtualGrid'"
              :item-width="ITEM_WIDTH"
              :item-height="ITEM_HEIGHT"
              :available-pages="availablePages"
              :max-page="maxPage"
              :first-fetch-empty="firstFetchEmpty"
              :total-count="totalCount"
              :items="profiles"
              :col-count="COL_COUNT"
              :grid-key="gridKey"
              :is-loading="isLoading"
              @fetch="fetchItems"
              @load-state="loadState"
              @resize="resizeGrid"
            ></VirtualInfiniteGrid>
          </div>
        </ClientOnly>
      </div>
      <div v-show="showNotice">
        <NoResultsNotice :data="{}" :test-id="props.testId + '/'"></NoResultsNotice>
      </div>
    </div>
  </div>
</template>

