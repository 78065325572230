import { z } from 'zod';
import { LegalTextKey } from '../legalText';
import { HorizonComponentSchema, HorizonModalSchema } from './horizonComponentSchema';
import { EModal } from './modals';
import { SiteThemeSchema } from './themeSchema';
import { PartnerPixelsSchema } from './partnerPixelsSchema';
import { TranslationKeySchema } from './translationSchema';

export const PageKeySchema = z.union([
  LegalTextKey,
  z.enum([
    'home',
    'members',
    'visitors',
    'profile',
    'matched',
    'i-like',
    'liked-me',
    'favourites',
    'credits',
    'account',
    'settings',
    'chat',
    'matchgame',
    'messagebox',
    'login',
    'error',
    'gallery',
    'my-pictures',
    'payment'
  ])
]);

export const PageSchema = z.strictObject({
  key: PageKeySchema.optional(),
  name: z.string(),
  path: z.string(),
  auth: z.boolean().optional(),
  components: z.array(HorizonComponentSchema),
  overrides: SiteThemeSchema.optional(),
  pageOverrides: SiteThemeSchema.optional()
});

export const SiteSettingsSchema = z.strictObject({
  name: z.string(),
  description: z.string(),
  favicon: z.string(),
  logo: z.object({
    src: z.string(),
    alt: z.string()
  }),
  chatlogo: z
    .object({
      src: z.string(),
      alt: z.string()
    })
    .optional(),
  fonts: z
    .object({
      header: z.string().optional(),
      body: z.string().optional()
    })
    .optional(),
  holdingTenantId: z.string(),
  domainName: z.string(),
  billingSupport: z.string().optional(),
  localeCode: z.string(),
  currency: z.string(),
  companyId: z.string().uuid(),
  unauthorizedRedirectRoute: z.string(),
  authorizedRedirectRoute: z.string(),
  exitWebsiteUrl: z.string().optional(),
  chatAllowFileUploads: z.boolean(),
  footerLinkOrder: z.array(z.string()).optional(),
  headerLinkOrder: z.array(z.string()).optional(),
  components: z.strictObject({
    profileCard: HorizonComponentSchema,
    profileFilters: HorizonComponentSchema,
    profilePagination: HorizonComponentSchema,
    notification: HorizonComponentSchema,
    [EModal.registrationModalComponent]: HorizonModalSchema,
    [EModal.loginModalComponent]: HorizonModalSchema,
    [EModal.forgotPasswordModalComponent]: HorizonModalSchema,
    [EModal.resetPasswordModalComponent]: HorizonModalSchema,
    [EModal.insufficientCreditsModalComponent]: HorizonModalSchema,
    [EModal.onboardingNoticeModalComponent]: HorizonModalSchema,
    [EModal.filtersModalComponent]: HorizonModalSchema.optional(),
    [EModal.matchedModalComponent]: HorizonModalSchema.optional(),
    [EModal.flirtModalComponent]: HorizonModalSchema.optional(),
    [EModal.headerMenuModalComponent]: HorizonModalSchema.optional()
  }),
  partnerPixels: PartnerPixelsSchema
});

export const ConfigSchema = z.strictObject({
  pages: z.array(PageSchema).nonempty(),
  theme: SiteThemeSchema,
  siteSettings: SiteSettingsSchema,
  translations: TranslationKeySchema
});

