import type { ExtractResponse } from '@btr/fetch-frog';
import { z } from 'zod';
import type { paths, components } from '../../schemas/collectconsent';

export const LegalTextKey = z.enum([
  'cookieStatement',
  'termsAndConditions',
  'protectOurChildren',
  'companyInformation',
  'supportContactAndAddress',
  'mediaGiantNL',
  'priceInformationPremium',
  'privacyStatement',
  '2257Statement',
  'reportPage',
  'introText',
  'footerText',
  'signupConfirmationText'
]);

export type LegalTextKey = z.infer<typeof LegalTextKey>;

export type LegalTextResponseData = ExtractResponse<
  paths,
  '/locale/{localeCode}/legal/{key}',
  'get'
>;

export type LegalVariables = components['schemas']['contracts.VariablesResponse'];
