<script lang="ts" setup>
import type { HorizonComponentProps, Notification } from '../../types';
import { computed } from 'vue';
import type { Props } from './ModernflingSupport/Message.schema';
import type { NotivueItem } from 'notivue';
import Message from './ModernflingSupport/Message.vue';
import Credits from './ModernflingSupport/Credits.vue';

interface CustomProps {
  notification: Notification;
}

const props = defineProps<
  HorizonComponentProps<Props> & {
    item: NotivueItem<CustomProps>;
  }
>();
const isMessage = computed<boolean>(() => props.item.props.notification.profile !== undefined);
const largeMessage = computed<boolean>(
  () => isMessage.value || (props.item.props.notification.success ?? false)
);
</script>

<template>
  <div
    class="slide-in-out sm:bg-background sm:shadow-glow relative w-[100px] items-center justify-between shadow-none sm:flex sm:w-[400px] sm:p-5"
    :class="{
      'h-[100px] sm:h-[140px]': largeMessage,
      'h-[80px] sm:h-[100px]': !largeMessage
    }"
  >
    <Message
      v-if="isMessage"
      :data="props.data"
      :item="props.item"
      :test-id="'/MessageNotification'"
    ></Message>
    <Credits
      v-else
      :data="props.data"
      :item="props.item"
      :test-id="'/CreditsNotification'"
    ></Credits>
  </div>
</template>

<style scoped></style>

