<script setup lang="ts">
import { EModal, type HorizonComponentProps } from '../../../types';
import { useFiltersStore, useModalStore } from '../../../stores';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useSiteConfigStore } from '../../../stores/siteConfig.store';
import ButtonSimple from '../../Button/Simple.vue';
import { storeToRefs, useNuxtApp } from '#imports';

const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const { $translate } = useNuxtApp();
const route = useRoute();
const pageName = String(route.name);

const filterStore = useFiltersStore();

const homePage = siteConfigStore.pages?.find((page) => page.key === 'home');
const visitorPage = siteConfigStore.pages?.find((page) => page.key === 'visitors');
const membersPage = siteConfigStore.pages?.find((page) => page.key === 'members');
const matchgamePage = siteConfigStore.pages?.find((page) => page.key === 'matchgame');

const isHomePage = computed(() => {
  return pageName === homePage?.name;
});
const isMatchgamePage = computed(() => pageName === matchgamePage?.name);
const isMembersPage = computed(() => pageName === membersPage?.name);
const showMatchMemberButton = computed(() => {
  return isMatchgamePage.value || isMembersPage.value;
});
const showFilterButton = computed(() => {
  return showMatchMemberButton.value || isHomePage.value;
});

const props = defineProps<
  HorizonComponentProps<{
    showNoResults?: boolean;
    amount?: number;
    state?: string | undefined;
  }>
>();
const showNoResults = computed(() => props.data?.showNoResults);
const amount = computed(() => props.data?.amount ?? 0);
const state = computed(() => props.data?.state);

const showState = computed(
  () => isMembersPage.value && state.value !== undefined && state.value.toLowerCase() !== 'all'
);
const showAmount = computed(() => isMembersPage.value && amount.value > 0 && !showState.value);
const modalStore = useModalStore();

const title = computed(() => {
  const page = pageName === matchgamePage?.name ? membersPage?.name : pageName;
  return !showFilterButton.value ? 'Your ' + page : page;
});

function pressedFilterButton() {
  if (isHomePage.value) {
    modalStore.showModalComponent(EModal.registrationModalComponent);
    return;
  }

  modalStore.showFilterModal({ showLocationFilter: !isMatchgamePage.value });
}
</script>

<template>
  <div
    class="mx-auto mb-3 flex place-content-between"
    :class="{
      'mt-0': isHomePage,
      'mt-2 md:mt-6': !isHomePage
    }"
  >
    <Icon
      v-if="!showFilterButton"
      :name="pageName === visitorPage?.name ? 'fa6-regular:eye' : 'ic:baseline-favorite-border'"
      class="text-header-text mr-2 mt-auto"
      size="25"
    />
    <div class="flex flex-row">
      <p class="text-header-text relative top-1.5 mr-auto text-2xl font-bold">
        {{ title }}
      </p>
      <p v-if="showAmount" class="text-primary relative top-1.5 mr-auto text-2xl font-bold">
        {{ '&nbsp;' + amount }}
      </p>
      <p v-if="showState" class="text-primary relative top-1.5 mr-auto text-2xl font-bold">
        {{ '&nbsp;' + state }}
      </p>
    </div>

    <div class="ml-auto flex gap-2">
      <button
        v-show="showFilterButton"
        class="border-header-text/30 flex h-10 w-20 flex-row place-content-around items-center rounded-lg border"
        :data-testid="props.testId + '/FilterButton'"
        @click="pressedFilterButton"
      >
        <p class="text-header-text ml-1 text-sm">
          {{ $translate(translations?.filterText) }}
        </p>
        <Icon name="ion:filter" class="text-header-text mr-1" size="20" />
      </button>
      <NuxtLink
        v-if="showMatchMemberButton"
        class="border-header-text/30 flex h-10 w-24 flex-row place-content-around items-center rounded-lg border"
        :to="`${pageName === matchgamePage?.name ? membersPage?.path : matchgamePage?.path}`"
        :data-testid="props.testId + '/Match|MembersButton'"
      >
        <p class="text-header-text ml-1 text-sm">
          {{
            pageName === matchgamePage?.name
              ? $translate(translations?.membersButton)
              : $translate(translations?.matchButton)
          }}
        </p>
        <Icon
          :name="pageName === matchgamePage?.name ? 'ph:squares-four' : 'lucide:user-round-plus'"
          class="text-header-text mr-1"
          size="20"
        />
      </NuxtLink>
    </div>
  </div>

  <div
    v-if="showNoResults && showFilterButton"
    class="relative bottom-6 mb-4 flex flex-col items-center gap-2 text-center"
  >
    <button
      class="text-text text-sm underline"
      type="button"
      :data-testid="props.testId + '/NoResultsFilterButton'"
      @click="pressedFilterButton()"
    >
      {{ $translate(translations?.emptyResult) }}
    </button>
    <ButtonSimple
      :data="{ label: $translate(translations?.resetFilterLabel) }"
      :test-id="props.testId"
      @click="filterStore.resetFilters()"
    />
  </div>
</template>

<style scoped lang="postcss"></style>

