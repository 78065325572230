import validate from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45site_45config_45server_45global from "/pangaea/apps/horizon/horizon-ui-site/middleware/1.site-config.server.global.ts";
import _2_45route_45guard_45global from "/pangaea/apps/horizon/horizon-ui-site/middleware/2.route-guard.global.ts";
import _3_45fetch_45global_45state_45server_45global from "/pangaea/apps/horizon/horizon-ui-site/middleware/3.fetch-global-state.server.global.ts";
import _4_45consent_45checker_45global from "/pangaea/apps/horizon/horizon-ui-site/middleware/4.consent-checker.global.ts";
import _5_45partner_45params_45global from "/pangaea/apps/horizon/horizon-ui-site/middleware/5.partner-params.global.ts";
import manifest_45route_45rule from "/pangaea/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.8_encoding@0.1.13_eslint@9.8.0_ioredis@5._liyqwheercijcoeh2xbnbyocia/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45site_45config_45server_45global,
  _2_45route_45guard_45global,
  _3_45fetch_45global_45state_45server_45global,
  _4_45consent_45checker_45global,
  _5_45partner_45params_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}