<script setup lang="ts">
import { useRuntimeConfig } from '#imports';
import { ref } from 'vue';
import type { ZodFormattedError } from 'zod';
import { createIdentityFetchClient } from '../../../composables/apiClients';
import { useModalStore } from '../../../stores';
import {
  EModal,
  ForgotPasswordSchema,
  type ForgotPassword,
  type HorizonComponentProps,
  type Nullable
} from '../../../types';
import FormErrorRenderer from '../../Forms/FormErrorRenderer.vue';

const props = defineProps<HorizonComponentProps>();
const runtimeConfig = useRuntimeConfig();
const modalStore = useModalStore();
const identityApiClient = createIdentityFetchClient();

const formInfo = ref<Nullable<ForgotPassword>>({
  email: null
});
const formErrors = ref<ZodFormattedError<ForgotPassword> | undefined>();
const fetchStatus = ref<'idle' | 'sending' | 'success' | 'error'>('idle');

async function sendForgotPasswordEmail() {
  formErrors.value = undefined;
  fetchStatus.value = 'sending';

  const parsedForm = ForgotPasswordSchema.safeParse(formInfo.value);
  if (!parsedForm.success) {
    formErrors.value = parsedForm.error.format();
    fetchStatus.value = 'idle';
    return;
  }

  const { error } = await identityApiClient(
    '/client/identity/tenants/{tenant_id}/users/reset-password',
    {
      method: 'POST',
      path: {
        tenant_id: runtimeConfig.public.tenantId
      },
      body: {
        email: parsedForm.data.email
      }
    }
  );

  fetchStatus.value = !error ? 'success' : 'error';
}

async function openRegisterModal() {
  modalStore.showModalComponent(EModal.registrationModalComponent);
}
</script>

<template>
  <div
    class="bg-background top-1/10 md:!w-modal-lg absolute m-5 h-full max-h-full w-full overflow-y-auto rounded-2xl p-6 md:h-fit"
  >
    <div
      class="border-header-text/20 absolute right-2 top-2 flex h-10 w-10 cursor-pointer place-content-center place-items-center rounded-md border"
    >
      <Icon
        name="ic:sharp-close"
        size="25"
        class="text-header-text hover:text-primary"
        :data-testid="props.testId + '/CloseButton'"
        @click="modalStore.clearModal()"
      />
    </div>

    <div class="mb-4 flex flex-col">
      <h1 class="text-header-text mx-auto pb-4 text-2xl font-bold">Forgot your Password?</h1>
      <p class="text-header-text text-center text-sm font-bold">
        Please enter your e-mail address and we will send you a link to reset your password
      </p>
    </div>

    <div
      v-if="fetchStatus === 'success'"
      class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm text-white"
    >
      A link to reset your password has been sent if your e-mail address is known to us.
    </div>
    <div
      v-if="fetchStatus === 'error'"
      class="bg-error mb-3 w-full rounded-full py-4 text-center text-sm text-white"
    >
      Something went wrong with sending a link, please try again later..
    </div>

    <form v-else>
      <div class="mb-10">
        <input
          id="email"
          v-model="formInfo.email"
          type="email"
          name="email"
          placeholder="Please fill in your email address"
          class="control"
          :class="{ '!border-danger': !!formErrors?.email }"
          :data-testid="props.testId + '/EmailInput'"
        />
        <FormErrorRenderer :zod-errors="formErrors" property="email" />
      </div>

      <ButtonSimple
        class="shadow-buttonShadow w-full uppercase tracking-wide"
        :data="{ label: 'Send', isLarge: true }"
        :test-id="props.testId"
        @click="sendForgotPasswordEmail()"
      />
    </form>

    <div class="text-header-text flex flex-row place-content-center">
      <a
        class="hover:text-primary mt-3 block text-center"
        href="#"
        :data-testid="props.testId + '/RegisterLink'"
        @click="openRegisterModal()"
      >
        New user? Sign up here.
      </a>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.control {
  @apply text-header-text bg-header-bg border-header-text/20 h-10 w-full rounded-md border-2 px-2 py-1 text-sm;
}
</style>

