import { useNuxtApp } from '#app';
import type { ChatDocument, MessageDocument } from '@horizon/chat-protocol-ts';
import TTLCache from '@isaacs/ttlcache';
import dayjs from 'dayjs';
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  type DocumentData,
  type Query,
  type Timestamp
} from 'firebase/firestore';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { createChatFetchClient, createCreditFetchClient } from '../composables/apiClients';
import {
  useAuthStore,
  useChatFireStore,
  useMailStore,
  useNotificationStore,
  useProfileStore,
  useSiteConfigStore
} from '../stores';
import type { chatProfileData, MessageCreateBody, Notification } from '../types';
import type { ProfileCard } from '../types/profile/profile';
import { logTranslatedError } from '../utils/useTranslatedError';

export const useChatStore = defineStore('chat', () => {
  const isInited = ref<boolean>(false);

  const { $translate } = useNuxtApp();
  const siteConfigStore = useSiteConfigStore();
  const authStore = useAuthStore();
  const mailStore = useMailStore();
  const profileStore = useProfileStore();
  const notificationStore = useNotificationStore();

  const chatFetchClient = createChatFetchClient();
  const creditFetchClient = createCreditFetchClient();

  const locale = siteConfigStore.siteSettings?.localeCode as string;
  const domainName = siteConfigStore.siteSettings?.domainName as string;
  const userID = computed(() => authStore.authUser?.uid);

  const currentTab = ref<'chat' | 'mail'>('chat');
  const chatProfiles = ref<chatProfileData[]>([]);
  const ids = ref<string[]>([]);

  const currentChatUser = ref<chatProfileData>();
  const currentTabProfiles = computed<chatProfileData[]>(() => {
    const showConversation = currentTab.value === 'chat';
    return chatProfiles.value.filter((x) => x.conversation === showConversation);
  });

  let chatIdempotencyId = crypto.randomUUID?.();

  const creditCount = ref<number>(0);
  const unreadCount = computed(() => {
    return chatProfiles.value.filter((x) => !x.isRead).length;
  });

  async function getCredits(save: boolean = false) {
    if (!authStore.isAuthorized) return;

    try {
      const { data: creditResponse } = await creditFetchClient('/users', {
        method: 'GET'
      });
      if (creditResponse) {
        if (save) {
          creditCount.value = creditResponse === null ? 0 : creditResponse.current_credits;
        }
        return creditResponse === null ? 0 : creditResponse.current_credits;
      }
    } catch (e) {
      console.error(`unable to get user credits: ${e}`);
      console.error(`returning 0 credits`);
      return 0;
    }
  }

  async function getIfFlirted(profileID: string) {
    const profile = ref(getProfileByID(profileID));
    if (!profile.value) {
      profile.value = await newProfile(profileID);
    }
    return { flirted: profile.value?.flirted, name: profile.value?.card.name };
  }

  function getCreatedAt(chat: ChatDocument | undefined) {
    if (!chat) return '';
    //ts-expect-error createdAt constantly changes between created_at and createdAt, so its useless to update the type until this is resolved.
    if (chat.createdAt) return chat.createdAt;
    //@ts-expect-error createdAt constantly changes between created_at and createdAt, so its useless to update the type until this is resolved.
    if (chat.created_at) return chat.created_at;
    return '';
  }

  async function newProfile(profileID: string) {
    const createdChat = await createChat(profileID);
    if (createdChat) {
      const profile: chatProfileData = {
        card: createdChat.card,
        chatID: createdChat?.chat?.id ?? '',
        //@ts-expect-error createdChat?.chat is of type ChatDocument but this type is out of date: https://harlemnext.atlassian.net/browse/HIL-1494.
        createdAt: getCreatedAt(createdChat?.chat),
        conversation: false,
        flirted: false,
        messages: [],
        isRead: true
      };
      addProfile(profile);
      await getProfileImage(profile.card.profile_id);
      return getProfileByID(profile.card.profile_id);
    }
  }

  function getProfileByID(id: string) {
    for (const profile of chatProfiles.value) {
      if (profile.card.profile_id === id) {
        return profile;
      }
    }
    return undefined;
  }

  function updateProfile(profile: chatProfileData, updatedData: chatProfileData) {
    profile.card = updatedData.card;
    profile.chatID = updatedData.chatID;
    profile.createdAt = updatedData.createdAt;
    profile.conversation = updatedData.conversation;
    profile.flirted = updatedData.flirted;
    profile.messages = updatedData.messages;
  }

  function addProfile(profileToAdd: chatProfileData) {
    const profile = getProfileByID(profileToAdd.card.profile_id);
    if (!profile) {
      chatProfiles.value.push(profileToAdd);
      ids.value.push(profileToAdd.card.profile_id);
      orderProfiles();
    } else {
      updateProfile(profile, profileToAdd);
    }
    createMessageListener(profileToAdd);
  }

  function updateMessages(profileToUpdate: chatProfileData, messages: MessageDocument[]) {
    const profile = getProfileByID(profileToUpdate.card.profile_id);
    if (profile) {
      for (const message of messages) {
        checkIfUnread(message, profile);
      }
      profile.messages = messages;
    }
  }

  function checkIfUnread(message: MessageDocument, profile: chatProfileData) {
    if (
      !message.isRead &&
      message.type !== 'normal' &&
      message.type !== 'flirt' &&
      notificationStore.countAsUnread(message)
    ) {
      if (message.content) {
        const notification: Notification = {
          messageID: message.id,
          profile: {
            id: profile.card.profile_id,
            name: profile.card.name,
            images: [
              {
                src: profile.card.image.url,
                alt: profile.card.name
              }
            ]
          },
          text: message.content,
          duration: 5000
        };
        notificationStore.addNotification(notification, message, profile);
      }
    }
  }

  function updateProfileImage(profileToUpdate: chatProfileData, image: string) {
    const profile = getProfileByID(profileToUpdate.card.profile_id);
    if (profile) {
      profile.card.image = {
        ...profile.card.image,
        url: image
      };
    }
  }

  async function createChat(profileID: string) {
    if (!authStore.authUser?.uid || !authStore.authUser.displayName) return;

    const profile = await profileStore.getProfileById(profileID);
    const profileCard: ProfileCard = profileStore.extractCardFromProfile(profile);

    const { data, error } = await chatFetchClient('/user/chats/{locale}', {
      method: 'POST',
      path: {
        locale: locale
      },
      body: {
        chat: {
          profile: {
            uuid: profileID,
            name: profileCard.name
          },
          is_conversation: false,
          last_sender_type: 'customer',
          domain: domainName
        },
        locale
      }
    });

    if (error) {
      console.error(`unable to create chat: ${error}, using dummy data instead`);
      return;
    }

    return {
      chat: data,
      card: profileCard
    };
  }

  async function markChatAsRead(chatId: string): Promise<{ error: string | undefined }> {
    // CHB Expects a hostname without any subdomains, and will prepend the environment to the url
    const hostname = window.location.hostname;
    const domain = hostname.split('.').slice(-2).join('.');

    const { error } = await chatFetchClient('/user/chats/{locale}/{chatId}/{domain}/read', {
      method: 'POST',
      path: {
        locale,
        chatId,
        domain
      }
    });

    if (error) {
      console.error(`unable to mark chat as read: ${error}`);
    }

    return { error: error?.data?.error };
  }

  function timeTypeToValue(time: string | Timestamp) {
    if (typeof time === 'string') {
      return dayjs(time).unix();
    } else {
      return time.seconds;
    }
  }

  function orderProfiles() {
    setTimeout(() => {
      chatProfiles.value.sort(function (a, b) {
        const bTime =
          b.messages && b.messages.length > 0
            ? timeTypeToValue(b.messages.at(-1)?.createdAt ?? '0')
            : timeTypeToValue(b.createdAt ?? '0');
        const aTime =
          a.messages && a.messages.length > 0
            ? timeTypeToValue(a.messages.at(-1)?.createdAt ?? '0')
            : timeTypeToValue(a.createdAt ?? '0');
        return bTime - aTime;
      });
    });
  }

  type listenerType = () => void;
  const listeners: listenerType[] = [];

  async function createChatListener() {
    if (!userID.value) return;

    const chatFirestore = await useChatFireStore().getChatFirestore();
    if (!chatFirestore) return;

    const chatCollection = collection(doc(collection(chatFirestore, 'geos'), locale), 'chats');
    const chatQuery = query(
      chatCollection,
      where('customer.uuid', '==', userID.value)
      // where('tenant', '==', tenantID), // TODO: CHB overwrites the tenant to the holding tenant ID, which we currently do not have
      // where('isDeleted', '==', false) // TODO: API does not set this property
    );

    await fetchInitialProfiles(chatQuery);

    const unsubNewChats = onSnapshot(chatQuery, (chatQuerySnapshot) => {
      chatQuerySnapshot.forEach(async (chat) => {
        const chatData = chat.data() as ChatDocument;
        await loadChatDocumentProfile(chatData);
      });
    });
    listeners.push(unsubNewChats);
  }

  const notToAddToMailTypes = ['normal', 'flirt', 'reply'];

  async function createMessageListener(profile: chatProfileData) {
    const cloudFireStore = useChatFireStore();
    const chatFirestore = await cloudFireStore.getChatFirestore();
    if (!chatFirestore || !userID.value) return;

    const messageQ = query(
      collection(
        doc(collection(doc(collection(chatFirestore, 'geos'), locale), 'chats'), profile.chatID),
        'messages'
      ),
      orderBy('createdAt', 'asc')
    );

    const unsubNewMessages = onSnapshot(messageQ, (messageQuerySnapshot) => {
      const messages: MessageDocument[] = [];
      messageQuerySnapshot.forEach(async (qmessage) => {
        const data = qmessage.data() as MessageDocument;

        // TODO: HIL-1239 This check should be moved to firestore rules later, but creating a new index would take days and is being refactored at this moment anyways.
        if (cloudFireStore.isSnowflake) {
          if (data.senderType !== 'customer' || data.senderUuid !== userID.value) {
            return;
          }
        }

        if (!notToAddToMailTypes.includes(data.pokeMessageType)) {
          mailStore.addMail(data);
        }

        messages.push(data);
      });
      updateMessages(profile, messages);
      orderProfiles();
    });
    listeners.push(unsubNewMessages);
  }

  async function fetchInitialProfiles(chatQuery: Query<DocumentData, DocumentData>): Promise<void> {
    const allCurrentChats = await getDocs(chatQuery).catch((error) => {
      logTranslatedError({
        error,
        whileVar: 'retrieving',
        whatVar: 'chats',
        devTitle: 'chat.store fetchInitialProfiles getDocs',
        devMessage: ''
      });
    });
    if (!allCurrentChats) return;

    const profileDocs = new Map<string, ChatDocument>();
    for (let i = 0; i < allCurrentChats.docs.length; i++) {
      const data = allCurrentChats.docs[i].data() as ChatDocument;
      if (data.profile) {
        profileDocs.set(data.profile.uuid, data);
      }
    }

    const profileUUIDs = Array.from(profileDocs.keys());
    const profileCards = await profileStore.getProfileCardsByIds(profileUUIDs);
    if (!profileCards) return;

    chatProfiles.value = profileCards.map((x) => {
      const chatDocument = profileDocs.get(x.profile_id)!;

      return {
        card: x,
        chatID: chatDocument.id,
        createdAt: chatDocument.createdAt,
        conversation: chatDocument.isConversation,
        flirted: false,
        messages: [],
        isRead: chatDocument.isRead
      };
    });

    chatProfiles.value.forEach((chatProfile) => {
      addProfile(chatProfile);
    });
  }

  async function loadChatDocumentProfile(chatDocument?: ChatDocument): Promise<void> {
    if (!chatDocument?.profile || !chatDocument?.id) return;

    const profileUUID = chatDocument.profile.uuid;

    const alreadyFetchedProfile = chatProfiles.value.find((x) => x.card.profile_id === profileUUID);
    if (alreadyFetchedProfile) {
      alreadyFetchedProfile.conversation = chatDocument.isConversation;
      alreadyFetchedProfile.isRead = chatDocument.isRead;
      return;
    }

    const fetchedProfile = await profileStore.getProfileById(profileUUID).catch(() => {});
    const profileCard = fetchedProfile && profileStore.extractCardFromProfile(fetchedProfile);
    if (!profileCard) return;

    const chatProfile: chatProfileData = {
      card: profileCard,
      chatID: chatDocument.id,
      createdAt: chatDocument.createdAt,
      conversation: chatDocument.isConversation,
      flirted: false, // TODO: new field
      // flirted: chatData.FlirtedWith ?? false,
      messages: [],
      isRead: chatDocument.isRead
    };

    addProfile(chatProfile);
  }

  async function getProfileImage(id: string, useReturn: boolean = false) {
    const IDs: string[] = [id];
    const images = await profileStore.getProfileImages(IDs, 250);
    if (images) {
      const profile = getProfileByID(images[0].id as string);
      if (profile) {
        updateProfileImage(profile, images[0].url as string);
        if (useReturn) {
          return images[0];
        }
      }
    }
  }

  async function initStore() {
    if (isInited.value) {
      return;
    }
    mailStore.clearMail();
    await createChatListener();
    await getCredits(true);
    isInited.value = true;
  }

  function exitStore() {
    for (const unsub of listeners) {
      unsub();
    }
    isInited.value = false;
  }

  function clearAll() {
    chatProfiles.value = [];
    ids.value = [];
    creditCount.value = 0;
  }

  /**
   * Create formdata with body properties, casting back to messagecreatebody to ensure all properties are set.
   */
  function createMessageBody(
    body: Omit<MessageCreateBody, 'domain' | 'file'> & { file?: File }
  ): MessageCreateBody | undefined {
    const formData = new FormData();

    const domain = siteConfigStore.siteSettings?.domainName;
    if (!domain) return undefined;
    formData.set('domain', domain);

    if (body.file) {
      formData.set('file', body.file);
    }

    Object.entries(body).forEach(([key, value]) => {
      if (value) {
        formData.set(key, value);
      }
    });

    return formData as unknown as MessageCreateBody;
  }

  function getChatID(profileID: string) {
    return authStore?.authUser?.uid + ':' + profileID;
  }

  type SendMessageResult =
    | { status: 'insufficientcredits' }
    | { status: 'error'; errorMessage: string }
    | { status: 'success' };

  async function sendMessage(
    chatID: string,
    recipientUUID: string,
    content: string,
    attachment: File | undefined = undefined
  ): Promise<SendMessageResult> {
    if (creditCount.value === 0) {
      return { status: 'insufficientcredits' };
    }

    const { data, error: messageError } = await chatFetchClient(
      '/user/chats/{locale}/{chatId}/messages',
      {
        method: 'POST',
        headers: {
          'X-Idempotency-Key': chatIdempotencyId
        },
        path: {
          locale: locale,
          chatId: chatID
        },
        body: createMessageBody({
          file: attachment,
          'message.content': content,
          'message.recipient_uuid': recipientUUID
        })
      }
    );

    if (!data?.success || messageError) {
      console.error(`unable to send message: ${messageError}`);

      if (messageError?.data?.error === 'user has insufficient credits to deduct') {
        return { status: 'insufficientcredits' };
      } else {
        return {
          status: 'error',
          errorMessage: $translate(siteConfigStore.translations?.whileError, {
            whileVar: 'sending',
            whatVar: 'your message'
          })
        };
      }
    }

    // TODO: Dynamic credit pricing once backend returns it
    creditCount.value = Math.max(creditCount.value - 1, 0);
    chatIdempotencyId = crypto.randomUUID();

    return { status: 'success' };
  }

  const attachmentCache = new TTLCache<string, string>({
    ttl: 20 * 60 * 1000 // 20 minutes
  });

  async function getMessageAttachment(attachmentID: string): Promise<string | undefined> {
    const cachedUrl = attachmentCache.get(attachmentID);
    if (cachedUrl) return cachedUrl;

    const { data, error: messageError } = await chatFetchClient(
      '/user/attachments/{attachmentId}',
      {
        method: 'GET',
        path: {
          attachmentId: attachmentID
        }
      }
    );

    if (messageError || !data) return undefined;

    attachmentCache.set(attachmentID, data.url);
    return data.url;
  }

  async function sendFlirt(ID: string, content: string) {
    const profile = getProfileByID(ID) ?? (await newProfile(ID));
    if (!profile) {
      return {
        chatID: undefined,
        error: 'something went wrong'
      };
    }

    const chatID = profile.chatID;

    const { data, error: messageError } = await chatFetchClient(
      '/user/chats/{locale}/{chatId}/flirts',
      {
        method: 'POST',
        headers: {
          'X-Idempotency-Key': chatIdempotencyId
        },
        path: {
          locale: locale,
          chatId: chatID
        },
        body: {
          chat_document_id: chatID,
          domain: siteConfigStore.siteSettings?.domainName,
          message: {
            recipient_uuid: ID,
            content
          }
        }
      }
    );

    if (!data?.success || messageError) {
      if (messageError?.data?.error === 'maximum flirts exceeded for profile') {
        return {
          chatID: undefined,
          error: $translate(siteConfigStore.translations?.maximumFlirtsExceeded)
        };
      }

      return {
        chatID: undefined,
        error: $translate(siteConfigStore.translations?.whileError, { whileVar: 'flirting' })
      };
    }

    chatIdempotencyId = crypto.randomUUID();

    return {
      chatID: chatID,
      error: undefined
    };
  }

  return {
    profiles: chatProfiles,
    ids,
    creditCount,
    unreadCount,
    currentTab,
    currentChatUser,
    currentTabProfiles,

    initStore,
    exitStore,
    getProfileByID,
    newProfile,
    sendMessage,
    markChatAsRead,
    sendFlirt,
    clearAll,
    getCredits,
    getProfileImage,
    getChatID,
    getIfFlirted,
    getMessageAttachment
  };
});

