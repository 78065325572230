<script setup lang="ts">
import { reactive, ref, toRefs } from 'vue';
import type { HorizonComponentProps } from '../../types';
import { useSiteConfigStore } from '../../stores';
import { useNuxtApp } from '#app';

const { $translate } = useNuxtApp();
const siteConfigStore = useSiteConfigStore();
const { translations } = toRefs(siteConfigStore);
const props = defineProps<HorizonComponentProps>();

const body = reactive({
  name: '',
  email: '',
  subject: '',
  message: ''
});

const status = ref<'idle' | 'sending' | 'error' | 'success'>('idle');

async function sendForm(): Promise<void> {
  status.value = 'sending';

  await $fetch('/api/send-ticket', {
    method: 'POST',
    body
  })
    .then(() => {
      status.value = 'success';
    })
    .catch(() => {
      status.value = 'error';
    });
}
</script>

<template>
  <form class="text-text mt-3 flex flex-col gap-3" @submit.prevent="sendForm()">
    <div
      v-if="status === 'error'"
      class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm"
    >
      <strong class="text-white">{{ $translate(translations?.failedToSendMessage) }}</strong>
    </div>
    <div
      v-if="status === 'success'"
      class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm"
    >
      <strong class="text-white">{{ $translate(translations?.succesfullySentMessage) }}</strong>
    </div>

    <input
      v-model="body.name"
      class="border-button border-2 border-solid px-3 py-2 text-black"
      type="text"
      required
      placeholder="Your name:"
      :data-testid="props.testId + '/NameInput'"
    />

    <input
      v-model="body.email"
      class="border-button border-2 border-solid px-3 py-2 text-black"
      type="email"
      required
      placeholder="Your email:"
      :data-testid="props.testId + '/EmailInput'"
    />

    <select
      v-model="body.subject"
      class="border-button border-2 border-solid px-3 py-2 text-black"
      required
      :data-testid="props.testId + '/SubjectSelect'"
    >
      <option value="" selected="true" disabled>Choose subject:</option>
      <option>Payment</option>
      <option>Technical issue</option>
      <option>Feedback</option>
      <option>Other</option>
    </select>

    <textarea
      v-model="body.message"
      class="border-button border-2 border-solid px-3 py-2 text-black"
      required
      placeholder="Write a message..."
      :data-testid="props.testId + '/MessageTextArea'"
    ></textarea>

    <button
      :disabled="status === 'sending' || status === 'success'"
      class="btn w-min"
      :data-testid="props.testId + '/SendButton'"
    >
      {{ $translate(translations?.send )}}
    </button>
  </form>
</template>

