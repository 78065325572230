import { defineStore } from 'pinia';
import { ref, watch, onBeforeUnmount } from 'vue';
import { onBeforeRouteLeave } from '#vue-router';
import { useFiltersStore, useProfileStore, useModalStore, useSiteConfigStore } from '../stores';
import { type ProfileCard, EModal } from '../types';
import {
  storageKeys,
  storeValue,
  getStoredValue,
  arrayShuffle,
  emptyCard,
  emptyFilterCard,
  log
} from '../utils';

const QUEUE_SIZE = 30;

export const useMatchGameStore = defineStore('matchgame', () => {
  const modalStore = useModalStore();
  const profileStore = useProfileStore();
  const filterStore = useFiltersStore();

  const profiles = ref<ProfileCard[]>([]);
  const pageNumber = ref<number>(-1);
  const totalCount = ref<number>(0);
  const totalPages = ref<number>(0);
  const availablePages = ref<number[]>([-1]);
  const maxPage = ref<number>(-1);
  const direction = ref<1 | -1>(1);
  const profilesAvailable = ref<boolean>(true);
  const noMoreProfilesText = ref<string>('');
  const firstFetchEmpty = ref<boolean>(false);
  const firstFetch = ref<boolean>(pageNumber.value === -1);
  const domain = useSiteConfigStore().siteSettings?.name;
  const matchGameSeenKey = domain + '_' + storageKeys.matchGame.seenProfiles;
  const seenProfiles = ref<string[]>(getStoredValue(matchGameSeenKey) ?? []);

  function getRandomPage() {
    const index = Math.floor(Math.random() * availablePages.value.length);
    let pageNumber = availablePages.value.splice(index, 1)[0];
    if (pageNumber === undefined) {
      pageNumber = maxPage.value;
      maxPage.value = -1;
    }
    return pageNumber;
  }

  async function fetchProfiles() {
    if (profiles.value.length >= QUEUE_SIZE) return;
    if (!profilesAvailable.value) {
      const lastCard = profiles.value.at(-1);
      if (lastCard && lastCard.profile_id.startsWith('empty')) {
        return;
      }
    }

    pageNumber.value = getRandomPage();
    firstFetch.value = profiles.value.length === 0 && pageNumber.value === -1;
    const data = await profileStore.getMatchGameProfiles(pageNumber.value, QUEUE_SIZE);
    if (firstFetch.value) {
      firstFetchEmpty.value = data.totalCount === 0;
      totalCount.value = data.totalCount;
      if (totalCount.value > 0) {
        totalPages.value = Math.ceil(totalCount.value / QUEUE_SIZE);
        availablePages.value = [...Array(totalPages.value).keys()];
        maxPage.value = availablePages.value.pop() ?? -1;
        const usedPageIndex = availablePages.value.indexOf(data.usedPage);
        availablePages.value.splice(usedPageIndex, 1);
      }
    }
    const shouldAddToQueue = !data.noProfiles || firstFetchEmpty.value;
    if (data.profiles != null && data.profiles.length > 0 && shouldAddToQueue) {
      const filteredData = data.profiles.filter(
        (x) => !profiles.value.find((y) => y.profile_id === x.profile_id)
      );
      const shuffled = arrayShuffle(filteredData);
      for (const newProfile of shuffled) {
        if (!seenProfiles.value.includes(newProfile.profile_id)) {
          profiles.value.push(newProfile);
        }
      }
    }

    if (data.noProfiles || (profiles.value.length >= totalCount.value && totalCount.value !== 0)) {
      const shouldStopFetching = data.profiles?.length < QUEUE_SIZE || !firstFetchEmpty.value;
      if (shouldStopFetching || profiles.value.length >= totalCount.value) {
        endOfFetching();
      }
    }

    profilesAvailable.value = !data.noProfiles;
  }

  function endOfFetching() {
    if (!profilesAvailable.value) return;
    profilesAvailable.value = false;

    const endProfile: ProfileCard =
      filterStore.isDefaultFilter() || firstFetchEmpty.value ? emptyCard : emptyFilterCard;

    const lastCardIndex = profiles.value.length - 1;
    const lastCard = profiles.value[lastCardIndex];
    if (!lastCard || lastCard.profile_id === endProfile.profile_id) return;

    if (lastCard.profile_id.startsWith('empty')) {
      profiles.value.pop();
    }
    profiles.value.push(endProfile);
  }

  async function pressButton(like: boolean) {
    direction.value = like ? 1 : -1;

    const profile = profiles.value.shift();
    if (!profile) return;
    seenProfiles.value.push(profile.profile_id);
    storeValue(matchGameSeenKey, seenProfiles.value);
    const matched = await profileStore.likeFunction(profile.profile_id, like);
    if (like && matched) {
      modalStore.showModalComponent(EModal.matchedModalComponent, false, profile);
    }

    await fetchProfiles();
  }

  function getSavedtutorialState() {
    const savedState = getStoredValue(storageKeys.matchGame.tutorial);
    if (savedState != null && savedState != undefined) {
      return savedState;
    }
    return true;
  }

  watch(
    () => filterStore.filtersUpdated,
    () => {
      profilesAvailable.value = true;
      profiles.value = [];
      pageNumber.value = -1;
      maxPage.value = -1;
      availablePages.value = [-1];
      totalCount.value = 0;
      fetchProfiles();
    }
  );

  return {
    profiles,
    direction,
    noMoreProfilesText,
    profilesAvailable,
    totalCount,

    getSavedtutorialState,
    fetchProfiles,
    pressButton
  };
});

