<script lang="ts" setup>
import type { HorizonComponentProps, Notification } from '../../../types';
import { ref, onMounted, onUnmounted } from 'vue';
import { navigateTo } from '#app';
import type { Props } from './Message.schema';
import type { NotivueItem } from 'notivue';

interface CustomProps {
  notification: Notification;
}

const props = defineProps<
  HorizonComponentProps<Props> & {
    item: NotivueItem<CustomProps>;
  }
>();
const duration = ref(props.item.props.notification.duration / 1000 + 's');
const transformDirectionMobile = props.data?.placementMobile.includes('left') ? '-100%' : '100%';
const transformDirectionDesktop = props.data?.placementDesktop.includes('left') ? '-100%' : '100%';

let notificationTimeout: ReturnType<typeof setTimeout>;
onMounted(() => {
  notificationTimeout = setTimeout(() => {
    clearNotification();
  }, props.item.props.notification.duration);
});
onUnmounted(() => {
  clearTimeout(notificationTimeout);
});

function clearNotification() {
  props.item.clear();
}

function goToChat() {
  clearNotification();
  if (props.item.props.notification.profile) {
    navigateTo(`/message/${props.item.props.notification.profile.id}`);
  } else {
    navigateTo('/chat');
  }
}

function goToProfile() {
  clearNotification();
  if (props.item.props.notification.profile) {
    navigateTo(`/profile/${props.item.props.notification.profile.id}`);
  } else {
    navigateTo('/');
  }
}
</script>

<template>
  <svg
    class="circle-outline absolute h-full w-full rounded-full sm:hidden"
    :data-testid="props.testId + '/MobileGoToChatButton'"
    @click="goToChat()"
  >
    <circle cx="50" cy="50" r="50" stroke-width="7" fill="none" />
  </svg>
  <div class="z-30 h-[100px] w-[100px]">
    <div
      class="bg-secondary text-text absolute left-1 top-1 z-30 flex h-5 w-5 items-center justify-center rounded-full text-sm sm:hidden"
    >
      1
    </div>
    <img
      class="shadow-glow relative right-2 h-full w-full cursor-pointer rounded-full shadow-black/40"
      :src="props.item.props.notification.profile?.images.at(0)?.src"
      :alt="props.item.props.notification.profile?.images.at(0)?.alt"
      :data-testid="props.testId + '/GoToProfileButton'"
      @click="goToProfile"
    />
  </div>
  <div class="hidden h-full w-[250px] flex-col items-end justify-around font-light sm:flex">
    <div
      class="relative bottom-2 w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-xl text-left text-base font-bold"
    >
      {{ props.item.props.notification.profile?.name + ' says:' }}
    </div>
    <div
      class="relative bottom-1 w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-xl bg-gray-200 p-2.5 text-left text-sm"
    >
      {{ props.item.props.notification.text }}
    </div>
    <ButtonSimple
      class="bg-btn-primary-bg hover:bg-btn-primary-hover-bg text-btn-primary-text relative top-2 z-30 uppercase"
      :data="{ label: props.data?.buttonLabel ?? 'TO MESSAGE' }"
      :test-id="props.testId"
      @click="goToChat()"
    />
  </div>
  <div class="hidden sm:block">
    <Icon
      name="fa-solid:times"
      class="text-tertiary hover:text-primary absolute right-0.5 top-0.5 z-30 cursor-pointer text-xl"
      :data-testid="props.testId + '/ClearButton'"
      @click="clearNotification"
    />
  </div>
  <svg class="line absolute left-0 top-0 hidden w-full sm:block">
    <line x1="0" y1="140" x2="100%" y2="140" stroke-width="3" />
  </svg>
</template>

<style scoped>
.circle-outline {
  animation-name: emptyCircle;
  animation-timing-function: linear;
  animation-duration: v-bind('duration');
  box-shadow: 0 0 9px 3px pink;
  stroke: var(--secondary-color);
}

@keyframes emptyCircle {
  0% {
    stroke-dasharray: 315 315;
  }
  20% {
    stroke-dasharray: 315 315;
  }
  95% {
    stroke-dasharray: 0 315;
  }
  100% {
    stroke-dasharray: 0 315;
  }
}
.line {
  animation-name: emptyLine;
  animation-timing-function: linear;
  animation-duration: v-bind('duration');
  stroke: var(--primary-color);
}

@keyframes emptyLine {
  0% {
    stroke-dasharray: 400 400;
  }
  20% {
    stroke-dasharray: 400 400;
  }
  95% {
    stroke-dasharray: 0 400;
  }
  100% {
    stroke-dasharray: 0 400;
  }
}

@media screen and (max-width: 640px) {
  .slide-in-out {
    animation: slide-in-out-mobile 250ms linear;
  }
}

@media screen and (min-width: 640px) {
  .slide-in-out {
    animation: slide-in-out-desktop 250ms linear;
  }
}

@keyframes slide-in-out-mobile {
  0% {
    transform: translateX(v-bind('transformDirectionMobile'));
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-out-desktop {
  0% {
    transform: translateX(v-bind('transformDirectionDesktop'));
  }
  100% {
    transform: translateX(0);
  }
}
</style>

