<script lang="ts" setup>
import { useWindowSize } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, onMounted, onBeforeUnmount, ref } from 'vue';
import { useChatStore, useSiteConfigStore } from '../../stores';
import type { HorizonComponentProps } from '../../types';
import ChatBox from './ModernFlingSupport/ChatBox.vue';
import ChatList from './ModernFlingSupport/ChatList.vue';
import ChatMailboxSelector from './ModernFlingSupport/ChatMailboxSelector.vue';
import MessageList from './ModernFlingSupport/MessageList.vue';
import ProfileHeader from './ModernFlingSupport/ProfileHeader.vue';
import { getSingleRouteParam } from '../../utils/route';
import { useRoute } from '#imports';
import { getEmptyProfile, getEmptyProfileMessages } from '../../utils/emptyProfile';

const props = defineProps<HorizonComponentProps>();

const siteConfigStore = useSiteConfigStore();

const chatStore = useChatStore();
const { currentChatUser, currentTab, currentTabProfiles } = storeToRefs(chatStore);

const { width } = useWindowSize();
const isDesktop = computed<boolean>(() => width.value > 1024);

const route = useRoute();
const paramID = getSingleRouteParam(route.params, 'id');

async function tryLoadUser() {
  if (!paramID) {
    const image =
      siteConfigStore.siteSettings?.favicon ?? siteConfigStore.siteSettings?.logo.src ?? '';
    const name = siteConfigStore.siteSettings?.name ?? '';
    currentChatUser.value = getEmptyProfile(name, image);
    return;
  }

  currentChatUser.value = chatStore.getProfileByID(paramID);
  if (!currentChatUser.value) {
    currentChatUser.value = await chatStore.newProfile(paramID);
  }

  if (currentChatUser.value) {
    currentTab.value = currentChatUser.value.conversation ? 'chat' : 'mail';
  }
}

const counter = ref<number>(0);
const interval = ref<ReturnType<typeof setInterval>>();

function noChatMessages(msgDelay: number = 1200) {
  const messages: number = getEmptyProfileMessages().length;
  interval.value = setInterval(() => {
    if (counter.value === messages) {
      clearInterval(interval.value);
      interval.value = undefined;
    } else {
      currentChatUser.value?.messages?.push(
        getEmptyProfileMessages(currentTabProfiles.value.length < 1)[counter.value]
      );
      counter.value++;
    }
  }, msgDelay);
}

function clearMessages() {
  if (interval.value) {
    clearInterval(interval.value);
  }
}

onMounted(async () => {
  await chatStore.initStore();
  await tryLoadUser();

  if (!paramID) {
    noChatMessages();
  } else {
    clearMessages();
  }
});
onBeforeUnmount(() => {
  clearMessages();
});
</script>

<template>
  <div class="container mb-16 grid h-full md:mb-0">
    <div class="grid-container border-border border-2 border-t-0">
      <ChatMailboxSelector
        v-show="!currentChatUser || isDesktop"
        class="border-border border-b-2 border-r-2"
        :data="{}"
        :test-id="props.testId"
      ></ChatMailboxSelector>
      <ProfileHeader
        v-show="currentChatUser || isDesktop"
        class="border-border border-b-2"
        :data="{}"
        :test-id="props.testId"
      ></ProfileHeader>

      <ChatList
        v-show="!currentChatUser || isDesktop"
        class="border-border border-r-2"
        :class="{ 'row-span-2': !currentChatUser || isDesktop }"
        :data="{}"
        :test-id="props.testId"
      ></ChatList>
      <MessageList
        v-show="currentChatUser || isDesktop"
        :data="{}"
        :test-id="props.testId"
      ></MessageList>

      <ChatBox
        v-show="currentChatUser || isDesktop"
        class="border-border border-t-2"
        :data="{}"
        :test-id="props.testId"
      ></ChatBox>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr minmax(60px, auto);
  height: 100%;
}

@screen lg {
  .grid-container {
    grid-template-columns: 1fr 1fr;
  }
}

@screen xl {
  .grid-container {
    grid-template-columns: 500px 1fr;
  }
}
</style>

