<script setup lang="ts" generic="T">
import { useSiteConfigStore } from '../../stores';
import type { ProfileCard } from '../../types';
import { getSkeletonGrid, overrideStyles } from '../../utils';
import Card from './ModernflingSupport/Card.vue';
import { storeToRefs } from 'pinia';
import { toRefs, computed } from 'vue';

type Props = {
  itemWidth: number;
  itemHeight: number;
  colCount: number;
  isLoading?: boolean;
  items?: ProfileCard[];
  maxCards?: number;
};
const props = defineProps<Props>();
const { itemWidth, itemHeight, colCount } = toRefs(props);

const siteConfigStore = useSiteConfigStore();
const { profileCardComponent } = storeToRefs(siteConfigStore);
const grid = computed(() => props.items ?? getSkeletonGrid(props.maxCards ?? 24));
const loading = computed(() => props.isLoading ?? true);
const width = computed(() => `${itemWidth.value}px`);
const height = computed(() => `${itemHeight.value}px`);
</script>

<template>
  <div
    class="grid gap-2"
    :class="{
      'grid-cols-2': colCount === 2,
      'grid-cols-3': colCount === 3,
      'grid-cols-4': colCount === 4,
      'grid-cols-5': colCount === 5,
      'grid-cols-6': colCount === 6
    }"
  >
    <div v-for="card in grid" :key="card.profile_id" class="skeletoncard h-full pb-2 pe-2">
      <Card
        :data="{
          profile: card,
          isDetailPageCard: false,
          isLoading: loading
        }"
        :style="overrideStyles(profileCardComponent?.overrides)"
        :test-id="null"
      ></Card>
    </div>
  </div>
</template>
<style scoped lang="postcss">
.scroller {
  height: 100%;
  margin: 0 auto;
}
.skeletoncard {
  width: v-bind(width);
  height: v-bind(height);
}
</style>

