<script setup lang="ts">
import { storeToRefs } from '#imports';
import { useChatStore } from '../../../stores';
import type { HorizonComponentProps } from '../../../types';

const props = defineProps<HorizonComponentProps>();

const chatStore = useChatStore();
const { currentTab } = storeToRefs(chatStore);
</script>

<template>
  <div :data-testid="props.testId + '/ChatMailboxSelector'">
    <button
      class="bg-background h-full w-1/2"
      :class="[currentTab === 'chat' ? 'active' : 'inactive']"
      :data-testid="props.testId + '/ChatBoxButton'"
      @click="currentTab = 'chat'"
    >
      <Icon name="ph:chats-circle" />
      Chats
    </button>

    <button
      class="h-full w-1/2"
      :class="[currentTab === 'mail' ? 'active' : 'inactive']"
      :data-testid="props.testId + '/MailBoxButton'"
      @click="currentTab = 'mail'"
    >
      <Icon name="fluent:mail-inbox-16-regular" />
      Mailbox
    </button>
  </div>
</template>

<style scoped lang="postcss">
.active {
  @apply bg-modal-backdrop text-primary;
}
.inactive {
  @apply bg-background text-header-text;
}
</style>