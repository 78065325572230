<script setup lang="ts">
import { computed, storeToRefs } from '#imports';
import { useSiteConfigStore } from '../../../stores';
import { useChatStore } from '../../../stores/chat.store';
import type { HorizonComponentProps } from '../../../types';
import { dateToAge, getEmptyProfile } from '../../../utils';

const props = defineProps<HorizonComponentProps>();

const chatStore = useChatStore();
const { currentChatUser } = storeToRefs(chatStore);

const siteConfigStore = useSiteConfigStore();

const image =
  siteConfigStore.siteSettings?.chatlogo?.src ?? siteConfigStore.siteSettings?.logo.src ?? '';
const name = siteConfigStore.siteSettings?.name ?? '';
const emptyProfile = getEmptyProfile(name, image);

const user = computed(() => currentChatUser.value ?? emptyProfile);

const cover = computed(() =>
  currentChatUser?.value?.card.profile_id === 'none' ? 'object-contain' : 'object-cover'
);
</script>

<template>
  <div :data-testid="props.testId + '/ProfileHeader'" class="flex p-2">
    <button
      class="inline-flex flex-row items-center text-4xl font-bold lg:hidden"
      :data-testid="props.testId + '/BackButton'"
      @click="currentChatUser = undefined"
    >
      <Icon name="ic:chevron-left" class="text-primary" />
    </button>

    <NuxtLink
      class="flex items-center gap-3"
      :to="user.card.profile_id === 'none' ? '/members' : `/profile/${user.card.profile_id}`"
      :data-testid="props.testId + '/ProfileImageButton'"
    >
      <img
        v-if="user.card.image.url"
        class="aspect-square h-full overflow-hidden rounded-full"
        :class="cover"
        :src="user.card.image.url"
        :alt="user.card.profile_id"
      />

      <div>
        <h1 class="text-xl font-bold sm:text-lg">
          {{ user.card.name }}
        </h1>
        <p v-if="user.card.date_of_birth" class="text-header-text/60 text-xs">
          {{ dateToAge(user.card.date_of_birth) }} Years old
        </p>
      </div>
    </NuxtLink>
  </div>
</template>

