<script setup lang="ts">
import type { IProfileCardProps, HorizonComponentProps } from '../../../types';
import { useModalStore, useSiteConfigStore } from '../../../stores';
import { dateToAge } from '../../../utils';
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router';

const modalStore = useModalStore();
function showFiltersModal() {
  modalStore.showFilterModal({ showLocationFilter: true });
}
const siteConfigStore = useSiteConfigStore();
const shimmerBackground = siteConfigStore.siteConfig?.theme.colors?.shimmerBackground ?? '#1f0330';
const shimmerHighlight = siteConfigStore.siteConfig?.theme.colors?.shimmerHighlight ?? '#3e0561';

const props = defineProps<HorizonComponentProps<IProfileCardProps>>();
const noMoreProfilesText = ref<string>('');
const profile = computed(() => props.data.profile);
const route = useRoute();
const homepage = computed(() => {
  return route.name === 'Home';
});

const isSkeletonCard = computed<boolean>(() =>
  props.data.profile.profile_id.split('#')[1] ? true : false
);

if (profile.value && profile.value.profile_id?.startsWith('empty')) {
  noMoreProfilesText.value = profile.value.profile_id?.split('.')[1];
}

const emit = defineEmits<{
  (e: 'refetch', event: Event, id: string): void;
}>();

function imgError(event: Event) {
  emit('refetch', event, profile.value.profile_id);
}
</script>

<template>
  <div>
    <template v-if="props.data?.isLoading && isSkeletonCard">
      <div
        :class="{ 'cursor-point': !props.data?.isDetailPageCard }"
        class="card shimmer h-4/5 border-4 border-[#0f0218] bg-transparent"
      >
        <div class="mx-auto aspect-square h-full w-4/5"></div>
        <div class="overlay2 bg-background fixed left-0 top-0 z-20 h-full w-full"></div>
        <footer class="h-16 bg-transparent py-1 text-center">
          <div class="overlay bg-background fixed bottom-0 left-0 z-20 h-16 w-full"></div>
        </footer>
      </div>
    </template>
    <template v-else-if="profile.profile_id.startsWith('empty')">
      <div
        class="text-header-text relative hidden aspect-[5/6] rounded-xl text-lg md:block lg:text-base xl:text-lg"
      >
        <div
          class="shadow-softglow bg-primary absolute block h-full w-full overflow-hidden rounded-xl p-4 text-sm shadow-black/40"
        >
          <div class="bg-primary mb-2 flex flex-row items-center gap-4 text-center">
            <p>{{ noMoreProfilesText }}</p>
          </div>

          <div class="border-primary relative mb-2 w-full border-0 bg-transparent text-center">
            Change your filters for new results
          </div>
          <button
            class="btn relative w-full border border-white bg-transparent p-2 text-center text-sm"
            :data-testid="props.testId + '/FilterButton'"
            @click="showFiltersModal()"
          >
            Select filters
          </button>
        </div>
      </div>

      <div
        class="mobilecard text-header-text relative block aspect-[5/6] rounded-xl text-base md:hidden"
      >
        <div
          class="shadow-softglow bg-primary absolute block size-full overflow-hidden rounded-xl p-4 text-sm shadow-black/40"
        >
          <div class="bg-primary mb-2 flex flex-row items-center gap-4 text-center">
            <p>{{ noMoreProfilesText }}</p>
          </div>

          <div class="border-primary relative mb-2 w-full border-0 bg-transparent text-center">
            Change your filters for new results
          </div>
          <button
            class="btn relative w-full border border-white bg-transparent p-2 text-center text-sm"
            :data-testid="props.testId + '/MobileFilterButton'"
            @click="showFiltersModal()"
          >
            Select filters
          </button>
        </div>
      </div>
    </template>
    <template v-else-if="props.data?.profile">
      <div
        class="relative hidden aspect-[5/6] rounded-xl text-lg text-white md:block"
        :class="{ card: !homepage }"
      >
        <div class="outer h-full w-full transition-transform duration-700 ease-in-out">
          <div
            class="front shadow-softglow absolute h-full w-full overflow-hidden rounded-xl shadow-black/40"
          >
            <div class="gradient pointer-events-none relative h-full w-full">
              <img
                v-if="profile.image?.url"
                :key="profile.image.url"
                class="h-full w-full rounded-xl object-cover"
                :src="profile.image.url"
                alt=""
                :onerror="imgError"
              />
            </div>

            <div class="absolute bottom-0 z-10 flex w-full bg-black/20 p-3 backdrop-blur-[3px]">
              <Icon name="fluent-emoji-flat:green-circle" size="10" class="relative top-3 mr-2" />
              <div>
                <p class="text-sm font-bold">
                  {{ profile.name }}
                </p>
                <p class="text-xs">{{ dateToAge(profile.date_of_birth) }} years old</p>
              </div>
            </div>
          </div>

          <div
            v-if="!homepage"
            class="back shadow-softglow bg-primary absolute block h-full w-full overflow-hidden rounded-xl p-4 shadow-black/40"
          >
            <div class="bg-primary mb-4 flex flex-col items-center gap-1">
              <img
                v-if="profile.image?.url"
                class="h-12 w-12 rounded-full border-2 border-white object-cover"
                :src="profile.image.url"
                alt=""
                :onerror="imgError"
              />
              <p class="text-sm">{{ profile.name }}</p>
            </div>

            <NuxtLink
              :to="`/profile/${profile.profile_id}`"
              class="btn relative mb-2 w-full border border-white bg-transparent text-center text-sm"
              :data-testid="props.testId + '/ProfileButton'"
            >
              View profile
            </NuxtLink>
            <NuxtLink
              class="btn relative w-full border border-white bg-transparent text-center text-sm"
              :to="`/message/${profile.profile_id}`"
              :data-testid="props.testId + '/ToChatButton'"
            >
              Send message
            </NuxtLink>
          </div>
        </div>
      </div>

      <div class="mobilecard relative block aspect-[5/6] rounded-xl text-lg text-white md:hidden">
        <div class="outer h-full w-full">
          <NuxtLink
            :to="`/profile/${profile.profile_id}`"
            class="relative block h-full w-full"
            :data-testid="props.testId + '/MobileCard'"
          >
            <div
              class="front shadow-softglow absolute h-full w-full overflow-hidden rounded-xl shadow-black/40"
            >
              <div class="gradient pointer-events-none relative h-full w-full">
                <img
                  v-if="profile.image?.url"
                  class="h-full w-full rounded-xl object-cover"
                  :src="profile.image.url"
                  alt=""
                  :onerror="imgError"
                />
              </div>

              <div class="absolute bottom-0 z-10 flex w-full bg-black/20 p-3 backdrop-blur-[3px]">
                <Icon name="fluent-emoji-flat:green-circle" size="10" class="relative top-3 mr-2" />
                <div>
                  <p class="text-sm font-bold">
                    {{ profile.name }}
                  </p>
                  <p class="text-xs">{{ dateToAge(profile.date_of_birth) }} years old</p>
                </div>
              </div>
            </div>
          </NuxtLink>
        </div>
      </div>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.card {
  transform-style: preserve-3d;
  perspective: 1000px;

  & > div {
    transform-style: preserve-3d;
  }

  &:hover > div {
    transform: rotateY(180deg);
  }

  .back,
  .front {
    backface-visibility: hidden;
    z-index: 1;
  }

  .back {
    transform: rotateY(180deg);
    z-index: 1;
  }
}

.mobilecard {
  .front {
    backface-visibility: hidden;
    z-index: 1;
  }
}

.gradient {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;

    height: 100%;
    width: 100%;

    background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 1) 100%);
  }
}
.shimmer {
  /* background: linear-gradient(to right, #1f0330 40%, #2e0548 50%, #1f0330 60%); */
  background: linear-gradient(
    130deg,
    v-bind(shimmerBackground) 40%,
    v-bind(shimmerHighlight) 50%,
    v-bind(shimmerBackground) 60%
  );
  background-size: 300%;
  z-index: 0;
  background-position-x: 120%;
  animation: 2s ease-out infinite shimmering;
}

@keyframes shimmering {
  to {
    background-position-x: 0%;
  }
}

.overlay {
  /* Defines the portion of the footer part to be drawn and clipped */
  /* unrounded */
  clip-path: polygon(
    /* Top left outside corner */ 0% 0%,
    /* Bottom left outside corner */ 0% 50%,
    /* Bottom edge below left inside corners */ 1.5rem 50%,
    /* Top left insideside corner */ 1.5rem calc(100% - 3.375rem),
    /* Top right insideside corner */ calc(100% - 1.5rem) calc(100% - 3.375rem),
    /* Bottom right insideside corner */ calc(100% - 1.5rem) calc(100% - 2.125rem),
    /* Bottom left insideside corner */ 1.5rem calc(100% - 2.125rem),
    /* Bottom edge below left inside corners again */ 1.5rem 50%,
    /* Bottom right outside corner */ 100% 50%,
    /* Top right outside corner */ 100% 0%,
    /* end of big hole*/ 0% 0%,
    /* beginning of small hole */ 0% 50%,
    /* Bottom left outside corner */ 0% 100%,
    /* Bottom edge below left inside corners */ 3rem 100%,
    /* Top left insideside corner */ 3rem calc(100% - 1.875rem),
    /* Top right insideside corner */ calc(100% - 3rem) calc(100% - 1.875rem),
    /* Bottom right insideside corner */ calc(100% - 3rem) calc(100% - 0.875rem),
    /* Bottom left insideside corner */ 3rem calc(100% - 0.875rem),
    /* Bottom edge below left inside corners again */ 3rem 100%,
    /* Bottom right outside corner */ 100% 100%,
    /* Top right outside corner */ 100% 50%,
    0% 50%
  );
}
.overlay2 {
  /* Defines the portion of the card part to be drawn and clipped */
  clip-path: polygon(
    /* Top left outside corner */ 0% 0%,
    /* Bottom left outside corner */ 0% calc(100% - 4rem),
    /* Bottom edge below left inside corners */ 0.75rem calc(100% - 4rem),
    /* Top left insideside corner */ 0.75rem 0.75rem,
    /* Top right insideside corner */ calc(100% - 0.75rem) 0.75rem,
    /* Bottom right insideside corner */ calc(100% - 0.75rem) calc(100% - 4rem),
    /* Bottom left insideside corner */ 0.75rem calc(100% - 4rem),
    /* Bottom edge below left inside corners again */ 0.75rem calc(100% - 4rem),
    /* Bottom right outside corner */ 100% calc(100% - 4rem),
    /* Top right outside corner */ 100% 0%
  );
}
</style>

