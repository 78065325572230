import { navigateTo } from '#app';
import { useAuthStore } from '@horizon/library/stores/auth.store';
import { hasPartnerParameters } from '@horizon/library/utils/partner';

export default defineNuxtRouteMiddleware(async (to, from) => {
  // If the user is already signed in, no point forwarding the parameters
  const authStore = useAuthStore();
  if (authStore.isAuthorized) return;

  // Only copy query parameters if we actually have partner flags in the URL (Email) or from a cookie (SSO)
  if (!hasPartnerParameters(from.query)) return;

  // Do not forward parameters if they have already been applied to prevent infinite loop
  if (hasPartnerParameters(to.query)) return;

  // Forward query parameters from the current page to the next page
  const routeLocationWithForwardedParams = {
    ...to,
    query: {
      ...to.query,
      ...from.query
    }
  };

  return navigateTo(routeLocationWithForwardedParams);
});

