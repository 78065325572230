import type { FirebaseError } from 'firebase/app';
import { getApps, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken, type Auth } from 'firebase/auth';
import type { Firestore } from 'firebase/firestore';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { defineStore } from 'pinia';
import { createChatFetchClient } from '../composables/apiClients';
import { useRuntimeConfig } from '#app';
import { computed, ref } from 'vue';
import { jwtDecode, type JwtPayload } from 'jwt-decode';

const CHAT_FIRESTORE_APP_NAME = 'chat';

interface CHBToken extends JwtPayload {
  claims: {
    email: string;
    geos: string[];
    locale: string;
    snowflake: boolean;
    tenant: string;
    user_id: string;
  };
}

export const useChatFireStore = defineStore('chatFirestore', () => {
  const chatPostClient = createChatFetchClient();
  const runtimeConfig = useRuntimeConfig();

  const chbToken = ref<CHBToken>();

  const isTokenExpired = computed<boolean>(() => {
    if (!chbToken.value) return true;
    if (chbToken.value.exp === undefined) return true;

    const inTenMinutes = Date.now() + 1000 * 60 * 10;
    return inTenMinutes >= chbToken.value.exp * 1000;
  });

  const isSnowflake = computed<boolean>(() => {
    if (!runtimeConfig.public.isHosted) {
      return false;
    }

    return chbToken.value //
      ? chbToken.value.claims.snowflake
      : true;
  });

  async function getCloudFirestoreToken(): Promise<string | undefined> {
    const { data, error: messageError } = await chatPostClient('/auth/token', {
      method: 'POST'
    });

    if (!data || messageError) {
      console.error(`unable to get firestore token: ${messageError}`);
      return undefined;
    }

    return data.token;
  }

  async function authenticateCloudFirestoreApp(cloudAuth: Auth, customToken: string) {
    let resultCode: string | undefined;
    const result = await signInWithCustomToken(cloudAuth, customToken).catch((e: FirebaseError) => {
      resultCode = e.code;
    });

    if (resultCode || !result) {
      return {
        errorCode: resultCode ?? 'auth/invalid-credential'
      };
    }

    const idToken = await result.user.getIdToken().catch();
    if (!idToken)
      return {
        errorCode: 'auth/idtoken-fetch-failed'
      };

    return {
      errorCode: undefined
    };
  }

  async function getChatFirestore(): Promise<Firestore | undefined> {
    if (useRuntimeConfig().public.isHosted) {
      return getCloudFirestore();
    } else {
      return getEmulatorFirestore();
    }
  }

  async function getCloudFirestore(): Promise<Firestore | undefined> {
    const existingCloudApp = getApps().find((x) => x.name === CHAT_FIRESTORE_APP_NAME);
    if (existingCloudApp) getFirestore(existingCloudApp);

    if (!runtimeConfig.public.chatHomeBaseFirestoreConfig) {
      console.error('Could not initialize cloud firestore instance: firebaseOptions not provided');
      return;
    }

    const cloudApp = initializeApp(
      runtimeConfig.public.chatHomeBaseFirestoreConfig,
      CHAT_FIRESTORE_APP_NAME
    );
    const cloudAuth = getAuth(cloudApp);

    if (isTokenExpired.value) {
      const customToken = await getCloudFirestoreToken();
      if (!customToken) {
        console.error('Error authenticating with firestore: unable to get firestore token');
        chbToken.value = undefined;
        return;
      }

      const authenticationResult = await authenticateCloudFirestoreApp(cloudAuth, customToken);
      if (authenticationResult.errorCode != undefined) {
        console.error('Error authenticating with firestore', authenticationResult.errorCode);
        chbToken.value = undefined;
        return;
      }

      chbToken.value = jwtDecode<CHBToken>(customToken);
    }

    return getFirestore(cloudApp);
  }

  async function getEmulatorFirestore(): Promise<Firestore | undefined> {
    let chatFirebaseApp = getApps().find((x) => x.name === CHAT_FIRESTORE_APP_NAME);
    if (!chatFirebaseApp) {
      chatFirebaseApp = initializeApp(
        {
          projectId: 'chao-demo',
          apiKey: '_',
          authDomain: 'https://auth.firebase.hn.dev'
        },
        CHAT_FIRESTORE_APP_NAME
      );

      connectFirestoreEmulator(
        getFirestore(chatFirebaseApp),
        'firestore.firebase.pangaea.hn.dev',
        443
      );
    }

    return getFirestore(chatFirebaseApp);
  }

  return {
    chbToken,
    isSnowflake,

    getChatFirestore
  };
});

