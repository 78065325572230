import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useSiteConfigStore } from '../../stores';
import { LegalTextKey } from '../../types';

export function useFooter(overrideOrder: string[] = []) {
  const configStore = useSiteConfigStore();
  const { pages } = storeToRefs(configStore);
  // TODO: Translations for FooterLink.label
  const legalTextKeys = Object.values<string>(LegalTextKey.Values);
  const footerLinks = (pages.value ?? [])
    .filter((x) => x.key && legalTextKeys.includes(x.key))
    .map((x) => ({ label: x.name, route: x.path }));
  const order: string[] =
    overrideOrder.length > 0 ? overrideOrder : (configStore.siteSettings?.footerLinkOrder ?? []);
  if (order.length > 1) {
    footerLinks.sort(function (a, b) {
      const indexA = order.indexOf(a.label);
      const indexB = order.indexOf(b.label);
      return indexA - indexB;
    });
  }

  const showFooter = ref<boolean>(true);

  return {
    showFooter,
    footerLinks
  };
}

