<script lang="ts" setup>
import type { HorizonComponentProps, Notification } from '../../../types';
import { ref, onMounted, onUnmounted } from 'vue';
import { navigateTo } from '#app';
import type { Props } from './Message.schema';
import type { NotivueItem } from 'notivue';

interface CustomProps {
  notification: Notification;
}

const props = defineProps<
  HorizonComponentProps<Props> & {
    item: NotivueItem<CustomProps>;
  }
>();
const duration = ref(props.item.props.notification.duration / 1000 + 's');
const transformDirectionMobile = props.data?.placementMobile.includes('left') ? '-100%' : '100%';
const transformDirectionDesktop = props.data?.placementDesktop.includes('left') ? '-100%' : '100%';
const success = props.item.props.notification.success;
let notificationTimeout: ReturnType<typeof setTimeout>;
onMounted(() => {
  notificationTimeout = setTimeout(() => {
    clearNotification();
  }, props.item.props.notification.duration);
});
onUnmounted(() => {
  clearTimeout(notificationTimeout);
});

function clearNotification() {
  props.item.clear();
}

function goToChat() {
  clearNotification();
  navigateTo(`/chat`);
}
</script>

<template>
  <div class="z-30 flex h-full w-[65px]">
    <Icon
      :name="success ? 'mdi:check-circle' : 'mdi:cross-circle-outline'"
      class="text-primary my-auto"
      size="40"
    />
  </div>
  <div class="hidden h-full w-full flex-col justify-around font-light sm:flex">
    <!-- <div
      class="relative bottom-1 h-full w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-xl bg-gray-200 p-2.5 text-left text-sm"
    >
      {{ props.item.title }}
    </div>
    <div
      class="relative bottom-1 h-full w-full overflow-hidden text-ellipsis whitespace-nowrap rounded-xl bg-gray-200 p-2.5 text-left text-sm"
    >
      {{ props.item.props.notification.text }}
    </div> -->
    <div class="flex flex-col items-center">
      <p class="text-xl font-bold">{{ props.item.title }}</p>
      <p>{{ props.item.props.notification.text }}</p>
    </div>

    <ButtonSimple
      v-if="success"
      class="bg-btn-primary-bg hover:bg-btn-primary-hover-bg text-btn-primary-text top1 relative z-30 mx-auto h-10 w-3/5 uppercase"
      :data="{ label: props.data?.buttonLabel ?? 'TO MESSAGE' }"
      :test-id="props.testId"
      @click="goToChat()"
    />
  </div>
  <div class="hidden sm:block">
    <Icon
      name="fa-solid:times"
      class="text-header-text hover:text-primary absolute right-1.5 top-1.5 z-30 cursor-pointer text-xl"
      :data-testid="props.testId + '/ClearButton'"
      @click="clearNotification"
    />
  </div>
  <svg class="line absolute -bottom-1.5 left-0 hidden w-full sm:block">
    <line x1="0" y1="140" x2="100%" y2="140" stroke-width="3" />
  </svg>
</template>

<style scoped>
.circle-outline {
  animation-name: emptyCircle;
  animation-timing-function: linear;
  animation-duration: v-bind('duration');
  box-shadow: 0 0 9px 3px pink;
  stroke: var(--secondary-color);
}

@keyframes emptyCircle {
  0% {
    stroke-dasharray: 315 315;
  }
  20% {
    stroke-dasharray: 315 315;
  }
  95% {
    stroke-dasharray: 0 315;
  }
  100% {
    stroke-dasharray: 0 315;
  }
}
.line {
  animation-name: emptyLine;
  animation-timing-function: linear;
  animation-duration: v-bind('duration');
  stroke: var(--primary-color);
}

@keyframes emptyLine {
  0% {
    stroke-dasharray: 400 400;
  }
  20% {
    stroke-dasharray: 400 400;
  }
  95% {
    stroke-dasharray: 0 400;
  }
  100% {
    stroke-dasharray: 0 400;
  }
}

@media screen and (max-width: 640px) {
  .slide-in-out {
    animation: slide-in-out-mobile 250ms linear;
  }
}

@media screen and (min-width: 640px) {
  .slide-in-out {
    animation: slide-in-out-desktop 250ms linear;
  }
}

@keyframes slide-in-out-mobile {
  0% {
    transform: translateX(v-bind('transformDirectionMobile'));
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-in-out-desktop {
  0% {
    transform: translateX(v-bind('transformDirectionDesktop'));
  }
  100% {
    transform: translateX(0);
  }
}
</style>

