import dayjs from 'dayjs';
import { defineStore } from 'pinia';
import { createProfilesFetchClient } from '../composables/apiClients';
import { useFiltersStore, useSiteConfigStore } from '../stores';
import type { ProfileCard, ProfileData, QueryBuilderField, QueryBuilderQuery } from '../types';
import { logTranslatedError, throwFatalTranslatedError } from '../utils/useTranslatedError';
import { ref } from 'vue';

export const useProfileStore = defineStore('profile', () => {
  const filterStore = useFiltersStore();
  const profileFetchClient = createProfilesFetchClient();
  const locale = useSiteConfigStore().siteSettings?.localeCode as string;
  const locationState = ref<string>();
  async function favoriteFunction(profileID: string, addToFavorite: boolean) {
    if (addToFavorite) {
      addProfiletoFavorites(profileID);
      return;
    }
    removeProfilefromFavorites(profileID);
  }

  async function addProfiletoFavorites(profileID: string) {
    const { error: profilesError } = await profileFetchClient(
      '/profile-user/favorite/{profileId}',
      {
        method: 'POST',
        path: { profileId: profileID }
      }
    );
    if (profilesError) {
      throwFatalTranslatedError({
        error: profilesError,
        whileVar: 'adding profile to favorites',
        whatVar: '',
        devTitle: 'call to profile-s2d-api POST /profile-user/favorite/{profileId} failed',
        devMessage: profilesError?.data?.error
      });
    }
  }

  async function removeProfilefromFavorites(profileID: string) {
    const { error } = await profileFetchClient('/profile-user/unfavorite/{profileId}', {
      method: 'DELETE',
      path: { profileId: profileID }
    });
    if (error) {
      throwFatalTranslatedError({
        error: error,
        whileVar: 'removing profile from favorites',
        whatVar: '',
        devTitle: 'call to profile-s2d-api DELETE /profile-user/unfavorite/{profileId} failed',
        devMessage: error?.data?.error
      });
    }
  }

  async function getProfileById(id: string) {
    const { data: profileResponse, error: profileError } = await profileFetchClient(
      '/profile/{profileId}',
      {
        method: 'GET',
        path: {
          profileId: id
        }
      }
    );
    if (profileError) {
      throwFatalTranslatedError({
        error: profileError,
        whileVar: 'retrieving',
        whatVar: 'profile',
        devTitle: 'call to profile-s2d-api POST /profile/{profileId} failed',
        devMessage: profileError?.data?.error
      });
    }
    return profileResponse as ProfileData;
  }

  async function getProfileCardsByIds(profileIds: string[]): Promise<ProfileCard[] | null> {
    const { data, error } = await profileFetchClient('/profile/profiles', {
      method: 'POST',
      body: {
        profile_ids: profileIds
      }
    });

    if (!data || data.no_profiles_found || error) {
      logTranslatedError({
        error: error,
        whileVar: 'retrieving',
        whatVar: 'profiles',
        devTitle: 'getProfileCardsByIds, call to profile-s2d-api POST /profile/profiles failed',
        devMessage: error?.data?.error
      });
      return null;
    }

    return data.profiles;
  }

  const agefilters = ['minAge', 'maxAge'];

  function buildFilterQuery(): {
    filterFields: QueryBuilderField[];
    subQueries: QueryBuilderQuery[];
    state: string | undefined;
  } {
    const useFilters = filterStore.activeFilterKeys ?? [];
    const appliedFilters = filterStore.appliedFilters ?? [];
    const filterFields: QueryBuilderField[] = [];
    const subQueries: QueryBuilderQuery[] = [];
    let state: string | undefined;

    for (const [key, value] of Object.entries(appliedFilters)) {
      if (!useFilters?.includes(key)) {
        continue;
      }

      if (value === 'all' || value === 'All') {
        continue;
      }

      if (key === 'location') {
        state = value;
        continue;
      }

      if (value === 'grey' || value === 'gray') {
        const grayField = buildFilter(key, 'gray' as QueryBuilderField['value']);
        const greyField = buildFilter(key, 'grey' as QueryBuilderField['value']);
        subQueries.push({
          filter: {
            fields: [grayField, greyField],
            operation: 'OR'
          },
          operation: 'AND',
          sub_queries: []
        });
        continue;
      }

      const field: QueryBuilderField = agefilters.includes(key)
        ? buildAgeFilter(key, value as QueryBuilderField['value'])
        : buildFilter(key, value as QueryBuilderField['value']);
      filterFields.push(field);
    }
    locationState.value = state;
    return {
      filterFields,
      subQueries,
      state
    };
  }

  function buildFilter(
    key: QueryBuilderField['name'],
    value: QueryBuilderField['value']
  ): QueryBuilderField {
    let op: QueryBuilderField['op'] = 'eq';
    if (key?.startsWith('min')) {
      op = 'gt';
    } else if (key?.startsWith('max')) {
      op = 'lt';
    }

    return {
      name: key,
      op: op,
      value: value
    };
  }

  function buildAgeFilter(
    key: QueryBuilderField['name'],
    value: QueryBuilderField['value']
  ): QueryBuilderField {
    const op: QueryBuilderField['op'] = key === 'minAge' ? 'lt' : 'gt';
    const offset = key === 'minAge' ? 0 : 1;
    const today = dayjs(new Date());
    const DoBUnix = today.subtract(Number(value) + offset, 'year').unix() - 1;

    return {
      name: 'date_of_birth',
      op: op,
      value: String(DoBUnix)
    };
  }

  function _buildLengthFilter(key: QueryBuilderField['name'], value: QueryBuilderField['value']) {
    const op: QueryBuilderField['op'] = key === 'minLength' ? 'lt' : 'gt';
    const field: QueryBuilderField = {
      name: 'length',
      op: op,
      value: value
    };
    return field;
  }

  async function getProfiles(page: number, ITEMS_PER_PAGE: number) {
    const { filterFields, subQueries, state } = buildFilterQuery();
    const offset = page === -1 ? page : page * ITEMS_PER_PAGE;
    const { data, error: profilesError } = await profileFetchClient('/profile/filter', {
      method: 'POST',
      body: {
        limit: ITEMS_PER_PAGE,
        offset: offset,
        should_order_randomly: true,
        query: {
          filter: {
            fields: filterFields,
            operation: 'AND'
          },
          operation: 'AND',
          sub_queries: subQueries
        },
        search: '',
        sort: [],
        referer: '',
        relational_filter: filterStore.relationalFilter,
        state
      }
    });

    if (profilesError) {
      throwFatalTranslatedError({
        error: profilesError,
        whileVar: 'retrieving',
        whatVar: 'profiles',
        devTitle: 'call to profile-s2d-api POST /profile/filter failed',
        devMessage: profilesError?.data?.error
      });
    }

    return {
      profiles:
        data?.no_profiles_found && filterStore.relationalFilter != 'All'
          ? []
          : (data?.profiles ?? ([] as ProfileCard[])),
      noProfiles: data?.no_profiles_found ?? false,
      totalCount: data?.total_count ?? 0,
      usedPage: Math.ceil((data?.used_offset ?? 0) / ITEMS_PER_PAGE)
    };
  }

  async function getSplashProfiles() {
    const { data, error: profilesError } = await profileFetchClient('/profile/sfw/filter', {
      method: 'POST',
      body: {
        locale: locale
      }
    });

    if (profilesError || !data) {
      return {
        profiles: [],
        noProfiles: true
      };
    }

    return {
      profiles: data?.profiles ?? ([] as ProfileCard[]),
      noProfiles: data.no_profiles_found
    };
  }

  function getTooltipText(lang: string = 'ENG') {
    return lang ? 'Physical contact not possible' : 'Fysiek contact niet mogelijk';
  }

  async function getProfileImages(ids: string[], size: number) {
    try {
      const { data } = await profileFetchClient('/profile/profile-images', {
        method: 'POST',
        body: {
          profile_ids: ids,
          size: size
        }
      });
      return data;
    } catch (e) {
      console.error(`unable to load profile image: ${e}`);
    }

    return [];
  }

  function extractCardFromProfile(profileData: ProfileData): ProfileCard {
    const profileCard: ProfileCard = {
      name: '',
      date_of_birth: '',
      city: profileData.city,
      state: profileData.state,
      image: profileData.avatar,
      profile_id: profileData.profile_id
    };

    if (profileData.attributes) {
      for (const attribute of profileData.attributes) {
        switch (attribute.name) {
          case 'profile_name':
            profileCard.name = attribute.value ?? '';
            break;
          case 'date_of_birth':
            profileCard.date_of_birth = attribute.value ?? '';
            break;
        }
      }
    }

    return profileCard;
  }
  async function getMatchGameProfiles(page: number, queueSize: number) {
    const { filterFields, subQueries } = buildFilterQuery();
    const offset = page === -1 ? page : page * queueSize;
    const { data, error: profilesError } = await profileFetchClient('/profile/filter', {
      method: 'POST',
      body: {
        limit: queueSize,
        offset: offset,
        should_order_randomly: true,
        query: {
          filter: {
            fields: filterFields,
            operation: 'AND'
          },
          operation: 'AND',
          sub_queries: subQueries
        },
        search: '',
        sort: [],
        referer: '',
        relational_filter: 'MatchGame'
      }
    });

    if (profilesError) {
      throwFatalTranslatedError({
        error: profilesError,
        whileVar: 'retrieving',
        whatVar: 'profiles',
        devTitle: 'call to profile-s2d-api POST /match failed',
        devMessage: profilesError?.data?.error
      });
    }
    return {
      profiles: data?.profiles ?? ([] as ProfileCard[]),
      noProfiles: data?.no_profiles_found ?? false,
      totalCount: data?.total_count ?? 0,
      usedPage: data ? Math.ceil(data.used_offset / queueSize) : 0
    };
  }

  async function likeFunction(profileID: string, like: boolean) {
    if (!profileID) return;
    const { data, error: profilesError } = await profileFetchClient(
      like ? '/match/like/{profileId}' : '/match/dislike/{profileId}',
      {
        method: 'POST',
        path: { profileId: profileID }
      }
    );

    if (profilesError) {
      const path = like ? '/match/like/{profileId}' : '/match/dislike/{profileId}';
      throwFatalTranslatedError({
        error: profilesError,
        whileVar: like ? 'liking' : 'disliking',
        whatVar: 'profiles',
        devTitle: 'call to profile-s2d-api POST ' + path + ' failed',
        devMessage: profilesError?.data?.error
      });
    }

    // TODO: Check
    return data?.matched ?? false;
  }

  async function unlikeFunction(profileID: string) {
    if (!profileID) return;
    const { error: profilesError } = await profileFetchClient('/match/unlike/{profileId}', {
      method: 'POST',
      path: { profileId: profileID }
    });

    if (profilesError) {
      throwFatalTranslatedError({
        error: profilesError,
        whileVar: 'unliking',
        whatVar: 'profiles',
        devTitle: 'call to profile-s2d-api POST /match/unlike/{profileId} failed',
        devMessage: profilesError?.data?.error
      });
    }
  }

  async function deleteMe() {
    const { error: profilesError } = await profileFetchClient('/profile-user/me', {
      method: 'DELETE'
    });
    if (profilesError !== null) {
      console.error(profilesError);
      return false;
    }
    return true;
  }

  return {
    getProfileById,
    getProfiles,
    getSplashProfiles,
    getTooltipText,
    getProfileImages,
    getProfileCardsByIds,
    extractCardFromProfile,
    favoriteFunction,
    getMatchGameProfiles,
    likeFunction,
    unlikeFunction,
    deleteMe,

    locationState
  };
});

