<script setup lang="ts">
import { usePaymentStore } from '../../stores/payment.store';
import { useSiteConfigStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { useNuxtApp } from '#imports';

const { $translate } = useNuxtApp();
const paymentStore = usePaymentStore();
const siteConfigStore = useSiteConfigStore();
const { paymentProducts } = storeToRefs(paymentStore);
const { translations, siteSettings } = storeToRefs(siteConfigStore);
const currency = siteSettings.value?.currency;
await paymentStore.fetchProducts();

const creditProducts = (paymentProducts.value ?? [])
  .filter((x) => x.reward_type === 'credits')
  .map((x) => ({
    id: x.id,
    totalPrice: x.price,
    amount: x.reward_quantity,
    itemPrice: x.pricePerCredit
  }));

creditProducts.sort((a, b) => a.itemPrice - b.itemPrice);
</script>

<template>
  <div v-if="creditProducts.length > 0" class="relative overflow-x-auto">
    <table class="text-text text-left text-sm">
      <thead class="text-xs uppercase">
        <tr>
          <th scope="col" class="border-bg border px-4 py-3">
            {{ $translate(translations?.credits) }}
          </th>
          <th scope="col" class="border-bg border px-4 py-3">
            {{ $translate(translations?.price) }}
          </th>
          <th scope="col" class="border-bg border px-4 py-3">
            {{ $translate(translations?.pricePerMessage) }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="product of creditProducts" :key="product.id">
          <td scope="row" class="border-bg whitespace-nowrap border px-4 py-2 font-medium">
            {{ product.amount }}
          </td>
          <td scope="row" class="border-bg border px-4 py-2">
            {{ currency }} {{ product.totalPrice }}
          </td>
          <td scope="row" class="border-bg border px-4 py-2">
            {{ currency }} {{ product.itemPrice }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

