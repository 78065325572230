import { useRuntimeConfig } from '#app';
import { createLiteFetchClient, createUseFetchClient } from '@btr/fetch-frog';
import type { paths as chatPaths } from '../schemas/chat';
import type { paths as collectConsentPaths } from '../schemas/collectconsent';
import type { paths as creditPaths } from '../schemas/credit';
import type { paths as fulFillmentPaths } from '../schemas/fulfillment';
import type { paths as geoPaths } from '../schemas/geo-wizard';
import type { paths as identityPaths } from '../schemas/identity';
import type { paths as profilesPaths } from '../schemas/profiles';
import { useSiteConfigStore } from '../stores';
import { useAuthStore } from '../stores/auth.store';

function buildUseFetchClient<T>(baseUrl: string) {
  const authStore = useAuthStore();
  const siteConfigStore = useSiteConfigStore();

  return createUseFetchClient<T>(baseUrl, {
    onRequest: async ({ options }) => {
      if (authStore.isAuthorized) {
        const token = await authStore.getUserAccessToken();
        options.headers = {
          Authorization: 'Bearer ' + token,
          ...options.headers
        };
      } else {
        options.headers = {
          'X-Holding-Tenant': siteConfigStore.siteSettings?.holdingTenantId || '',
          ...options.headers
        };
      }
    }
  });
}

function buildLiteFetchClient<T>(baseUrl: string) {
  const authStore = useAuthStore();
  const siteConfigStore = useSiteConfigStore();

  const client = createLiteFetchClient<T>(baseUrl, {
    onRequest: async ({ options }) => {
      if (authStore.isAuthorized) {
        const token = await authStore.getUserAccessToken();

        options.headers = {
          Authorization: 'Bearer ' + token,
          ...options.headers
        };
      } else {
        options.headers = {
          'X-Holding-Tenant': siteConfigStore.siteSettings?.holdingTenantId || '',
          ...options.headers
        };
      }
    }
  });

  return client;
}

export function createUseCCFetchClient() {
  const baseUrl = useRuntimeConfig().public.collectConsentApiBaseUrl;
  return buildUseFetchClient<collectConsentPaths>(baseUrl);
}

export function createCCFetchClient() {
  const baseUrl = useRuntimeConfig().public.collectConsentApiBaseUrl;
  return buildLiteFetchClient<collectConsentPaths>(baseUrl);
}

export function createUseIdentityFetchClient() {
  const baseUrl = useRuntimeConfig().public.identityApiBaseUrl;
  return buildUseFetchClient<identityPaths>(baseUrl);
}

export function createIdentityFetchClient() {
  const baseUrl = useRuntimeConfig().public.identityApiBaseUrl;
  return buildLiteFetchClient<identityPaths>(baseUrl);
}

export function createUseProfilesFetchClient() {
  const baseUrl = useRuntimeConfig().public.profilesApiBaseUrl;
  return buildUseFetchClient<profilesPaths>(baseUrl);
}

export function createProfilesFetchClient() {
  const baseUrl = useRuntimeConfig().public.profilesApiBaseUrl;
  return buildLiteFetchClient<profilesPaths>(baseUrl);
}

export function createLiteFulfillmentFetchClient() {
  const baseUrl = useRuntimeConfig().public.fulfillmentApiBaseUrl;
  return buildLiteFetchClient<fulFillmentPaths>(baseUrl);
}

export function createUseFulfillmentFetchClient() {
  const baseUrl = useRuntimeConfig().public.fulfillmentApiBaseUrl;
  return buildUseFetchClient<fulFillmentPaths>(baseUrl);
}

export function createCreditFetchClient() {
  const baseUrl = useRuntimeConfig().public.creditApiBaseUrl;
  return buildLiteFetchClient<creditPaths>(baseUrl);
}

export function createCreditUseFetchClient() {
  const baseUrl = useRuntimeConfig().public.creditApiBaseUrl;
  return buildUseFetchClient<creditPaths>(baseUrl);
}

export function createChatFetchClient() {
  const baseUrl = useRuntimeConfig().public.chatApiBaseUrl;
  return buildLiteFetchClient<chatPaths>(baseUrl);
}

export function createChatUseFetchClient() {
  const baseUrl = useRuntimeConfig().public.chatApiBaseUrl;
  return buildLiteFetchClient<chatPaths>(baseUrl);
}

export function createGeoFetchClient() {
  const baseUrl = useRuntimeConfig().public.geoApiBaseUrl;
  return buildLiteFetchClient<geoPaths>(baseUrl);
}

