<script setup lang="ts">
import { navigateTo, useCookie } from '#app';
import { onBeforeRouteLeave, useRoute } from '#vue-router';
import { computed, onMounted, ref, onBeforeUnmount } from 'vue';

import { usePaymentStore } from '../../stores/payment.store';
import { Status, statusValues } from '../../types/fulfillment';

//TODO: text should be translated instead of hardcoded
const route = useRoute();
const routeStatus = route.query.status;
const status = ref<Status | undefined>(statusValues.find((item) => item === routeStatus));
const orderId = useCookie('orderId');
const profileId = useCookie('profileId');
const paymentStore = usePaymentStore();
const redirectDelay = 3000;
const intervalDelay = paymentStore.intervalDelay;
const maxTries = paymentStore.maxTries;

if (!orderId.value) {
  await navigateTo('/', { redirectCode: 301 });
}

onMounted(async () => {
  clearInterval(paymentStore.intervalFulfillment);
  clearInterval(paymentStore.intervalCredit);
  if (status.value === Status.PaymentPending) {
    let fetchtries = 0;
    paymentStore.intervalFulfillment = setInterval(async () => {
      status.value = await paymentStore.fetchOrderStatus(Status.PaymentPending, orderId.value);

      if (status.value === Status.PaymentSuccessful) {
        clearInterval(paymentStore.intervalFulfillment);
        paymentSuccessful();
      }
      fetchtries++;
      if (fetchtries >= maxTries && status.value !== Status.PaymentFailed)
        status.value = Status.PendingTimeout;
      if (status.value === Status.PendingTimeout || status.value === Status.PaymentFailed) {
        clearInterval(paymentStore.intervalFulfillment);
        setTimeout(() => {
          navigateTo('/', { redirectCode: 301 });
        }, redirectDelay);
      }
    }, intervalDelay);
  }
  if (status.value === Status.PaymentSuccessful) {
    paymentSuccessful();
  }
});

function paymentSuccessful() {
  let fetchtries = 0;
  paymentStore.intervalCredit = setInterval(async () => {
    status.value = await paymentStore.fetchCreditInformation(
      Status.PaymentSuccessful,
      orderId.value
    );
    if (status.value === Status.AddingSuccessful) {
      clearInterval(paymentStore.intervalCredit);
      const redirectUrl = profileId.value ? `/message/${profileId.value}` : '/chat';
      setTimeout(() => {
        navigateTo(redirectUrl, { redirectCode: 301 });
      }, 5000);
    }
    fetchtries++;
    if (fetchtries >= maxTries) {
      clearInterval(paymentStore.intervalCredit);
      status.value = Status.AddingTimeout;
      setTimeout(() => {
        navigateTo('/', { redirectCode: 301 });
      }, redirectDelay);
    }
  }, intervalDelay);
}

function leaveFunction() {
  clearInterval(paymentStore.intervalCredit);
  clearInterval(paymentStore.intervalFulfillment);
  if (status.value !== Status.AddingSuccessful) paymentStore.workInBackground = true;
}

onBeforeUnmount(() => {
  leaveFunction();
});

onBeforeRouteLeave(() => {
  orderId.value = undefined;
  profileId.value = undefined;
  leaveFunction();
});

const information = computed<{
  title: string;
  description: string;
  redirect: boolean;
}>(() => {
  switch (status.value) {
    case Status.AddingSuccessful:
      return {
        title: 'Thank you for your purchase!',
        description: 'Your credits have been added to your account',
        redirect: true
      };
    case Status.PaymentFailed:
      return {
        title: 'Purchase failed',
        description: 'Something went wrong with your purchase.',
        redirect: true
      };
    case Status.PaymentPending:
      return {
        title: 'Purchase pending',
        description: 'Checking payment status...',
        redirect: false
      };
    case Status.PendingTimeout:
      return {
        title: 'Purchase is taking longer than expected',
        description: 'We keep working on it in the background',
        redirect: true
      };
    case Status.PaymentSuccessful:
      return {
        title: 'Adding credits',
        description: 'Credits are being added to your account...',
        redirect: false
      };
    case Status.AddingTimeout:
      return {
        title: 'Adding credits is taking longer than expected',
        description: 'We keep working on it in the background',
        redirect: true
      };
    default:
      return {
        title: 'Status unknown',
        description: 'Something went wrong; status unknown',
        redirect: true
      };
  }
});
</script>

<template>
  <div v-if="status" class="mt-20 text-center leading-5">
    <h1 class="text-header-text mb-4 text-2xl">{{ information.title }}</h1>
    <Icon
      v-if="status === Status.PaymentPending || status === Status.PaymentSuccessful"
      name="svg-spinners:ring-resize"
      class="text-primary mb-4"
      size="4em"
    />
    <p class="text-text">
      {{ information.description }}
    </p>
    <p v-if="status === Status.PaymentFailed" class="text-text">
      Please try again or contact customer service.
    </p>
    <p v-if="information.redirect" class="text-text">You will be redirected back.</p>
  </div>
</template>

