import { useAuthStore, useChatStore } from '@horizon/library/stores';
import { usePaymentStore } from '@horizon/library/stores/payment.store';

let ran = false;

export default defineNuxtRouteMiddleware(async () => {  
  if (ran) return;
  ran = true;

  const authStore = useAuthStore();
  const chatStore = useChatStore();
  const paymentStore = usePaymentStore();

  const promises: Promise<unknown>[] = [paymentStore.fetchProducts()];

  if (authStore.isAuthorized) {
    promises.push(fetchUserConsent(), chatStore.getCredits(true));
  }

  await Promise.allSettled(promises);
});

