import { getNowInUnix } from './time';

const MAX_STORE_TIME = 5; //in sec

type StorageKey = Record<string, string>;
type StorageKeys = Record<string, StorageKey>;

export const storageKeys: StorageKeys = {
  matchGame: {
    tutorial: 'MGTutorial',
    seenProfiles: 'MGSeen'
  },
  profileGrid: {
    prevPage: 'PrevProfileGrid',
    scrollState: '_SCROLL_STATE',
    filterState: '_FILTER_STATE'
  },
  storage: {
    leaveTime: 'LeaveTime'
  },
  notifications: {
    notified: 'Notified'
  },
  register: {
    Result: 'RegisterResult'
  },
  notFound: {
    IsProfile: 'IsProfile'
  }
};

export function setLeaveTime() {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  sessionStorage.setItem(
    storageKeys.storage.leaveTime,
    JSON.stringify({ timestamp: getNowInUnix() })
  );
}

export function initStorage() {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  const now = getNowInUnix();
  const storedTime = sessionStorage.getItem(storageKeys.storage.leaveTime);
  if (storedTime === null) return;
  const leftTime = JSON.parse(storedTime);
  if (now - leftTime > MAX_STORE_TIME) {
    clearStorage();
  }
}

export function clearStorage() {
  if (typeof sessionStorage === 'undefined') {
    return;
  }
  sessionStorage.clear();
}

export function getStoredValue(key: string) {
  if (typeof sessionStorage === 'undefined') return undefined;
  const storedItem = sessionStorage.getItem(key);
  if (storedItem === null) return null;

  try {
    return JSON.parse(storedItem);
  } catch (error) {
    if (error instanceof SyntaxError) return storedItem;
    console.error('stored value could not be parsed');
  }
}

export function storeValue<T>(key: string, item: T) {
  if (typeof sessionStorage === 'undefined') return false;
  try {
    sessionStorage.setItem(key, JSON.stringify(item));
  } catch {
    console.error('could not store value');
    return false;
  }
  return true;
}

