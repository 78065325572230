<script setup lang="ts">
import { useElementSize, watchDebounced } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed, inject, ref, onMounted, type Component } from 'vue';
import { useGlobalsStore, useProfileStore, useSiteConfigStore } from '../../stores';
import type { ProfileCard, HorizonComponentProps } from '../../types';
import { arrayShuffle, overrideStyles, getTestId } from '../../utils';
import BlurField from '../Generic/BlurField.vue';
import VirtualGrid from '../ProfileGrid/VirtualGrid.vue';
const props = defineProps<HorizonComponentProps>();

const components = inject<Record<string, Component>>('components');

const profileStore = useProfileStore();

const siteConfigStore = useSiteConfigStore();
const { profileCardComponent, profileFiltersComponent } = storeToRefs(siteConfigStore);

const globals = useGlobalsStore();
const landingGridContainer = ref(null);
const MAX_COL_COUNT = globals.constantGlobals.profileGrid.MAX_COL_COUNT;
const COL_COUNT = ref(MAX_COL_COUNT);
const { width } = useElementSize(landingGridContainer);
const ITEM_WIDTH = ref<number>(globals.constantGlobals.profileGrid.ITEM_WIDTH);

const ITEM_HEIGHT = computed(() => (ITEM_WIDTH.value / 5) * 6);

const maxRows = 4;
const profiles = ref<ProfileCard[]>([]);
const profileCards = ref<ProfileCard[]>([]);
const firstFetchEmpty = ref<boolean>(false);
const firstFetch = ref<boolean>(true);
const specialBlurOffsetCardwidthRange = {
  min: 169,
  max: 200
};
const maxCards = computed(() => COL_COUNT.value * maxRows);
const isLoading = ref<boolean>(false);

async function fetchItems() {
  if (profiles.value.length >= maxCards.value) {
    isLoading.value = false;
    return;
  }
  if (isLoading.value) return;
  isLoading.value = true;

  const data = await profileStore.getSplashProfiles();
  if (data.profiles.length > 0) isLoading.value = false;
  profiles.value = arrayShuffle(data.profiles);
  if (data.noProfiles) {
    if (firstFetch.value) {
      firstFetchEmpty.value = true;
      firstFetch.value = false;
    }
  }

  profileCards.value = profiles.value.slice(0, maxCards.value);
  isLoading.value = false;
}

watchDebounced(width, resizeGrid, { debounce: 10 });

function resizeGrid() {
  const cols = Math.floor(width.value / globals.constantGlobals.profileGrid.MIN_ITEM_WIDTH);
  COL_COUNT.value = Math.min(cols, MAX_COL_COUNT);
  ITEM_WIDTH.value = Math.min(
    width.value / COL_COUNT.value,
    globals.constantGlobals.profileGrid.MAX_ITEM_WIDTH
  );
  const maxCards = COL_COUNT.value * maxRows;
  profileCards.value = profiles.value.slice(0, maxCards);
}

onMounted(() => {
  fetchItems();
});
</script>

<template>
  <div class="relative translate-y-9">
    <div v-if="components && profileCardComponent" class="min-h-modal-2xl">
      <div ref="landingGridContainer" class="container">
        <component
          :is="components[profileFiltersComponent.name]"
          v-if="profileFiltersComponent"
          :style="overrideStyles(profileFiltersComponent?.overrides)"
          :data="{
            columnCount: COL_COUNT,
            itemWidth: ITEM_WIDTH,
            homePage: true
          }"
          class="mb-4"
          :data-testid="props.testId + '/' + getTestId(profileFiltersComponent)"
          :test-id="props.testId + '/' + getTestId(profileFiltersComponent)"
        />

        <VirtualGrid
          v-show="isLoading"
          :item-width="ITEM_WIDTH"
          :item-height="ITEM_HEIGHT"
          :col-count="COL_COUNT"
        />

        <ClientOnly>
          <VirtualGrid
            v-show="!isLoading"
            :item-width="ITEM_WIDTH"
            :item-height="ITEM_HEIGHT"
            :col-count="COL_COUNT"
            :items="profileCards"
            :is-loading="false"
          />
        </ClientOnly>
      </div>
    </div>

    <BlurField
      :data="{ isLoading: isLoading }"
      class="absolute md:top-[28%]"
      :class="{
        'top-[27%]':
          specialBlurOffsetCardwidthRange.min <= ITEM_WIDTH &&
          ITEM_WIDTH <= specialBlurOffsetCardwidthRange.max,
        'top-[25.5%]':
          specialBlurOffsetCardwidthRange.min > ITEM_WIDTH ||
          ITEM_WIDTH > specialBlurOffsetCardwidthRange.max
      }"
      :style="`height: ${3 * ITEM_HEIGHT}px;`"
      :test-id="props.testId + '/BlurField'"
    ></BlurField>
  </div>
</template>

