<script setup lang="ts">
import { usePaymentStore } from '@horizon/library/stores/payment.store';
import { useNotificationStore } from '@horizon/library/stores/notification.store';
import { watch, computed, onBeforeUnmount } from 'vue';
import { Status, statusValues } from '@horizon/library/types/fulfillment';
import type { Notification } from '@horizon/library/types';

const route = useRoute();
const routeStatus = route.query.status;
const status = ref(statusValues.find((item) => item === routeStatus));
const paymentStore = usePaymentStore();
const notificationStore = useNotificationStore();
const workInBackground = computed(() => paymentStore.workInBackground);
const intervalDelay = 5000; // in ms
const maxTries = 60; // about 5 min for interval delay = 5sec
const orderId = useCookie('orderId');

let intervalFulfillment: NodeJS.Timeout | undefined;
let intervalCredit: NodeJS.Timeout | undefined;

watch(
  () => workInBackground.value,
  () => {
    if (workInBackground.value) {
      backgroundPolling();
    }
  }
);

function backgroundPolling() {
  if (status.value === Status.PaymentPending) {
    if (intervalFulfillment) return;
    let fetchtries = 0;
    intervalFulfillment = setInterval(async () => {
      status.value = await paymentStore.fetchOrderStatus(Status.PaymentPending, orderId.value);
      if (status.value === Status.PaymentSuccessful) {
        clearInterval(intervalFulfillment);
        intervalFulfillment = undefined;
        paymentSuccessful();
      }
      fetchtries++;
      if (fetchtries >= maxTries && status.value !== Status.PaymentFailed)
        status.value = Status.PendingTimeout;
      if (status.value === Status.PendingTimeout || status.value === Status.PaymentFailed) {
        clearInterval(intervalFulfillment);
        intervalFulfillment = undefined;
        const message =
          status.value === Status.PendingTimeout
            ? 'The payment timed out'
            : 'Something went wrong during your purchase';
        const notification: Notification = {
          text: message,
          duration: 5000,
          success: false
        };
        notificationStore.addCreditsNotification(notification, message);
      }
    }, intervalDelay);
  }
  if (status.value === Status.PaymentSuccessful) {
    paymentSuccessful();
  } else {
    paymentStore.workInBackground = false;
  }
}

function paymentSuccessful() {
  if (intervalCredit) return;
  let fetchtries = 0;
  intervalCredit = setInterval(async () => {
    status.value = await paymentStore.fetchCreditInformation(
      Status.PaymentSuccessful,
      orderId.value
    );
    fetchtries++;
    if (fetchtries >= maxTries) {
      clearInterval(intervalCredit);
      intervalCredit = undefined;
      status.value = Status.AddingTimeout;
    }
    if (status.value === Status.AddingTimeout || status.value === Status.AddingSuccessful) {
      clearInterval(intervalCredit);
      const message =
        status.value === Status.AddingTimeout
          ? 'Credits could not be added to account'
          : 'Credits have been added to your account';
      const notification: Notification = {
        text: message,
        duration: 5000,
        success: status.value === Status.AddingSuccessful
      };
      notificationStore.addCreditsNotification(notification, message);
    }
  }, intervalDelay);
  paymentStore.workInBackground = false;
}

onBeforeUnmount(() => {
  clearInterval(intervalCredit);
  clearInterval(intervalFulfillment);
});
</script>

<template></template>

