<script setup lang="ts">
import { storeToRefs } from '#imports';
import { useChatStore } from '../../../stores';
import type { HorizonComponentProps } from '../../../types';
import ChatListItem from './ChatListItem.vue';

const props = defineProps<HorizonComponentProps>();

const chatStore = useChatStore();
const { currentChatUser, currentTabProfiles } = storeToRefs(chatStore);
</script>

<template>
  <ul
    class="hidden-scrollbar flex h-0 min-h-full flex-col"
    :data-testid="props.testId + '/ChatList'"
  >
    <ChatListItem
      v-for="(user, index) in currentTabProfiles"
      :key="index"
      :data="{ user }"
      :test-id="props.testId + '/ChatList'"
      @select="currentChatUser = user"
    ></ChatListItem>
  </ul>
</template>

