<script setup lang="ts">
import { ref, watch } from 'vue';
import { useAuthStore, useNotificationStore, useProfileStore } from '../../stores';
import {
  ResetPasswordSchema,
  type ResetPassword,
  type HorizonComponentProps,
  type Notification
} from '../../types';
import type { ZodFormattedError } from 'zod';
import { useDebounceFn } from '@vueuse/core';

const props = defineProps<HorizonComponentProps>();
const authStore = useAuthStore();
const formInfo = ref<ResetPassword>({
  password: '',
  confirmPassword: ''
});
const formErrors = ref<ZodFormattedError<ResetPassword> | undefined>();
const fetchStatus = ref<'idle' | 'sending' | 'success' | 'error'>('idle');
const deleteStatus = ref<'idle' | 'success' | 'error'>('idle');
const pressedDeleteMe = ref<boolean>(false);

const notificationStore = useNotificationStore();
const profileStore = useProfileStore();

type Setting = 'all' | 'mail' | 'none'; // this is a P.O.C, it kan be better
const setting = ref<Setting>(notificationStore.getSetting());

watch(setting, () => {
  notificationStore.applySetting(setting.value);
});

async function resetPassword() {
  formErrors.value = undefined;
  const parsedForm = ResetPasswordSchema.safeParse(formInfo.value);
  if (!parsedForm.success) {
    formErrors.value = parsedForm.error.format();
    fetchStatus.value = 'idle';
    return;
  }

  const { data: data, error: error } = await authStore.changePassword(parsedForm.data.password);

  if (data && !error) {
    await authStore.loginWithEmailPassword(
      authStore?.authUser?.email ?? '',
      parsedForm.data.password,
      true
    );
  } else {
    console.error('data error');
  }

  fetchStatus.value = !error ? 'success' : 'error';
}

const deleteMe = useDebounceFn(async () => {
  const success = await profileStore.deleteMe();
  if (success) {
    deleteStatus.value = 'success';
    setTimeout(() => {
      authStore.logout();
    }, 5000);
    return;
  }
  deleteStatus.value = 'error';
}, 250);

function testNotification(number: number) {
  if (number === 1) {
    const message = 'Credits have been added to your account';
    const notification: Notification = {
      text: message,
      duration: 50000,
      success: true
    };
    notificationStore.addCreditsNotification(notification, message);
  }
  if (number === 2) {
    const message = 'Unable to add credits to your account';
    const notification: Notification = {
      text: message,
      duration: 50000,
      success: false
    };
    notificationStore.addCreditsNotification(notification, message);
  }
  if (number === 3) {
    const message = 'The payment timed out';
    const notification: Notification = {
      text: message,
      duration: 50000,
      success: false
    };
    notificationStore.addCreditsNotification(notification, message);
  }
}
</script>

<template>
  <div class="mb:mb-12 container mx-auto w-3/4 max-w-4xl gap-6 px-4 md:mt-2">
    <div class="mx-4 flex flex-col rounded-xl md:flex-row md:p-8">
      <div class="flex w-full flex-col gap-6">
        <div class="bg-background text-header-text p-4">
          <div class="flex flex-row">
            <button class="btn mb-4" @click="testNotification(1)">success</button>
            <button class="btn mb-4" @click="testNotification(2)">adding</button>
            <button class="btn mb-4" @click="testNotification(3)">pending</button>
          </div>
          <div class="flex flex-col justify-between">
            <h2 class="mb-2 text-xl font-bold text-inherit">Password</h2>
            <p class="text-footer-text mb-2 text-sm leading-loose">Change your password</p>
          </div>

          <div
            v-if="fetchStatus === 'success'"
            class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm text-white"
          >
            Password has been changed!
          </div>
          <div
            v-if="fetchStatus === 'error'"
            class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
          >
            Something went wrong, please try again later..
          </div>

          <div
            v-if="fetchStatus === 'idle' && formErrors?.password?._errors[0]"
            class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
          >
            {{ formErrors.password._errors[0] }}
          </div>
          <div
            v-else-if="fetchStatus === 'idle' && formErrors?.confirmPassword?._errors[0]"
            class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
          >
            {{ formErrors.confirmPassword._errors[0] }}
          </div>

          <form @submit.prevent="resetPassword()">
            <div class="mb-4 flex flex-col gap-3">
              <input
                id="password"
                v-model="formInfo.password"
                type="password"
                name="password"
                placeholder="Enter new password"
                class="control bg-modal-bg text-modal-text rounded border-2 border-black px-3 py-1"
                :class="{ '!border-danger': !!formErrors?.password }"
                :data-testid="props.testId + '/NewPasswordInput'"
              />
              <input
                id="confirmpassword"
                v-model="formInfo.confirmPassword"
                type="password"
                name="confirmpassword"
                placeholder="Repeat new password"
                class="control bg-modal-bg text-modal-text rounded border-2 border-black px-3 py-1"
                :class="{ '!border-danger': !!formErrors?.confirmPassword }"
                :data-testid="props.testId + '/ConfirmPasswordInput'"
              />
              <FormErrorRenderer :zod-errors="formErrors" property="confirmPassword" />
            </div>

            <ButtonSimple
              class="btn shadow-glow bg-btn-primary-bg text-btn-primary-text border-btn-primary-border hover:bg-btn-primary-hover-bg hover:border-btn-primary-hover-border block w-full py-1"
              :data="{
                label: 'Save changes',
                isSaving: fetchStatus === 'sending'
              }"
              type="submit"
              :test-id="props.testId"
            />
          </form>
        </div>

        <!-- <div class="bg-background p-4 text-black"> this is commented on purpose for when email change will be implemented
                    <h2 class="mb-4 text-xl font-bold text-inherit">E-mail</h2>

                    <div class="mb-4 flex flex-col gap-3">
                        <input
                            class="rounded border-2 border-black px-3 py-1"
                            type="email"
                            name=""
                            placeholder="Current email:"
                            value="user@mail.com"
                            disabled
                        />
                        <input
                            class="rounded border-2 border-black px-3 py-1"
                            type="email"
                            name=""
                            placeholder="New email:"
                            :data-testid="props.testId + '/NewEmailInput'"
                        />
                        <input
                            class="rounded border-2 border-black px-3 py-1"
                            type="email"
                            name=""
                            placeholder="Confirm new email:"
                            :data-testid="props.testId + '/ConfirmEmail'"
                        />
                    </div>

                    <button
                        class="btn shadow-glow bg-btn-primary-bg text-btn-primary-text border-btn-primary-border hover:bg-btn-primary-hover-bg hover:border-btn-primary-hover-border block w-full py-1"
                        :data-testid="props.testId + '/EmailSaveButton'"
                    >
                        Opslaan
                    </button>
                </div> -->
        <div
          v-if="deleteStatus === 'success'"
          class="bg-success mb-3 w-full rounded-full py-4 text-center text-sm text-white"
        >
          Account deleted, you will be logged out.
        </div>
        <div
          v-if="deleteStatus === 'error'"
          class="bg-danger mb-3 w-full rounded-full py-4 text-center text-sm text-white"
        >
          Something went wrong, please try again later..
        </div>
        <div class="bg-background col-span-2 hidden p-4 text-black md:block">
          <h2 class="mb-4 text-xl text-red-600">Delete account</h2>
          <p class="text-header-text mb-4">
            By clicking the button below, I agree that my complete message history and account will
            be erased.
          </p>

          <button
            v-if="!pressedDeleteMe"
            class="btn text-primary bg-tertiary hover:border-primary border-tertiary shadow-glow hover:shadow-primary hover:bg-background mt-4 block w-full border py-1"
            :data-testid="props.testId + '/DeleteAccountButton'"
            @click="pressedDeleteMe = true"
          >
            Delete my account
          </button>
          <div
            v-if="pressedDeleteMe"
            class="bg-danger mb-3 flex w-full flex-col rounded-full py-4 text-center text-sm text-white"
          >
            <strong class="text-center text-sm text-white">Are you sure? </strong>
          </div>
          <button
            v-if="pressedDeleteMe"
            class="btn text-primary bg-tertiary hover:border-primary border-tertiary shadow-glow hover:shadow-primary hover:bg-background mx-auto mt-2 block w-1/2 border py-1"
            :data-testid="props.testId + '/ConfirmDeleteButton'"
            @click="deleteMe"
          >
            Yes, delete my account
          </button>
        </div>
      </div>

      <!-- <div class="flex w-full flex-col gap-6">
        <div class="bg-background col-span-2 p-4 text-black">
          <div class="mb-4">
            <h2 class="mb-4 text-xl font-bold text-inherit">
              Laat ons weten hoeveel e-mails u wilt ontvangen
            </h2>

            <div class="mb-4 grid grid-cols-4 justify-center gap-3">
              <label>
                                <input
                                    class="peer hidden"
                                    type="radio"
                                    name="notifications"
                                    value="all"
                                    checked
                                />
                                <div
                                    class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                                >
                                    <Icon name="ic:baseline-mail" />
                                    <span>Alles</span>
                                </div>
                            </label>
                            <label>
                                <input
                                    class="peer hidden"
                                    type="radio"
                                    name="notifications"
                                    value="min"
                                />
                                <div
                                    class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                                >
                                    <Icon name="ic:baseline-mail" />
                                    <span>Minder</span>
                                </div>
                            </label>
                            <label>
                                <input
                                    class="peer hidden"
                                    type="radio"
                                    name="notifications"
                                    value="few"
                                />
                                <div
                                    class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                                >
                                    <Icon name="ic:baseline-mail" />
                                    <span>Weinig</span>
                                </div>
                            </label>

                            <label>
                                <input
                                    class="peer hidden"
                                    type="radio"
                                    name="notifications"
                                    value="custom"
                                />
                                <div
                                    class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                                >
                                    <Icon name="ic:baseline-mail-outline" />
                                    <span>Custom</span>
                                </div>
                            </label>

              <label>
                <input
                  class="peer hidden"
                  type="radio"
                  name="notifications"
                  value="all"
                  v-model="setting"
                />
                <div
                  class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                >
                  <Icon name="ic:baseline-mail" />
                  <span>Alles</span>
                </div>
              </label>
              <label>
                <input
                  class="peer hidden"
                  type="radio"
                  name="notifications"
                  value="mail"
                  v-model="setting"
                />
                <div
                  class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                >
                  <Icon name="ic:baseline-mail" />
                  <span>Alleen mail</span>
                </div>
              </label>
              <label>
                <input
                  class="peer hidden"
                  type="radio"
                  name="notifications"
                  value="none"
                  v-model="setting"
                />
                <div
                  class="border-primary peer-checked:bg-primary text-primary flex flex-col items-center rounded border p-2 peer-checked:text-white"
                >
                  <Icon name="ic:baseline-mail" />
                  <span>Geen</span>
                </div>
              </label>
            </div>

            <div>
              <input
                class="w-full"
                type="range"
                data-settings="mail-preferences"
                min="0"
                value="0"
                max="3"
                step="1"
                list="volsettings"
              />
            </div>
          </div>

          <h3 class="text-xl font-bold text-inherit">Stuur mij notificaties als:</h3>

          <div class="mb-4 grid grid-cols-[1fr,1.5rem,1.5rem] gap-x-4 gap-y-2">
            <div />
            <span class="text-center">Ja</span>
            <span class="text-center">Nee</span>

            <p>Ik een nieuw bericht van een nieuw contact ontvang</p>
            <span class="text-center">
              <input type="radio" name="new_contact" value="yes" checked />
            </span>
            <span class="text-center">
              <input type="radio" name="new_contact" value="no" />
            </span>

            <p>Ik een nieuw bericht ontvang</p>
            <span class="text-center">
              <input type="radio" name="new_message" value="yes" checked />
            </span>
            <span class="text-center">
              <input type="radio" name="new_message" value="no" />
            </span>

            <p>Iemand mij liked</p>
            <span class="text-center">
              <input type="radio" name="new_like" value="yes" checked />
            </span>
            <span class="text-center">
              <input type="radio" name="new_like" value="no" />
            </span>

            <p>Ik een nieuwe match heb</p>
            <span class="text-center">
              <input type="radio" name="new_match" value="yes" checked />
            </span>
            <span class="text-center">
              <input type="radio" name="new_match" value="no" />
            </span>

            <p>Iemand mijn pagina bezoekt</p>
            <span class="text-center">
              <input type="radio" name="page_visit" value="yes" checked />
            </span>
            <span class="text-center">
              <input type="radio" name="page_visit" value="no" />
            </span>

            <p>Stuur mij een e-mail met promoties</p>
            <span class="text-center">
              <input type="radio" name="promotions" value="yes" checked />
            </span>
            <span class="text-center">
              <input type="radio" name="promotions" value="no" />
            </span>
          </div>

          <h3 class="text-xl font-bold">Pauseer alles</h3>

          <div class="mb-4">
            <input id="email_notifications" type="checkbox" name="" />
            <label for="email_notifications" class="ml-2"> Ik wil geen e-mail ontvangen </label>
          </div>

          <button
            class="btn shadow-glow bg-btn-primary-bg text-btn-primary-text border-btn-primary-border hover:bg-btn-primary-hover-bg hover:border-btn-primary-hover-border block w-full py-1"
          >
            Opslaan
          </button>
        </div>
      </div> -->
      <div class="bg-background col-span-2 p-4 md:hidden">
        <h2 class="mb-4 text-xl text-red-600">Delete account</h2>
        <p class="text-header-text mb-8">
          By clicking the button below, I agree that my complete message history and account will be
          erased.
        </p>

        <button
          v-if="!pressedDeleteMe"
          class="btn text-primary bg-tertiary hover:border-primary border-tertiary shadow-glow hover:shadow-primary hover:bg-background mt-4 block w-full border py-1"
          :data-testid="props.testId + '/MobileDeleteAccountButton'"
          @click="pressedDeleteMe = true"
        >
          Delete my account
        </button>
        <div
          v-if="pressedDeleteMe"
          class="bg-danger mb-3 flex w-full flex-col rounded-full py-4 text-center text-sm text-white"
        >
          <strong class="text-center text-sm text-white">Are you sure? </strong>
        </div>
        <button
          v-if="pressedDeleteMe"
          class="btn text-primary bg-tertiary hover:border-primary border-tertiary shadow-glow hover:shadow-primary hover:bg-background mx-auto mt-2 block w-1/2 border py-1"
          :data-testid="props.testId + '/MobileConfirmDeleteButton'"
          @click="deleteMe"
        >
          Yes, delete my account
        </button>
      </div>
      <!-- <div class="bg-background col-span-2 p-4 text-black md:hidden">
        <h2 class="mb-4 text-xl text-red-600">Delete account</h2>
        <p class="mb-8">
          By clicking the button below, I agree that my complete message history and account will be
          erased.
        </p>

        <button class="btn text-primary bg-tertiary block w-full py-1">
          Yes, delete my account
        </button>
      </div> -->
    </div>
  </div>
</template>

<style scoped lang="postcss">
@media screen and (min-width: 768px) {
  .md\:cardShadow {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 0px;
  }
}
input {
  @apply border-header-text/20;
}
</style>

