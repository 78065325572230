import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import type { LabelValuePair, ProfileFilterTags, ProfileRelationalFilter } from '../types';
import { getStoredValue, storageKeys, storeValue, log } from '../utils';
import { getFilterOptions, mapPageNameToRelationalFilter } from '../utils/filters';
import { useSiteConfigStore } from './siteConfig.store';

const SHARED_FILTERS: boolean = false;

type FilterOptions = Record<string, LabelValuePair<string>[]>;
export type FilterKey = keyof FilterOptions;
export type AppliedFilters = Record<FilterKey, string>;

type StorageValue = {
  appliedFilters: AppliedFilters;
  relationalFilter: ProfileRelationalFilter;
  activeFilterKeys: FilterKey[];
};

export const useFiltersStore = defineStore('filters', () => {
  const route = useRoute();
  const domain = useSiteConfigStore().siteSettings?.name;
  const suffix = '_' + domain + '_' + storageKeys.profileGrid.filterState;
  const storageKey = computed(() => (SHARED_FILTERS ? 'ALL' : String(route.name)) + suffix);

  const storedValues = computed<StorageValue>(() => getStoredValue(storageKey.value) ?? null);

  const filtersUpdated = ref<number>(0);

  // TODO: make dynamic once translations work
  const relationalFilterOptions: LabelValuePair<ProfileRelationalFilter>[] = [
    { label: 'Alle profielen', value: 'All' },
    { label: 'Vind mij leuk', value: 'LikedMe' },
    { label: 'Vind ik leuk', value: 'Liked' },
    { label: 'Matches', value: 'Match' },
    // { label: 'Bezoekers', value: 'Visitors' },
    { label: 'Favorieten', value: 'Favorites' }
  ];

  const defaultFilterKeys: FilterKey[] = [
    'gender',
    'appearance',
    'build',
    'hair_color',
    'location',
    'minAge',
    'maxAge'
  ];
  const possibleFilters = ref<FilterOptions>({});
  const appliedFilters = ref<AppliedFilters>(
    storedValues.value != null
      ? storedValues.value.appliedFilters
      : getDefaultStorageValue().appliedFilters
  );

  const activeFilterKeys = ref(
    storedValues.value != null ? storedValues.value.activeFilterKeys : defaultFilterKeys
  );
  const relationalFilter = ref(
    storedValues.value != null ? storedValues.value.relationalFilter : 'All'
  );
  let isInited = false;

  watch(
    () => storageKey.value,
    () => {
      appliedFilters.value =
        storedValues.value != null
          ? storedValues.value.appliedFilters
          : getDefaultStorageValue().appliedFilters;
    }
  );

  async function initFilterStore(filterKeys: FilterKey[] = defaultFilterKeys) {
    if (isInited) return;
    appliedFilters.value =
      storedValues.value != null
        ? storedValues.value.appliedFilters
        : getDefaultStorageValue().appliedFilters;
    const { data, error } = await getFilterOptions();
    if (!data) return;
    if (data && !error) {
      const regularFilters: ProfileFilterTags = data.available_filters.tag_categories;
      for (const filter of regularFilters) {
        if (filterKeys.includes(filter.name)) {
          const options: LabelValuePair<string>[] = [{ label: 'All', value: 'all' }];
          for (const tag of filter.tags) {
            const option: LabelValuePair<string> = { label: tag.name, value: tag.name };
            options.push(option);
          }
          possibleFilters.value[filter.name] = options.sort(function (a, b) {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          });
        }
      }
    }
    const minAge: LabelValuePair<string> = { label: 'minAge', value: '18' };
    const maxAge: LabelValuePair<string> = { label: 'maxAge', value: '99' };
    possibleFilters.value['minAge'] = [minAge];
    possibleFilters.value['maxAge'] = [maxAge];
    isInited = true;
  }
  // this function might seem overkill, but for some reason the computed properties update too late so this function is here to keep everything on track.
  function initFilters() {
    const storageKey = (SHARED_FILTERS ? 'ALL' : String(route.name)) + suffix;
    const storedValues = getStoredValue(storageKey) ?? null;
    const appliedFilters =
      storedValues != null ? storedValues.appliedFilters : getDefaultStorageValue().appliedFilters;
    const filterKeys = storedValues != null ? storedValues.activeFilterKeys : defaultFilterKeys;
    const relationalFilter = mapPageNameToRelationalFilter(String(useRoute().name));
    applyFilters(appliedFilters, filterKeys, relationalFilter);
  }

  function applyFilters(
    filters: Partial<AppliedFilters>,
    active: FilterKey[],
    newRelationalFilter: ProfileRelationalFilter
  ) {
    let filtersChanged = false;

    for (const [filter, value] of Object.entries(filters)) {
      if (value === undefined) continue;
      if (appliedFilters.value[filter as keyof AppliedFilters] != value) {
        filtersChanged = true;
        appliedFilters.value[filter as keyof AppliedFilters] = value;
      }
    }
    if (newRelationalFilter !== relationalFilter.value) {
      filtersChanged = true;
      relationalFilter.value = newRelationalFilter;
    } else if (relationalFilter.value != 'All') {
      filtersChanged = true; // this is to make sure the grid is updated when a profile is added/removed from a relation
    }
    const state: StorageValue = {
      appliedFilters: appliedFilters.value,
      relationalFilter: newRelationalFilter,
      activeFilterKeys: active
    };
    storeValue(storageKey.value, state);

    if (filtersChanged) filtersUpdated.value++;
  }

  function getDefaultStorageValue(): StorageValue {
    return {
      appliedFilters: {
        gender: 'f',
        location: 'all',
        minAge: '18',
        maxAge: '99',
        appearance: 'all',
        build: 'all',
        hair_color: 'all'
      },
      activeFilterKeys: defaultFilterKeys,
      relationalFilter: 'All'
    };
  }

  function resetFilters() {
    if (typeof sessionStorage !== 'undefined') {
      const defaults = getDefaultStorageValue();
      storeValue(storageKey.value, defaults);
      applyFilters(defaults.appliedFilters, defaults.activeFilterKeys, defaults.relationalFilter);
      filtersUpdated.value++;
    } else {
      // eslint-disable-next-line no-console
      console.warn('Web Storage is not supported in this environment.');
    }
  }

  function isDefaultFilter() {
    const defaultValues = getDefaultStorageValue();

    for (const filter of Object.keys(appliedFilters.value)) {
      if (
        appliedFilters.value[filter as keyof AppliedFilters] !==
        defaultValues.appliedFilters[filter as keyof AppliedFilters]
      ) {
        return false;
      }
    }
    return true;
  }

  function forceUpdate() {
    filtersUpdated.value++;
  }

  return {
    appliedFilters,
    activeFilterKeys,
    relationalFilter,
    relationalFilterOptions,
    possibleFilters,
    filtersUpdated,
    SHARED_FILTERS,

    initFilterStore,
    initFilters,
    applyFilters,
    resetFilters,
    isDefaultFilter,
    forceUpdate,
    getDefaultStorageValue
  };
});

