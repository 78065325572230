import { z } from 'zod';

const stringOrNumber = z.union([z.string(), z.number()]);
const ALIGNMENT_OPTIONS = ['start', 'center', 'end'] as const;
const zodAlignmentOptions = z.enum(ALIGNMENT_OPTIONS);

export type EAlignmentOption = (typeof ALIGNMENT_OPTIONS)[number];

/**
 * Colors and values that can allways be overriden by the theme
 */
export const SiteThemeSchema = z.object({
  colors: z
    .object({
      primary: z.string().optional(),
      secondary: z.string().optional(),
      tertiary: z.string().optional(),
      danger: z.string().optional(),
      success: z.string().optional(),
      background: z.string().optional(),
      headerBackground: z.string().optional(),
      headerText: z.string().optional(),
      footerBackground: z.string().optional(),
      footerText: z.string().optional(),
      footerLink: z.string().optional(),
      footerLinkHover: z.string().optional(),
      text: z.string().optional(),
      link: z.string().optional(),
      linkHover: z.string().optional(),
      strong: z.string().optional(),
      border: z.string().optional(),

      buttonPrimaryBg: z.string().optional(),
      buttonPrimaryText: z.string().optional(),
      buttonPrimaryBorder: z.string().optional(),
      buttonPrimaryHoverBg: z.string().optional(),
      buttonPrimaryHoverText: z.string().optional(),
      buttonPrimaryHoverBorder: z.string().optional(),

      buttonSecondaryBg: z.string().optional(),
      buttonSecondaryText: z.string().optional(),
      buttonSecondaryBorder: z.string().optional(),
      buttonSecondaryHoverBg: z.string().optional(),
      buttonSecondaryHoverText: z.string().optional(),
      buttonSecondaryHoverBorder: z.string().optional(),

      modalBackdrop: z.string().optional(),
      modalBackground: z.string().optional(),
      modaltext: z.string().optional(),
      shimmerBackground: z.string().optional(),
      shimmerHighlight: z.string().optional()
    })
    .optional(),
  buttonRadius: stringOrNumber.optional(),
  roundness: stringOrNumber.optional(),
  margin: z
    .object({
      x: z.string().optional(),
      y: z.string().optional()
    })
    .optional(),
  padding: z
    .object({
      x: z.string().optional(),
      y: z.string().optional()
    })
    .optional(),
  alignment: zodAlignmentOptions.optional(),
  flex: stringOrNumber.optional(),
  height: z.string().optional(),
  // TODO: move image to component
  image: z
    .object({
      fit: z.string().optional()
    })
    .optional()
});

