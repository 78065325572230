import type { MessageDocument } from '@horizon/chat-protocol-ts';
import { defineStore } from 'pinia';

interface State {
  mailData: MessageDocument[];
  mailIDs: string[];
}

const defaultFirstMail: MessageDocument = {
  attachment: '',
  content: 'We zijn erg blij dat je voor ons hebt gekozen!',
  createdAt: '2024-06-10T16:51:51+02:00',
  id: '',
  isRead: true,
  pokeMessageType: 'greeting',
  isFree: true,
  messageLength: 46,
  senderId: '',
  senderType: 'agent',
  type: 'normal',
  domain: '',
  imported: false,
  locale: 'nl_NL',
  typeId: '',
  tenant: '',
  senderName: 'agent',
  operatorResponseTime: 21,
  creditsSpent: 1,
  metadata: {},
  senderUuid: '',
  recipientUuid: ''
};

export const useMailStore = defineStore('mail', {
  state: (): State => {
    return {
      mailData: [defaultFirstMail],
      mailIDs: defaultFirstMail.id ? [defaultFirstMail.id] : []
    };
  },
  actions: {
    clearMail() {
      this.mailData = [defaultFirstMail];
      this.mailIDs = defaultFirstMail.id ? [defaultFirstMail.id] : [];
    },
    addMail(mailtoAdd: MessageDocument) {
      const mail = this.mailData.find((m) => m.id === mailtoAdd.id);
      if (mailtoAdd && !mail) {
        this.mailData.push(mailtoAdd);
      }
    },
    readMail(mailID: string) {
      const mail = this.mailData.find((m) => m.id === mailID);
      if (mail) {
        mail.isRead = true;
        return true;
      }
      return false;
    },
    getIsRead(mailid: string) {
      const isread = this.mailData.find((mail) => mail.id === mailid)?.isRead;
      return isread;
    },
    getTitle(name: string, type: string) {
      switch (type) {
        case 'like':
          return name + ' vind je leuk!';
        case 'flirt':
          return name + ' will met je flirten!';
        case 'proposal':
          return name + ' wil met je afspreken!';
        case 'poke':
          return name + ' heeft je gepoked!';
        case 'favorite':
          return name + ' heeft je ge-favorite!';
        case 'greeting':
          return 'Wat fijn dat je er bent!';
        // default:
        //   return "sorry er ging wat mis"
      }
    }
  }
});

