import type { Page, PageKey, Translations } from '../types';
import { useNuxtApp } from '#imports';

function getTranslation(
  pages: Page[],
  pageKey: PageKey,
  translation: string | undefined = undefined
) {
  const { $translate } = useNuxtApp();
  return translation
    ? $translate(translation)
    : (pages?.find((page) => page.key === pageKey)?.name ?? pageKey);
}

type PageKeyMap = Record<PageKey, string>;

export function useTranslationMap(
  pages: Page[] | undefined,
  translations: Translations | undefined
) {
  if (!pages || !translations)
    return {
      translationMapping: undefined,
      mobileTranslationMapping: undefined
    };
  const translationMapping: PageKeyMap = {
    members: getTranslation(pages, 'members', translations?.membersPage),
    matchgame: getTranslation(pages, 'matchgame', translations?.matchesPage),
    visitors: getTranslation(pages, 'visitors', translations?.visitorsPage),
    favourites: getTranslation(pages, 'favourites', translations?.favoritesPage),
    chat: getTranslation(pages, 'chat', translations?.chatPage),
    messagebox: getTranslation(pages, 'messagebox', translations?.chatPage),
    cookieStatement: getTranslation(pages, 'cookieStatement'),
    termsAndConditions: getTranslation(pages, 'termsAndConditions'),
    protectOurChildren: getTranslation(pages, 'protectOurChildren'),
    companyInformation: getTranslation(pages, 'companyInformation'),
    supportContactAndAddress: getTranslation(pages, 'supportContactAndAddress'),
    mediaGiantNL: getTranslation(pages, 'mediaGiantNL'),
    priceInformationPremium: getTranslation(pages, 'priceInformationPremium'),
    privacyStatement: getTranslation(pages, 'privacyStatement'),
    '2257Statement': getTranslation(pages, '2257Statement'),
    reportPage: getTranslation(pages, 'reportPage'),
    introText: getTranslation(pages, 'introText'),
    footerText: getTranslation(pages, 'footerText'),
    home: getTranslation(pages, 'home'),
    profile: getTranslation(pages, 'profile'),
    matched: getTranslation(pages, 'matched'),
    'i-like': getTranslation(pages, 'i-like'),
    'liked-me': getTranslation(pages, 'liked-me'),
    credits: getTranslation(pages, 'credits', translations?.creditsPage),
    account: getTranslation(pages, 'account'),
    settings: getTranslation(pages, 'settings'),
    login: getTranslation(pages, 'login'),
    error: getTranslation(pages, 'error'),
    gallery: getTranslation(pages, 'gallery'),
    'my-pictures': getTranslation(pages, 'my-pictures'),
    payment: getTranslation(pages, 'payment'),
    signupConfirmationText: getTranslation(pages, 'signupConfirmationText')
  };

  const mobileTranslationMapping: PageKeyMap = {
    members: getTranslation(pages, 'members', translations?.membersPageMobile),
    matchgame: getTranslation(pages, 'matchgame', translations?.matchesPageMobile),
    visitors: getTranslation(pages, 'visitors', translations?.visitorsPageMobile),
    favourites: getTranslation(pages, 'favourites', translations?.favoritesPageMobile),
    chat: getTranslation(pages, 'chat', translations?.chatPageMobile),
    messagebox: getTranslation(pages, 'messagebox', translations?.chatPageMobile),
    cookieStatement: getTranslation(pages, 'cookieStatement'),
    termsAndConditions: getTranslation(pages, 'termsAndConditions'),
    protectOurChildren: getTranslation(pages, 'protectOurChildren'),
    companyInformation: getTranslation(pages, 'companyInformation'),
    supportContactAndAddress: getTranslation(pages, 'supportContactAndAddress'),
    mediaGiantNL: getTranslation(pages, 'mediaGiantNL'),
    priceInformationPremium: getTranslation(pages, 'priceInformationPremium'),
    privacyStatement: getTranslation(pages, 'privacyStatement'),
    '2257Statement': getTranslation(pages, '2257Statement'),
    reportPage: getTranslation(pages, 'reportPage'),
    introText: getTranslation(pages, 'introText'),
    footerText: getTranslation(pages, 'footerText'),
    home: getTranslation(pages, 'home'),
    profile: getTranslation(pages, 'profile'),
    matched: getTranslation(pages, 'matched'),
    'i-like': getTranslation(pages, 'i-like'),
    'liked-me': getTranslation(pages, 'liked-me'),
    credits: getTranslation(pages, 'credits', translations?.creditsPage),
    account: getTranslation(pages, 'account'),
    settings: getTranslation(pages, 'settings'),
    login: getTranslation(pages, 'login'),
    error: getTranslation(pages, 'error'),
    gallery: getTranslation(pages, 'gallery'),
    'my-pictures': getTranslation(pages, 'my-pictures'),
    payment: getTranslation(pages, 'payment'),
    signupConfirmationText: getTranslation(pages, 'signupConfirmationText')
  };

  return {
    translationMapping,
    mobileTranslationMapping
  };
}

