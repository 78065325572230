<script setup lang="ts">
import type { MessageDocument } from '@horizon/chat-protocol-ts';
import { computed, ref, toRefs } from 'vue';
import { unixToChatDate, unixToDateTimeFormat } from '../../../utils';
import { getFlirtDataFromText } from '../../../utils/flirt';
import { useChatStore } from '../../../stores/chat.store';
import type { HorizonComponentProps } from '../../../types';
import Emoji from 'emoji-js';

type Props = {
  message: MessageDocument;
};
const props = defineProps<HorizonComponentProps<Props>>();
const { data } = toRefs(props);

const chatStore = useChatStore();

const attachmentUrl = ref<string>();

const emojiConvertor = new Emoji();
emojiConvertor.replace_mode = 'unified';
emojiConvertor.allow_native = true;

const replacedContent = computed<string>(() => {
  const m = data.value?.message?.content;
  if (!m) return '';

  return emojiConvertor.replace_colons(m);
});

async function loadUrl() {
  if (!data.value.message.attachment) return;

  const imageUrl = await chatStore.getMessageAttachment(data.value.message.attachment);

  if (imageUrl) {
    attachmentUrl.value = imageUrl;
  }
}

loadUrl();
</script>

<template>
  <li
    :class="{
      'self-start': data.message?.senderType === 'agent',
      'self-end': data.message?.senderType === 'customer'
    }"
    class="max-w-9/10"
    :data-testid="props.testId + '/' + props.data.message.id"
  >
    <div class="flex flex-col">
      <VTooltip>
        <div class="flex">
          <p v-if="data.message.senderType === 'customer'" class="text-header-text mr-auto text-sm">
            You&nbsp;
          </p>
          <time
            v-if="data.message.createdAt"
            class="text-header-text/60 text-sm"
            :datetime="unixToChatDate(data.message.createdAt, true)"
          >
            {{ unixToChatDate(data.message.createdAt, true) }}
          </time>
        </div>

        <template #popper>
          {{ unixToDateTimeFormat(data.message.createdAt, true) }}
        </template>
      </VTooltip>

      <article
        :class="{
          'mr-auto bg-gray-200': data.message.senderType === 'agent',
          'bg-primary ml-auto': data.message.senderType === 'customer'
        }"
        class="flex max-w-full flex-col text-wrap rounded-lg p-3"
      >
        <img
          v-if="data.message.type === 'flirt' && data.message.content"
          class="mx-auto mb-1 h-auto max-h-44 w-auto max-w-fit"
          :src="getFlirtDataFromText(data.message.content).image"
          alt=""
        />

        <img
          v-else-if="data.message.attachment"
          class="mx-auto mb-1 h-44 max-h-44 min-h-44 max-w-44"
          :src="attachmentUrl"
          alt="image could not be retrieved"
        />

        <p
          :class="{
            'text-gray-800': data.message.senderType === 'agent',
            'text-white': data.message.senderType === 'customer'
          }"
          class="whitespace-normal break-words"
        >
          {{ replacedContent }}
        </p>
      </article>
    </div>
  </li>
</template>

