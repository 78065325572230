<script setup lang="ts">
import { onMounted } from 'vue';
import { initStorage, setLeaveTime } from '@horizon/library/utils';
import TrackingInjector from '~/components/TrackingInjector/TrackingInjector.vue';
import NotificationTray from '~/components/NotificationTray.vue';
import PaymentChecker from './components/PaymentChecker.vue';

onMounted(() => {
  initStorage();
  window.addEventListener('beforeunload', function () {
    setLeaveTime();
  });
});
</script>

<template>
  <NuxtLayout>
    <NuxtPage></NuxtPage>
  </NuxtLayout>

  <NotificationTray></NotificationTray>
  <TrackingInjector></TrackingInjector>
  <PaymentChecker></PaymentChecker>
</template>

