<script setup lang="ts">
import { createCCFetchClient } from '../../../composables/apiClients';
import { useModalStore, useSiteConfigStore, useUserConsentStore } from '../../../stores';
import type { HorizonComponentProps } from '../../../types';
import { overrideStyles } from '../../../utils';
import { replaceVariables } from '../../../utils/variableReplacement';
import TextRenderer from '../../LegalText/TextRenderer.vue';
import type { Props } from './Onboarding.schema';
import { storeToRefs } from 'pinia';
import { ref, computed } from 'vue';

const siteConfigStore = useSiteConfigStore();
const modalStore = useModalStore();
const props = defineProps<HorizonComponentProps<Props>>();
const userConsentStore = useUserConsentStore();
const { userConsentState, consentSegments, totalSegmentCount } = storeToRefs(userConsentStore);
const ccFetchClient = createCCFetchClient();

const currentSegmentNumber = ref<number>(0);
const currentSegment = computed(() => consentSegments.value?.at(currentSegmentNumber.value));

const currentSegmentContent = computed(() => {
  const content = currentSegment.value?.content;
  if (!content) return;
  const company = userConsentState.value?.variables?.company;
  if (!company) return;

  return replaceVariables(currentSegment.value.content, {
    company,
    modifiedAt: currentSegment.value.modifiedAt,
    launchYear: new Date(currentSegment.value.createdAt).getFullYear()
  });
});

const isLoggingConsent = ref<boolean>(false);
const loggingError = ref<string>();

async function reject() {
  if (!currentSegment.value?.versionID) return;

  if (
    currentSegment.value.isRequired &&
    window.confirm("We can't finish registering your account if you don't agree")
  ) {
    return;
  }

  isLoggingConsent.value = true;
  await logConsent(currentSegment.value.versionID, false);
  isLoggingConsent.value = false;

  if (currentSegment.value.isRequired) {
    const exitWebsiteUrl = siteConfigStore.siteSettings?.exitWebsiteUrl ?? 'https://google.com';
    if (import.meta.client) window.location.href = exitWebsiteUrl;
    return;
  }

  nextSegment();
}

async function agree() {
  if (!currentSegment.value?.versionID) return;

  isLoggingConsent.value = true;
  const success = await logConsent(currentSegment.value.versionID, true);
  isLoggingConsent.value = false;

  if (!success) {
    loggingError.value = 'Something went wrong while agreeing, please try again later.';
    return;
  }

  nextSegment();
}

function nextSegment() {
  if (currentSegmentNumber.value === totalSegmentCount.value - 1) {
    modalStore.clearModal();
    userConsentStore.grantUserConsentAccess();
    return;
  }

  currentSegmentNumber.value++;
}

function stepClasses(step: number) {
  if (step === currentSegmentNumber.value) return 'bg-primary text-background';

  return step < currentSegmentNumber.value ? 'bg-success text-text' : 'bg-white text-black';
}

async function logConsent(consentSegmentVersionID: string, hasAccepted: boolean): Promise<boolean> {
  const { error } = await ccFetchClient('/user/consent', {
    method: 'POST',
    body: {
      consentSegmentVersionID,
      hasAccepted,
      type: 'modal'
    }
  });

  return !error;
}
</script>

<template>
  <div
    class="modal-wrapper border-primary bg-background relative m-5 flex h-fit max-h-full w-full max-w-[1200px] flex-wrap gap-4 overflow-y-auto border-2 lg:flex-nowrap"
    :style="overrideStyles(props.overrides)"
  >
    <div class="bg-header-bg lg:max-w-modal-small w-full p-10">
      <section class="max-w-modal-small mx-auto text-center text-sm">
        <img
          class="mx-auto mb-4 size-32 rounded-full"
          :src="props.data?.image.src"
          :alt="props.data?.image.alt"
        />
        <TextRenderer
          :data="{ text: userConsentState?.introText ?? '-' }"
          :test-id="null"
        ></TextRenderer>
      </section>
    </div>

    <section class="flex w-full flex-col p-10">
      <div
        v-if="loggingError"
        class="bg-header-bg mb-10 w-full rounded-full py-4 text-center text-sm text-white"
      >
        <strong>{{ loggingError }}</strong>
      </div>

      <div class="stepper">
        <div class="line"></div>

        <div
          v-for="i in totalSegmentCount"
          :key="i"
          class="z-10 grid h-8 w-8 place-items-center rounded-full text-sm"
          :class="stepClasses(i - 1)"
        >
          {{ i }}
        </div>
      </div>

      <TextRenderer
        v-if="currentSegmentContent"
        :key="currentSegmentNumber"
        class="mb-10 flex flex-1 flex-col justify-center"
        :data="{ text: currentSegmentContent }"
        :test-id="null"
      ></TextRenderer>

      <div class="flex flex-wrap justify-around gap-4 md:flex-nowrap">
        <button
          class="btn btn-secondary flex w-full items-center justify-center gap-3 border-2"
          type="button"
          :disabled="isLoggingConsent"
          :class="{ 'cursor-not-allowed bg-white/30': isLoggingConsent }"
          :style="overrideStyles(props.data?.rejectButton.overrides)"
          :data-testid="props.testId + '/RejectButton'"
          @click="reject()"
        >
          <Icon
            v-if="props.data?.rejectButton?.data?.iconName"
            :name="props.data?.rejectButton?.data?.iconName"
            size="16"
          />
          <span class="text-inherit">{{ props.data?.rejectButton?.data?.label ?? '-' }}</span>
        </button>

        <button
          class="btn btn-primary flex w-full items-center justify-center gap-3 border-2"
          type="button"
          :disabled="isLoggingConsent"
          :class="{ 'cursor-not-allowed bg-white/30': isLoggingConsent }"
          :style="overrideStyles(props.data?.acceptButton.overrides)"
          :data-testid="props.testId + '/AgreeButton'"
          @click="agree()"
        >
          <Icon
            v-if="props.data?.acceptButton?.data?.iconName"
            :name="props.data?.acceptButton?.data?.iconName"
            size="16"
          />
          <span class="text-inherit">
            {{ props.data?.acceptButton?.data?.label ?? '-' }}
            <span v-if="currentSegment?.isRequired" class="text-red-400">*</span>
          </span>
        </button>
      </div>
    </section>
  </div>
</template>

<style scoped lang="postcss">
.modal-wrapper {
  scrollbar-gutter: stable;
}

.stepper {
  @apply relative mb-10 flex w-full justify-between;

  .line {
    position: absolute;
    content: '';
    background-color: gray;
    width: 98%;
    height: 6px;
    left: 1%;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>

