<script setup lang="ts">
import type { HorizonComponentProps } from '../../types';
import type { Props } from './TextRenderer.schema';
import { sanitizeContent } from '../../utils/sanitizeContent';

const props = defineProps<HorizonComponentProps<Props>>();
const footer = props.data?.footer ?? false;
const sanitized = sanitizeContent(props.data?.text).replaceAll('<a', '<a target="_blank" ');
</script>

<template>
  <!-- eslint-disable vue/no-v-html -->
  <article
    v-if="sanitized"
    class="prose prose-li:my-0 prose-p:my-0 prose-strong:text-strong prose-headings:text-header-text max-w-none"
    :class="
      footer
        ? 'prose-a:text-footer-link prose-p:text-footer-text prose-p:text-xs'
        : 'prose-a:text-link hover:prose-a:text-link-hover prose-p:text-text'
    "
    v-html="sanitized"
  ></article>
</template>

