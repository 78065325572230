<script setup lang="ts">
import { nextTick, ref, storeToRefs, watch, watchEffect } from '#imports';
import { useChatStore } from '../../../stores/chat.store';
import type { HorizonComponentProps } from '../../../types';
import MessageListItem from './MessageListItem.vue';

const props = defineProps<HorizonComponentProps>();

const chatStore = useChatStore();
const { currentChatUser } = storeToRefs(chatStore);

const messageList = ref<HTMLUListElement>();

watch(currentChatUser, async () => {
  if (currentChatUser.value?.chatID && currentChatUser.value.chatID !== 'none') {
    await chatStore.markChatAsRead(currentChatUser.value.chatID);
  }
});

watchEffect(async () => {
  if (!currentChatUser.value?.messages) return;

  if (!messageList.value) return;

  await nextTick();
  messageList.value.scrollTop = 1_000_000_000;
});
</script>

<template>
  <ul
    ref="messageList"
    class="hidden-scrollbar flex h-0 min-h-full grow-0 flex-col items-start gap-4 px-4 py-4"
    :data-testid="props.testId + '/MessageList'"
  >
    <template v-if="currentChatUser">
      <MessageListItem
        v-for="message of currentChatUser.messages"
        :key="message.id"
        :data="{ message }"
        :test-id="props.testId + '/MessageList'"
      ></MessageListItem>
    </template>
  </ul>
</template>

