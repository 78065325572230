<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref, inject, type Component } from 'vue';
import { useMatchGameStore, useModalStore, useSiteConfigStore } from '../../stores';
import type { HorizonComponentProps, ProfileCard } from '../../types';
import { dateToAge, storageKeys, storeValue, getSkeletonGrid, overrideStyles } from '../../utils';
import Hearts from './ModernflingSupport/Hearts.vue';

const props = defineProps<HorizonComponentProps>();
const mgStore = useMatchGameStore();
const modalStore = useModalStore();
const { direction, noMoreProfilesText } = storeToRefs(mgStore);
const siteConfigStore = useSiteConfigStore();
const { profileCardComponent } = storeToRefs(siteConfigStore);
const components = inject<Record<string, Component>>('components');
const emit = defineEmits<{
  (e: 'showModal'): void;
  (e: 'click', like: boolean): void;
}>();
type tutorialStages = 'like' | 'dislike' | 'flirt' | 'chat';
const tutorialStage = ref<tutorialStages>('like');
const tutorial = ref<boolean>(false);
const stacksize = 5;

onBeforeMount(() => {
  tutorial.value = mgStore.getSavedtutorialState();
});

await mgStore.fetchProfiles();

const profiles = computed(() => {
  return mgStore.profiles.slice(0, stacksize).reverse();
});
const actualStacksize = computed<number>(() => Math.min(stacksize, profiles.value.length));

const skeletonCard = getSkeletonGrid(1)[0];

function skipTutorial() {
  tutorial.value = false;
  storeValue(storageKeys.matchGame.tutorial, tutorial.value);
}

function showFiltersModal() {
  modalStore.showFilterModal({ showLocationFilter: true });
}

function showFlirtModal(profile: ProfileCard) {
  modalStore.showFlirtModal({
    profileId: profile.profile_id
  });
}
</script>

<template>
  <div
    v-if="components && profiles.length === 0"
    class="mt-26 mb-12 flex h-[37rem] w-full items-center justify-center md:mt-2"
  >
    <component
      :is="components[profileCardComponent.name]"
      v-if="profileCardComponent"
      class="relative top-24 z-30 mb-12 h-[46rem] w-[24rem] sm:top-20"
      :data="{
        profile: skeletonCard,
        isDetailPageCard: false,
        isLoading: true
      }"
      :style="overrideStyles(profileCardComponent?.overrides)"
      :test-id="null"
    ></component>
  </div>

  <TransitionGroup
    v-else
    name="list"
    tag="ul"
    class="mt-26 mb-12 flex h-[37rem] w-full items-center justify-center md:mt-2"
    :style="`--direction: ${direction}`"
  >
    <div
      v-if="tutorial"
      class="z-30"
      :class="{ 'opacity-100 transition-opacity duration-700 ease-in': tutorial }"
    >
      <div
        v-if="tutorialStage === 'like'"
        class="bg-background relative z-50 flex h-[37rem] w-[24rem] flex-col rounded-lg border-8 border-gray-600"
      >
        <button
          class="text-header-text ml-auto mr-2 mt-2 flex h-7 w-28 items-center justify-center rounded-full bg-gray-700 p-2 text-xs hover:bg-gray-800"
          :data-testid="props.testId + '/SkipTutorialButton'"
          @click="skipTutorial()"
        >
          Skip Tutorial
          <Icon name="ic:sharp-close" size="20" class="text-header-text" />
        </button>
        <div class="mt-3/10 flex flex-col">
          <Icon name="fa-solid:heart" size="24" class="text-header-text mx-auto text-center" />
          <p class="text-header-text my-3 text-center text-2xl">Click the button below to like</p>
          <p class="text-header-text mx-auto w-4/5 text-center text-sm">
            Add the profile to your Favorites for easy access, and who knows? You just might
            discover a match!
          </p>
          <div class="border-primary w-3/10 mx-auto mt-4 border"></div>
          <p class="text-header-text w-3/10 mx-auto mt-2 text-center text-sm">Try it out now!</p>
        </div>
        <button
          aria-label="like"
          class="bg-btn-primary-bg border-btn-primary-bg text-header-text mb-5 ml-auto mr-3 mt-auto flex h-16 w-16 items-center justify-center rounded-full border-2 p-2 shadow"
          :class="'hover:border-header-text'"
          :data-testid="props.testId + '/TutorialLikeButton'"
          @click="tutorialStage = 'dislike'"
        >
          <Hearts />
        </button>
      </div>
      <div
        v-if="tutorialStage === 'dislike'"
        class="bg-background relative z-50 flex h-[37rem] w-[24rem] flex-col rounded-lg border-8 border-gray-600"
      >
        <button
          class="text-header-text ml-auto mr-2 mt-2 flex h-7 w-28 items-center justify-center rounded-full bg-gray-700 p-2 text-xs hover:bg-gray-800"
          :data-testid="props.testId + '/SkipTutorialButton'"
          @click="skipTutorial()"
        >
          Skip Tutorial
          <Icon name="ic:sharp-close" size="20" class="text-header-text" />
        </button>
        <div class="mt-3/10 flex flex-col">
          <button
            aria-label="dislike"
            class="border-btn-header-text text-header-text z-20 mx-auto mt-auto flex h-12 w-12 items-center justify-center rounded-full border-2 bg-transparent p-2 shadow hover:cursor-default"
          >
            <Icon name="ic:outline-close" size="24" />
          </button>
          <p class="text-header-text my-3 text-center text-2xl">
            Click the button below to dislike
          </p>
          <p class="text-header-text mx-auto w-3/4 text-center text-sm">
            Clicking this means you didn't like the profile. We will show the next profile selected
            for you!
          </p>
          <div class="border-primary w-3/10 mx-auto mt-4 border"></div>
          <p class="text-header-text w-3/10 mx-auto mt-2 text-center text-sm">Try it out now!</p>
        </div>
        <button
          aria-label="dislike"
          class="border-btn-primary-bg text-primary z-20 mb-5 ml-3 mr-auto mt-auto flex h-16 w-16 items-center justify-center rounded-full border-2 bg-transparent p-2 shadow"
          :class="'hover:bg-btn-primary-bg hover:border-header-text hover:text-header-text'"
          :data-testid="props.testId + '/TutorialDislikeButton'"
          @click="tutorialStage = 'flirt'"
        >
          <Icon name="ic:outline-close" size="32" />
        </button>
      </div>
      <div
        v-if="tutorialStage === 'flirt' || tutorialStage === 'chat'"
        class="bg-background relative z-50 flex h-[37rem] w-[24rem] flex-col rounded-lg border-8 border-gray-600"
      >
        <button
          class="text-header-text ml-auto mr-2 mt-2 flex h-7 w-28 items-center justify-center rounded-full bg-gray-700 p-2 text-xs hover:bg-gray-800"
          :data-testid="props.testId + '/SkipTutorialButton'"
          @click="skipTutorial()"
        >
          Skip Tutorial
          <Icon name="ic:sharp-close" size="20" class="text-header-text" />
        </button>
        <div class="mt-3/10 flex flex-col">
          <Icon
            :name="tutorialStage === 'flirt' ? 'fa-regular:kiss-wink-heart' : 'fa:comments-o'"
            size="24"
            class="text-header-text mx-auto text-center"
          />
          <p class="text-header-text my-3 text-center text-2xl">
            {{ tutorialStage === 'flirt' ? 'Send a flirt' : 'Start Chatting' }}
          </p>
          <p class="text-header-text mx-auto w-4/5 text-center text-sm">
            {{
              tutorialStage === 'flirt'
                ? 'Send a flirt, let somebody know you are really into them!'
                : 'Chat away! What better way to get in contact and stay in touch?'
            }}
          </p>
          <div class="border-primary w-3/10 mx-auto mt-4 border"></div>
          <p class="text-header-text w-3/10 mx-auto mt-2 text-center text-sm">Try it out now!</p>
        </div>
        <div class="mx-auto mb-5 mt-auto flex justify-center gap-6">
          <div class="group flex flex-col" :class="{ invisible: tutorialStage != 'flirt' }">
            <button
              aria-label="flirt"
              class="text-header-text group-hover:text-primary group-hover:border-header-text z-20 flex h-11 w-11 items-center justify-center rounded-full border border-gray-400 bg-gray-900 p-2 shadow group-hover:border-2"
              :data-testid="props.testId + '/TutorialFlirtButton'"
              @click="tutorialStage = 'chat'"
            >
              <Icon name="fa-regular:kiss-wink-heart" size="24" />
            </button>
            <p class="text-header-text group-hover:text-primary mx-auto mt-1 text-xs">Flirt</p>
          </div>
          <div class="group flex flex-col" :class="{ invisible: tutorialStage != 'chat' }">
            <button
              aria-label="chat"
              class="text-header-text group-hover:text-primary group-hover:border-header-text z-20 flex h-11 w-11 items-center justify-center rounded-full border border-gray-400 bg-gray-900 p-2 shadow group-hover:border-2"
              :data-testid="props.testId + '/TutorialToChatButton'"
              @click="skipTutorial()"
            >
              <Icon name="fa:comments-o" size="24" />
            </button>
            <p class="text-header-text group-hover:text-primary mx-auto mt-1 text-xs">Chat</p>
          </div>
        </div>
      </div>
    </div>

    <li
      v-for="(profile, index) in profiles"
      v-show="profiles.length > 0"
      :key="profile.profile_id"
      class="absolute h-[37rem] w-[24rem]"
      :style="`--index: ${actualStacksize - 1 - index};`"
    >
      <div
        v-if="profile?.image?.url != ''"
        class="h-full rounded-lg border-8 border-gray-600 shadow-sm shadow-black"
      >
        <img class="h-full w-full object-cover" :src="profile.image.url" :alt="profile.name" />
        <div class="relative bottom-36 h-36 w-full bg-black/50">
          <div class="flex">
            <p class="text-header-text ml-4 mt-3 text-2xl">{{ profile.name }}</p>
            <p class="drop-shadow-black relative top-1.5 ml-2 mt-3 text-gray-400 drop-shadow-lg">
              {{ dateToAge(profile.date_of_birth) }} Years
            </p>
          </div>
          <div v-if="profiles && profiles.length" class="mx-3 mt-4 flex justify-center gap-6">
            <button
              aria-label="dislike"
              class="border-btn-primary-bg text-primary z-20 mr-auto flex h-16 w-16 items-center justify-center rounded-full border-2 bg-transparent p-2 shadow"
              :class="'hover:bg-btn-primary-bg hover:border-header-text hover:text-header-text'"
              :data-testid="props.testId + '/DislikeButton'"
              @click="emit('click', false)"
            >
              <Icon name="ic:outline-close" size="32" />
            </button>

            <div class="mx-auto flex justify-center gap-6">
              <div class="group flex flex-col">
                <button
                  aria-label="flirt"
                  class="text-header-text group-hover:text-primary group-hover:border-header-text z-20 flex h-11 w-11 items-center justify-center rounded-full border border-gray-400 bg-gray-900 p-2 shadow group-hover:border-2"
                  :data-testid="props.testId + '/FlirtButton'"
                  @click="showFlirtModal(profile)"
                >
                  <Icon name="fa-regular:kiss-wink-heart" size="24" />
                </button>
                <p class="text-header-text group-hover:text-primary mx-auto mt-1 text-xs">Flirt</p>
              </div>
              <div class="group flex flex-col">
                <NuxtLink
                  aria-label="chat"
                  :to="`/message/${profile.profile_id}`"
                  class="text-header-text group-hover:text-primary group-hover:border-header-text z-20 flex h-11 w-11 items-center justify-center rounded-full border border-gray-400 bg-gray-900 p-2 shadow group-hover:border-2"
                  :data-testid="props.testId + '/ToChatButton'"
                >
                  <Icon name="fa:comments-o" size="24" />
                </NuxtLink>
                <p class="text-header-text group-hover:text-primary mx-auto mt-1 text-xs">Chat</p>
              </div>
            </div>

            <button
              aria-label="like"
              class="bg-btn-primary-bg border-btn-primary-bg text-header-text ml-auto flex h-16 w-16 items-center justify-center rounded-full border-2 p-2 shadow"
              :class="'hover:border-header-text'"
              :data-testid="props.testId + '/LikeButton'"
              @click="emit('click', true)"
            >
              <Hearts />
            </button>
          </div>
        </div>
      </div>
      <div
        v-else
        class="w-max-[26rem] text-header-text mb-28 h-full items-center justify-center rounded-xl text-lg md:block lg:text-base xl:text-lg"
      >
        <div
          class="shadow-softglow bg-primary text-header flex h-96 flex-col items-center overflow-hidden rounded-xl p-4 text-center shadow-black/40"
        >
          <div class="bg-primary mb-4 flex flex-row items-center gap-4 pt-8 text-center">
            <p class="text-inherit">{{ noMoreProfilesText }}</p>
          </div>

          <div class="border-primary relative mb-4 w-full border-0 bg-transparent text-center">
            Change your filters to see new profiles
          </div>
          <button
            class="btn relative w-full border border-white bg-transparent text-center"
            :data-testid="props.testId + '/FilterButton'"
            @click="showFiltersModal()"
          >
            Select filters
          </button>
        </div>
      </div>
    </li>
  </TransitionGroup>
</template>

<style scoped>
li {
  transform-style: preserve-3d;
  transition:
    transform 0.8s ease,
    opacity 0.7s ease;

  transform: translateY(calc(var(--index) * 32px)) scale(max(calc(1 - var(--index) * 0.1), 0));
}

.list-enter-from {
  opacity: 0;
  transform: translateY(120px) scale(max(calc(1 - var(--index) * 0.1), 0));
}

.list-leave-to {
  z-index: 0;
  opacity: 0;
  transform: translateX(calc(var(--direction) * 120px)) rotate(calc(var(--direction) * 15deg))
    scale(0.95);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
  position: absolute;
}
</style>

