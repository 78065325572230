<script setup lang="ts">
import { useModalStore, useSiteConfigStore } from '../../../stores';
import Hearts from '../../CardSwiper/ModernflingSupport/Hearts.vue';
import type { ProfileCard } from '../../../types/profile';
import type { HorizonComponentProps } from '../../../types';
import HeartParticleSpawner from './HeartParticleSpawner.vue';
import { storeToRefs } from 'pinia';
import { useNuxtApp } from '#imports';

const { $translate } = useNuxtApp();
const modalStore = useModalStore();
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const props = defineProps<HorizonComponentProps<ProfileCard>>();
</script>

<template>
  <HeartParticleSpawner></HeartParticleSpawner>

  <div
    v-if="props && props.data.image && props.data.image.url != ''"
    class="top-1/10 min-h-modal-sm absolute mx-auto h-[58vh] w-[400px] rounded-lg"
  >
    <img
      class="mx-auto h-full w-[400px] rounded-xl object-cover"
      :src="props.data.image.url"
      :alt="props.data.name"
    />
    <div
      class="min-h-modal-sm absolute top-0 flex h-[58vh] w-full place-content-center place-items-center rounded-xl bg-[#22252c]/70"
    >
      <div class="mx-auto flex flex-col">
        <button
          class="bg-btn-primary-bg border-btn-primary-bg text-header-text mx-auto flex h-16 w-16 cursor-default items-center justify-center rounded-full border-2 p-2 shadow"
        >
          <Hearts />
        </button>
        <div class="mx-auto mt-6 flex flex-col">
          <p class="text-header-text mx-auto">{{ $translate(translations?.youMatchedWith) }}</p>
          <p class="text-header-text text-2xl">{{ props.data.name }}</p>
        </div>
        <div class="mx-auto flex flex-col">
          <NuxtLink
            aria-label="chat"
            :to="`/message/${props.data.profile_id}`"
            class="text-header-text group-hover:text-primary bg-primary z-20 flex h-9 w-48 items-center justify-center rounded-xl p-2"
            :data-testid="props.testId + '/ToChatButton'"
            @click="modalStore.clearModal()"
          >
            {{ $translate(translations?.sendAMessage) }}
            <Icon name="fa:comments-o" size="24" />
          </NuxtLink>
          <button
            class="text-header-text group-hover:text-primary z-20 mt-2 flex h-9 w-48 items-center justify-center rounded-xl bg-gray-700 p-2"
            :data-testid="props.testId + '/CloseButton'"
            @click="modalStore.clearModal()"
          >
            {{ $translate(translations?.keepPlaying) }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

