import { z } from 'zod';

export const TranslationKeySchema = z.object({
  loginButton: z.string().optional(),
  membersPage: z.string().optional(),
  membersPageMobile: z.string().optional(),
  matchesPage: z.string().optional(),
  matchesPageMobile: z.string().optional(),
  visitorsPage: z.string().optional(),
  visitorsPageMobile: z.string().optional(),
  favoritesPage: z.string().optional(),
  favoritesPageMobile: z.string().optional(),
  chatPage: z.string().optional(),
  chatPageMobile: z.string().optional(),
  creditsPage: z.string().optional(),
  preTitle: z.string().optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  signupButton: z.string().optional(),
  betweenButtonsText: z.string().optional(),
  footerText: z.string().optional(),
  logoutText: z.string().optional(),
  legalTextfetchError: z.string().optional(),
  filterText: z.string().optional(),
  emptyResult: z.string().optional(),
  resetFilterLabel: z.string().optional(),
  membersButton: z.string().optional(),
  matchButton: z.string().optional(),
  test: z.string().optional(),
  errorHeading: z.string().optional(),
  invalidError: z.string().optional(),
  emailTokenError: z.string().optional(),
  verifyError: z.string().optional(),
  verifiedLogInError: z.string().optional(),
  googleSSOCallError: z.string().optional(),
  googleSSOLoginError: z.string().optional(),
  loadError: z.string().optional(),
  tryAgain: z.string().optional(),
  whileError: z.string().optional(),
  emailDisabled: z.string().optional(),
  pageNotFoundTitle: z.string().optional(),
  pageNotFoundSubTitle: z.string().optional(),
  page: z.string().optional(),
  love: z.string().optional(),
  bestValue: z.string().optional(),
  save: z.string().optional(),
  maximumFlirtsExceeded: z.string().optional(),
  backHome: z.string().optional(),
  you: z.string().optional(),
  failedToSendMessage: z.string().optional(),
  succesfullySentMessage: z.string().optional(),
  send: z.string().optional(),
  credits: z.string().optional(),
  price: z.string().optional(),
  pricePerMessage: z.string().optional(),
  youMatchedWith: z.string().optional(),
  sendAMessage: z.string().optional(),
  keepPlaying: z.string().optional()
});

