<script setup lang="ts">
import { navigateTo, useNuxtApp } from '#app';
import { useSiteConfigStore } from '../../stores';
import { storeToRefs } from 'pinia';
import { storageKeys, getStoredValue } from '../../utils';
import type { HorizonComponentProps } from '../../types';
import { computed } from 'vue';
import type { TranslationVars } from '../../types/config/translation';

const props = defineProps<HorizonComponentProps>();
const fromProfile = computed(() => getStoredValue(storageKeys.notFound.IsProfile));
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const { $translate } = useNuxtApp();
const vars = computed<TranslationVars>(() => {
  return { notFoundVar: fromProfile.value ? 'love' : 'page' };
});
</script>

<template>
  <div class="flex h-[50vh] flex-col">
    <div class="container mt-10 flex size-full flex-col items-center">
      <div class="mb-4 flex flex-row">
        <p class="text-primary mx-2 my-auto text-9xl">4</p>
        <Icon
          name="solar:heart-broken-line-duotone"
          size="100"
          class="text-primary mx-auto my-auto"
        />
        <p class="text-primary mx-2 my-auto text-9xl">4</p>
      </div>

      <h1 class="text-text font mb-2 text-balance text-center text-5xl">
        {{ $translate(translations?.pageNotFoundTitle, vars) + '.' }}
      </h1>
      <h1 v-if="fromProfile" class="text-text font text-balance text-center text-3xl italic">
        {{ $translate(translations?.pageNotFoundSubTitle) + '!' }}
      </h1>

      <ButtonSimple
        :data="{ label: $translate(translations?.backHome) }"
        class="mt-8"
        :test-id="props.testId"
        @click="navigateTo('/')"
      />
    </div>
  </div>
</template>

<style>
.font {
  font-family: 'sofia';
}
</style>

