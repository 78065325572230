<script setup lang="ts">
//TODO: temp solution until https://harlemnext.atlassian.net/browse/HIL-792 is fixed
import { onMounted, onUnmounted, ref, toRefs, computed, watch } from 'vue';
import { useAuthStore, useGlobalsStore, useSiteConfigStore } from '../../stores';
import type { HorizonComponentProps } from '../../types';
import type { Props } from './Modernfling.schema';
import { useFooter } from '../Footer/useFooter';
import TextFetcher from '../LegalText/TextFetcher.vue';
import { useScroll, useWindowSize } from '@vueuse/core';
import { storeToRefs, useNuxtApp } from '#imports';
import Footer from '../Footer/Modernfling.vue';

const { $translate } = useNuxtApp();
const authStore = useAuthStore();
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const globals = useGlobalsStore();

const props = defineProps<HorizonComponentProps<Props>>();
const { showFooter, footerLinks } = useFooter();
const directionChangePosition = ref<number>(0);
const { y, directions } = useScroll(window, { behavior: 'smooth' });
const { width: pageWidth, height } = useWindowSize();
const { top: up, bottom: down } = toRefs(directions);
const lastDirectionUp = ref<boolean>(false);
const forceShowFooter = ref<boolean>(globals.dynamicGlobals.profileGrid.forceFooter);
const wheelTriggerBuffer = 20;
const scrollTriggerBuffer = computed(() =>
  pageWidth.value > 768 ? height.value / 10 : height.value / 5
);

const showArrow = computed<boolean>(() => !showFooter.value);

watch(
  () => globals.dynamicGlobals.profileGrid.forceFooter,
  (newVal) => {
    forceShowFooter.value = newVal;
    showFooter.value = shouldShowFooter();
  }
);

function shouldShowFooter() {
  if (forceShowFooter.value) return true;
  if (up.value || lastDirectionUp.value) {
    const threshold = directionChangePosition.value - scrollTriggerBuffer.value;
    return y.value < threshold;
  }
  if (down.value || !lastDirectionUp.value) {
    const threshold = directionChangePosition.value + scrollTriggerBuffer.value / 2;
    return !(y.value > threshold);
  }
  return false;
}

function setShowFooter() {
  directionChangePosition.value = y.value;
  lastDirectionUp.value = false;
  showFooter.value = true;
}

function handleScroll() {
  if (y.value < 0) return;
  if (up.value) {
    if (!lastDirectionUp.value) {
      directionChangePosition.value = y.value;
    }
    lastDirectionUp.value = true;
  }
  if (down.value) {
    if (lastDirectionUp.value) directionChangePosition.value = y.value;
    lastDirectionUp.value = false;
  }
  showFooter.value = shouldShowFooter();
}

function handleWheel(scroll: WheelEvent) {
  if (up.value || down.value) return; // handleScroll should have precedence
  if (scroll.deltaY < -1 * wheelTriggerBuffer) {
    showFooter.value = true;
  }
  if (scroll.deltaY > wheelTriggerBuffer) {
    showFooter.value = false;
  }
}

onMounted(() => {
  window.addEventListener('wheel', handleWheel);
  window.addEventListener('scroll', handleScroll);
  showFooter.value = shouldShowFooter();
});

onUnmounted(() => {
  window.removeEventListener('wheel', handleWheel);
  window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
  <footer
    v-if="!forceShowFooter"
    id="mainFooter"
    class="footerPosition pb-16 md:pb-0"
    :class="{
      footerShow: showFooter,
      footerHide: !forceShowFooter
    }"
  >
    <div class="flex border-b border-[#333741]">
      <VTooltip class="mx-auto hidden md:flex">
        <Icon
          v-if="showArrow"
          name="iconamoon:arrow-up-2-bold"
          size="50"
          class="arrow text-primary relative bottom-16 mx-auto font-bold hover:cursor-pointer"
          :data-testid="props.testId + '/ShowFooterButton'"
          @click="setShowFooter"
        />
        <template #popper> {{ 'Scroll upward for the footer' }} </template>
      </VTooltip>
    </div>

    <div class="bg-header-bg py-4">
      <div class="container">
        <nav>
          <ul
            class="text-footer-text flex flex-wrap justify-center gap-4 md:text-base lg:grid-cols-2"
          >
            <li
              v-for="footerLink in footerLinks"
              :key="footerLink.route"
              class="hover:text-footer-link-hover text-footer-link text-sm"
            >
              <NuxtLink
                :to="footerLink.route"
                :data-testid="props.testId + ' /' + footerLink.label + 'Link'"
                >{{ footerLink.label }}</NuxtLink
              >
            </li>

            <li class="hover:text-footer-link-hover text-footer-link text-sm">
              <NuxtLink
                :to="'https://affiliateheavens.com/'"
                :data-testid="props.testId + '/AffiliatesLink'"
                >{{ 'Affiliates' }}</NuxtLink
              >
            </li>

            <li
              v-if="authStore.isAuthorized"
              class="hover:text-footer-link-hover text-footer-link font-sans text-sm"
            >
              <button
                type="button"
                class="cursor-pointer text-inherit"
                :data-testid="props.testId + '/LogoutLink'"
                @click="authStore.logout()"
              >
                Logout
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="bg-footer-bg hidden w-full py-4 md:flex md:py-8">
      <TextFetcher
        :data="{ legalKey: 'footerText', footer: true }"
        :legal-textfetch-error="$translate(translations?.legalTextfetchError!)"
        :test-id="null"
      />
    </div>
  </footer>
  <Footer v-else :test-id="props.testId" :data> </Footer>
</template>

<style scoped lang="postcss">
footer {
  color: #a8a8a8;
}

.footerPosition {
  box-shadow: none;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.footerHide {
  transform: translate3d(0, 110%, 0);
  transition: 0.4s all ease-out;
}

footer.footerShow {
  transform: translate3d(0, 0, 0);
  transition: 0.4s all ease-out;
}

.arrow {
  animation: 5s linear 0s infinite both running bounce;
}

@keyframes bounce {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25%,
  75% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}
</style>

