<script setup lang="ts">
import { computed } from 'vue';
import { useAuthStore, useSiteConfigStore } from '../../stores';
import type { HorizonComponentProps } from '../../types';
import { useFooter } from './useFooter';
import TextFetcher from '../LegalText/TextFetcher.vue';
import { useRoute } from 'vue-router';
import { storeToRefs, useNuxtApp } from '#imports';

const route = useRoute();
const homepage = computed(() => {
  return route.name === 'Home';
});
const authStore = useAuthStore();
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);
const { $translate } = useNuxtApp();

const props = defineProps<HorizonComponentProps>();
const { footerLinks } = useFooter();
const sourceLogo = siteConfigStore.siteSettings?.logo;
const backUpLogo = { src: '', alt: 'logo' };
const logo = sourceLogo ?? backUpLogo;
</script>

<template>
  <footer id="mainFooter" class="mb-0 w-full" :class="{ 'z-40': homepage }">
    <div class="border-b border-[#333741]" :class="{ 'bg-background': homepage }"></div>

    <div class="bg-header-bg py-4">
      <div
        class="container"
        :class="{ 'py-0 lg:grid lg:grid-cols-[1fr_275px] lg:gap-8': homepage }"
      >
        <div v-if="homepage">
          <NuxtLink to="/" :data-testid="props.testId + '/Logo'">
            <img class="h-12 sm:h-16" :src="logo.src" :alt="logo.alt" />
          </NuxtLink>

          <p class="bg-header-bg text-footer-text text-xs">
            {{ $translate(translations?.footerText) }}
          </p>
        </div>

        <nav>
          <ul
            class="text-footer-text flex flex-wrap justify-center gap-4 md:text-base lg:grid-cols-2"
            :class="{ 'pt-4 lg:grid': homepage }"
          >
            <li
              v-for="footerLink in footerLinks"
              :key="footerLink.route"
              class="hover:text-footer-link-hover text-footer-link text-sm"
            >
              <NuxtLink
                :to="footerLink.route"
                :data-testid="props.testId + ' /' + footerLink.label + 'Link'"
              >
                {{ footerLink.label }}</NuxtLink
              >
            </li>

            <li class="hover:text-footer-link-hover text-footer-link text-sm">
              <NuxtLink
                :to="'https://affiliateheavens.com/'"
                target="_blank"
                :data-testid="props.testId + '/AffiliatesLink'"
              >
                {{ 'Affiliates' }}</NuxtLink
              >
            </li>

            <li
              v-if="authStore.isAuthorized"
              class="hover:text-footer-link-hover text-footer-link font-sans text-sm"
            >
              <button
                type="button"
                class="cursor-pointer text-inherit"
                :data-testid="props.testId + '/LogoutLink'"
                @click="authStore.logout()"
              >
                {{ $translate(translations?.logoutText) }}
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div class="bg-footer-bg w-full py-4 md:py-8">
      <TextFetcher
        :data="{
          legalKey: 'footerText',
          footer: true
        }"
        :test-id="null"
        :legal-textfetch-error="$translate(translations?.legalTextfetchError)"
      />
    </div>
  </footer>
</template>

<style scoped lang="postcss">
footer {
  color: #a8a8a8;
}

.footerPosition {
  box-shadow: none;
  width: 100%;
  position: fixed;
  bottom: 0;
}

.footerHide {
  transform: translate3d(0, 110%, 0);
  transition: 0.4s all ease-out;
}

footer.footerShow {
  transform: translate3d(0, 0, 0);
  transition: 0.4s all ease-out;
}

.arrow {
  animation: 5s linear 0s infinite both running bounce;
}

@keyframes bounce {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25%,
  75% {
    transform: translateY(-15px);
  }
}

.bounce {
  animation-name: bounce;
}
</style>

