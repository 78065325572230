import type { FetchResponseError } from '@btr/fetch-frog/types/utils';
import type { TranslationVars } from '../types/config/translation';
import { createError, useNuxtApp } from '#app';
import type { HorizonNuxtErrorData } from '../types';
import { storeToRefs } from '#imports';
import { useSiteConfigStore } from '../stores/siteConfig.store';

export function throwFatalTranslatedError<T>({
  error,
  whileVar,
  whatVar,
  devTitle,
  devMessage
}: {
  error: FetchResponseError<T> | null;
  whileVar: TranslationVars['whileVar'];
  whatVar: TranslationVars['whatVar'];
  devTitle: string;
  devMessage: string | undefined;
}) {
  const { $translate } = useNuxtApp();
  const { translations } = storeToRefs(useSiteConfigStore());

  const vars: TranslationVars = {
    whileVar: whileVar,
    whatVar: whatVar
  };

  throw createError<HorizonNuxtErrorData>({
    data: {
      title: $translate(translations?.value?.whileError, vars),
      subtitle: $translate(translations?.value?.tryAgain),
      devTitle,
      devMessage
    },
    stack: error?.stack,
    fatal: true
  });
}

export function logTranslatedError<T extends Error>({
  error,
  whileVar,
  whatVar,
  devTitle,
  devMessage
}: {
  error: T | null,
  whileVar: TranslationVars['whileVar'];
  whatVar: TranslationVars['whatVar'];
  devTitle: string;
  devMessage: string | undefined;
}) {
  const { $translate } = useNuxtApp();
  const { translations } = storeToRefs(useSiteConfigStore());

  const vars: TranslationVars = {
    whileVar: whileVar,
    whatVar: whatVar
  };

  const result: Record<string, unknown> = {
    title: $translate(translations?.value?.whileError, vars),
    subtitle: $translate(translations?.value?.tryAgain),
  }

  if (import.meta.dev) {
    result.devTitle = devTitle;
    result.devMessage = devMessage ?? '';
    result.error = error;
  }

  console.error(result)
}
