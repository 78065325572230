<script setup lang="ts">
import { computed, toRefs } from 'vue';
import type { chatProfileData, HorizonComponentProps } from '../../../types';
import { unixToFromNowDate, isOnline } from '../../../utils';
import { getFlirtDataFromText } from '../../../utils/flirt';
import type { MessageDocument } from '@horizon/chat-protocol-ts';
import { useNuxtApp } from '#app';
import { useSiteConfigStore } from '../../../stores';
import { storeToRefs } from 'pinia';

type Props = {
  user: chatProfileData;
};
const props = defineProps<HorizonComponentProps<Props>>();
const { data } = toRefs(props);
const emit = defineEmits(['select']);

const { $translate } = useNuxtApp();
const siteConfigStore = useSiteConfigStore();
const { translations } = storeToRefs(siteConfigStore);

const lastMessage = computed<MessageDocument | undefined>(() => data.value.user.messages?.at(-1));
//TODO: replace with actual isOnline data when available:
const online = computed<boolean>(() =>
  isOnline(
    lastMessage?.value?.createdAt ? lastMessage?.value?.createdAt : data.value.user.createdAt
  )
);
</script>

<template>
  <li
    class="border-border border-b-2 p-3 hover:cursor-pointer"
    :data-testid="props.testId + '/' + data.user.chatID"
    @click="emit('select')"
  >
    <NuxtLink :to="`/message/${data.user.card.profile_id}`">
      <div class="grid grid-cols-[1fr_1rem] items-center justify-start">
        <div class="flex items-center justify-start gap-3">
          <div class="relative">
            <img
              class="size-12 rounded-full object-cover drop-shadow-xl"
              :src="data.user.card.image.url"
              :alt="data.user.card.name"
            />

            <!-- TODO: Show online indicator dynamically -->
            <Icon
              :name="online ? 'fluent-emoji-flat:green-circle' : 'fluent-emoji-flat:red-circle'"
              size="10"
              class="absolute bottom-0 right-0 z-10"
            />
          </div>

          <div class="relative w-full overflow-hidden">
            <h3 class="self-start font-medium leading-none">
              {{ data.user.card.name }}
            </h3>
            <p class="text-header-text/60 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
              {{
                lastMessage?.createdAt
                  ? unixToFromNowDate(lastMessage?.createdAt)
                  : unixToFromNowDate(data.user.createdAt)
              }}
            </p>
          </div>
        </div>

        <div class="row-span-2 flex flex-col">
          <div v-if="!data.user.isRead" class="bg-primary size-4 rounded-full"></div>
        </div>

        <div v-if="lastMessage" class="mt-2 flex w-0 min-w-full items-center gap-2">
          <Icon
            v-if="lastMessage && lastMessage?.attachment"
            name="ic:round-attachment"
            class="text-primary"
          />

          <p v-if="lastMessage.senderType === 'customer'" class="text-header-text/60 text-sm">
            {{ $translate(translations?.you) + ':' }}
          </p>

          <div
            v-if="
              lastMessage?.pokeMessageType === 'flirt' || lastMessage?.pokeMessageType === 'normal'
            "
            class="flex"
          >
            <p class="text-header-text/60 text-sm">{{ 'You:' }}</p>
            <img
              class="size-auto max-h-7 max-w-7"
              :src="getFlirtDataFromText(lastMessage?.content ?? '').emoji"
              alt=""
            />
          </div>

          <p class="text-header-text/60 overflow-hidden text-ellipsis whitespace-nowrap text-sm">
            {{ lastMessage?.content }}
          </p>
        </div>
      </div>
    </NuxtLink>
  </li>
</template>

