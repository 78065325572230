export enum Status {
  PaymentSuccessful = 'success',
  PaymentFailed = 'failed',
  PaymentPending = 'pending',
  PendingTimeout = 'pendingTimeout',
  AddingTimeout = 'addingTimeout',
  AddingSuccessful = 'done'
}

export const statusValues = Object.values(Status);

